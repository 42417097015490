import { put, call, takeLatest } from "redux-saga/effects"
import axios from "axios"
import { ADD_COMPANYPROFILE_REQUEST } from "./actionType"
import { addCompanyprofileFailure, addCompanyprofileSuccess } from "./actions"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* addCompanyprofile(action) {
  try {
    yield call(
      axios.post,
      `${BASE_URL}/company/upload/profile`,
      // "http://13.234.30.42:5000/company/upload/profile",
      action.payload,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(addCompanyprofileSuccess())
  } catch (error) {
    yield put(addCompanyprofileFailure(error.message))
  }
}

export default function* addCompanyprofileSaga() {
  yield takeLatest(ADD_COMPANYPROFILE_REQUEST, addCompanyprofile)
}
