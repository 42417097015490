import { React, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Link, useHistory } from "react-router-dom"
import { FaEdit, FaEye } from "react-icons/fa"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchUserRequest } from "store/actions"
import { Constant } from "pages/Resources/Constant"
import Searchbar from "../Searchbar/Searchbar"
import Pagination from "react-responsive-pagination"
import { IoMdArrowRoundBack } from "react-icons/io"
import { fetchUserTransactionRequest } from "store/actions"
import { useParams } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { display } from "@mui/system"

const UserTransaction = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()
  const [currentPage, setCurrentPage] = useState(1)

  const [searchData, setSearchData] = useState("")
  const [sortOption, setSortOption] = useState("")
  const [filterOption, setFilterOption] = useState("")
  const [bookingOption, setBookingOption] = useState("")
  const [carOption, setCarOption] = useState("")
  const [rotation, setRotation] = useState(0)

  const handleSearchData = searchtext => {
    console.log("searchbarValue==>", searchtext)
    setSearchData(searchtext)
    setCurrentPage(1)
  }
  const handleSortChange = event => {
    setSortOption(event.target.value)
  }

  const handleFilterChange = event => {
    setFilterOption(event.target.value)
    setBookingOption("")
    setCarOption("")
  }

  const handleBookingChange = event => {
    setBookingOption(event.target.value)
  }
  const HandleBackButton = () => {
    history.push(`/view_user/${id}`)
  }

  const UserData = useSelector(state => state.fetchuserReducer.users)
  console.log("UserDATA=>", UserData)
  const UserTransactionData = useSelector(
    state => state.fetchusertransactionReducer.usertransactions?.data
  )
  console.log("UserTransactionData=>", UserTransactionData)

  useEffect(() => {
    dispatch(fetchUserTransactionRequest(currentPage, searchData, id))
  }, [currentPage, searchData, id])

  // useEffect(() => {
  //   dispatch(
  //     fetchUserRequest(
  //       currentPage,
  //       searchData,
  //       sortOption,
  //       bookingOption,
  //       carOption
  //     )
  //   )
  // }, [currentPage, searchData, sortOption, bookingOption, carOption])

  function createData(name, Email, Phone_Number, Country, Status, Action) {
    return { name, Email, Phone_Number, Status, Country, Action }
  }

  // const createdAt = "2023-04-24T09:08:27.087Z" // Your createdAt string
  const DateFormate = createdAt => {
    const date = new Date(createdAt)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    return `${day}/${month}/${year}`
    console.log(`${day}-${month}-${year} ${hours}:${minutes}:${seconds}`)
  }

  const rows = [
    createData(
      "Aayush",
      "aayush00@gmail.com",
      9658741265,
      "Active",
      "India",
      <div className="action"></div>
    ),
    createData(
      "Rohit",
      "aayush00@gmail.com",
      9658741265,
      "Active",
      "India",
      4.3
    ),
    createData(
      "Aditya",
      "aayush00@gmail.com",
      9658741265,
      "Active",
      "India",
      6.0
    ),
    createData(
      "Neha",
      "aayush00@gmail.com",
      9658741265,
      "Active",
      "India",
      4.3
    ),
    createData(
      "Shubham",
      "aayush00@gmail.com",
      9658741265,
      "Active",
      "India",
      3.9
    ),
  ]

  const viewUserHandler = id => {
    history.push(`/view_user/${id}`)
  }
  // useEffect(() => {
  //   if (UserTransactionData?.docs.length === 0) {
  //     toast.error(props.t(Constant.notransactionavailable))
  //   }
  // }, [])

  const HandleRefreshFilter = () => {
    setRotation(rotation + 360)
    setFilterOption("")
    setSortOption("")
    setBookingOption("")
    setCarOption("")
    setCurrentPage(1)
  }
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  return (
    <div className={`main-content${classAlign}`}>
      {(document.title = "User | Nater")}
      <div className="page-content">
        <ToastContainer />
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "50%",
              gap: "15px",
            }}
          >
            <div className="backButton" onClick={HandleBackButton}>
              <IoMdArrowRoundBack className="backicon" />
            </div>
            <div>
              <h3>
                <strong>{props.t(Constant.usertransaction)}</strong>
              </h3>
            </div>
          </div>

          {/* {UserTransactionData?.docs.length === 0 ? (
            ""
          ) : ( */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              width: "50%",
            }}
          >
            <div>
              <Searchbar
                placeholder={props.t(Constant.search)}
                searchHandler={handleSearchData}
              />
            </div>
          </div>
          {/* )} */}
        </div>

        <br />
        {UserTransactionData?.docs.length === 0 ? (
          <div className="transactionnotavailablemessage">
            <div>
              <h1>{props.t(Constant.notransactionavailable)}</h1>
            </div>
          </div>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.username)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.companyname)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.currency)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.status)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.paymentmode)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.amountcharged)}</strong>
                  </TableCell>

                  {/* <TableCell className="tableHeaderCell" align="center">
                  <strong>{props.t(Constant.action)}</strong>
                </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {UserTransactionData?.docs &&
                  UserTransactionData?.docs.map(row => (
                    <TableRow
                      key={row._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" align="center">
                        {row.userObjId.name}
                      </TableCell>
                      <TableCell align="center">
                        {row.companyObjId.companyName}
                      </TableCell>
                      <TableCell align="center">{row.currency}</TableCell>
                      <TableCell align="center">{row.status}</TableCell>
                      <TableCell align="center">{row.type}</TableCell>
                      <TableCell align="center">{row.amount}</TableCell>

                      {/* <TableCell align="center">
                      {
                        <div>
                          <span onClick={() => viewUserHandler(row._id)}>
                            {" "}
                            <FaEye className="userListView" />
                          </span>{" "}
                        </div>
                      }
                    </TableCell> */}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {UserTransactionData?.docs.length === 0 ? (
          ""
        ) : (
          <div style={{ marginTop: 10 }}>
            <Pagination
              current={currentPage}
              maxWidth={5}
              total={UserTransactionData?.totalPages}
              onPageChange={n => {
                setCurrentPage(n)
                // dispatch(fetchUserRequest(n))
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
UserTransaction.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(UserTransaction)
