import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import "./Searchbar.css"
// import { searchedString } from "../../../../../store/actions"
import { useDispatch } from "react-redux"
import { debounce } from "lodash"
import { fetchCompaniesRequest } from "store/actions"
import { fetchvaletsRequest } from "store/actions"
import { fetchbookingRequest } from "store/actions"
import { fetchbookingvaletRequest } from "store/actions"
import { fetchUserRequest } from "store/actions"
import { fetchPaymentRequest } from "store/actions"
import { Constant } from "pages/Resources/Constant"
import { fetchPaymentValetRequest } from "store/actions"
function Searchbar(props) {
  console.log("props==>", props)
  const dispatch = useDispatch()
  const [query, setQuery] = useState("")
  const [data, setData] = useState([])

  const handleText = text => {
    // dispatch(fetchCompaniesRequest(1, text))
    // dispatch(fetchvaletsRequest(1, text))
    // dispatch(fetchbookingRequest(1, text))
    // dispatch(fetchbookingvaletRequest(1, text))
    // dispatch(fetchUserRequest(1, text))
    props.searchHandler(text)
    // dispatch(fetchPaymentRequest(1, text))
    // dispatch(fetchPaymentValetRequest(1, text))
  }

  return (
    <div>
      <input
        className="searchWeb"
        placeholder={props.placeholder}
        onChange={e => {
          handleText(e.target.value.toLowerCase())
        }}
      />
    </div>
  )
}
Searchbar.propTypes = {
  placeholder: PropTypes.any,
  t: PropTypes.any,
  searchHandler: PropTypes.func,
}

export default Searchbar
