import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"
import { FETCH_BOOKINGVALET_REQUEST } from "./actionType"
import { fetchbookingvaletFailure, fetchbookingvaletSuccess } from "./actions"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchbookingvalet(action) {
  const id = sessionStorage.getItem("_id")
  console.log("companyId==>", id)
  console.log("serach==>", action.payload.searchedText)
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/booking/company`,
      // "http://13.234.30.42:5000/booking/company",
      {
        page: action.payload.page,
        // page: 1,
        limit: 10,
        search: action.payload.searchedText ? action.payload.searchedText : "",
        // search: "",
        bookingStatus: action.payload.bookingStatus,
        sort: action.payload.sort,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        amount: action.payload.amount,
        companyObjId: id,
        month: action.payload.month,
        day: action.payload.day,
        parkingDuration: action.payload.parkingDuration,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchbookingvaletSuccess(response.data))
  } catch (error) {
    yield put(fetchbookingvaletFailure(error))
  }
}

function* fetchbookingvaletSaga() {
  yield takeEvery(FETCH_BOOKINGVALET_REQUEST, fetchbookingvalet)
}

export default fetchbookingvaletSaga
