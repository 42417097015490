import { React, useEffect, useState } from "react"
import "../../../../App.css"
import PropTypes from "prop-types"
import "bootstrap/dist/css/bootstrap.min.css"
import { Label } from "reactstrap"
import {
  BrowserRouter as Router,
  useHistory,
  useParams,
} from "react-router-dom"
import { withTranslation } from "react-i18next"
import { Constant } from "pages/Resources/Constant"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { editCompanyRequest } from "store/actions"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import SearchInput from "../GooglePlaces/SearchInput"
import { fetchViewCompaniesRequest } from "store/actions"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/bootstrap.css"

const EditCustomer = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()

  const [place, setPlace] = useState(null)
  const [searchInputError, setSearchInputError] = useState("")
  const [phone, setPhone] = useState("")
  const [phoneError, setPhoneError] = useState("")
  const handlePlaceChanged = placeValue => {
    if (placeValue) {
      setPlace(placeValue.formatted_address)
    }
    setSearchInputError("")
  }
  const addPhoneNumber = value => {
    setPhone(value)
    setPhoneError("")
  }
  useEffect(() => {
    dispatch(fetchViewCompaniesRequest(id))
  }, [])

  const ViewCompanyData = useSelector(
    state => state.fetchViewcompanyReducer.Viewcompanies.data
  )
  const Editcompanydata = useSelector(state => state.editCompanyReducer)

  console.log("editCompanyData=>", Editcompanydata)

  const initialValues = {
    companyName: ViewCompanyData?.companyName,
    email: ViewCompanyData?.email,
    // phone: ViewCompanyData?.phone,
    password: ViewCompanyData?.password,
    address: ViewCompanyData?.address,
    companyId: ViewCompanyData?.companyId,
    bookingFees: ViewCompanyData?.bookingFees,
  }
  const handleSubmit = values => {
    // if (!phone) {
    //   setPhoneError(props.t("Phone Number is Required"))
    //   return
    // } else {
    //   setPhoneError("")
    // }
    if (initialValues.address === null && !place) {
      setSearchInputError(props.t("Address is Required"))
      return
    } else {
      setSearchInputError("")
    }
    const formData = new FormData()
    formData.append("companyObjId", id)
    formData.append("companyName", values.companyName)
    formData.append("email", values.email)
    formData.append("phone", phone ? phone : ViewCompanyData?.phone)
    formData.append("address", place ? place : ViewCompanyData?.address)
    formData.append("companyId", values.companyId)
    formData.append("password", values.password)
    formData.append("bookingFees", values.bookingFees)

    dispatch(editCompanyRequest(formData))
    if (Editcompanydata?.error == null) {
      toast.success(props.t("Company Updated successfully"))
    } else toast.error(Editcompanydata.error)

    setTimeout(() => {
      history.push("/company")
    }, 2000)
  }

  // const initialValues = {
  //   companyName: ViewCompanyData?.companyName,
  //   email: ViewCompanyData?.email,
  //   phone: ViewCompanyData?.phone,
  //   password: ViewCompanyData?.password,
  //   address: ViewCompanyData?.address,
  //   companyId: ViewCompanyData?.companyId,
  // }

  const validationSchema = Yup.object({
    companyName: Yup.string().required(props.t("Name is Required")),
    email: Yup.string()
      .matches(
        /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i,
        props.t("Invalid email")
      )
      .required(props.t("Email is required")),
    // phone: Yup.string().required(props.t("Phone Number is Required")),

    companyId: Yup.string()
      .max(15, props.t("Must be 15 characters or less"))
      .required(props.t("Company ID is Required")),
    password: Yup.string()
      .required(props.t("Password is Required"))
      .min(8, props.t("Password must have atleast 8 Characters"))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        props.t(
          "Password must contain at least one letter, one number, and one special character"
        )
      ),
    bookingFees: Yup.string().required(props.t("Booking Fees is Required")),
  })

  const cancelHandler = () => {
    history.push("/company")
  }

  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }

  return (
    <div className={`main-content${classAlign}`}>
      {(document.title = "Company | Nater")}
      <div className="page-content">
        <ToastContainer />
        <div className="registerValet" style={{ marginLeft: "15px" }}>
          <div id="formValet">
            <h5>
              <strong>{props.t(Constant.Updatecompany)}</strong>
            </h5>
            <br />
            {console.log("initValues==>", initialValues)}
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className="passwordValet">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.name)}
                  </Label>
                  <Field
                    className="form-control"
                    style={{ backgroundColor: "#f1f1f9" }}
                    type="text"
                    name="companyName"
                    placeholder={props.t(Constant.name)}
                  />
                  <p className="textdanger1">
                    <ErrorMessage name="companyName" />
                  </p>
                </div>

                <br />
                <div className="passwordValet">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.email)}
                  </Label>
                  <Field
                    className="form-control"
                    style={{ backgroundColor: "#f1f1f9" }}
                    type="text"
                    name="email"
                    disabled={true}
                    placeholder={props.t(Constant.email)}
                  />
                  <p className="textdanger1">
                    <ErrorMessage name="email" />
                  </p>
                </div>

                <br />
                {/* <div className="passwordValet">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.phonenumber)}
                  </Label>
                  <Field
                    className="form-control"
                    style={{ backgroundColor: "#f1f1f9" }}
                    type="text"
                    name="phone"
                    placeholder={props.t(Constant.phonenumber)}
                  />
                  <p className="textdanger1">
                    <ErrorMessage name="phone" />
                  </p>
                </div> */}
                <Label className={`label${classAlign}`}>
                  {props.t(Constant.phonenumber)}
                </Label>
                <br />
                <div className="passwordValet">
                  <PhoneInput
                    country={"eg"}
                    enableSearch={true}
                    value={phone ? phone : ViewCompanyData?.phone}
                    onChange={addPhoneNumber}
                    // initialValues={ViewValetData?.phone}
                  />
                  {/* <p className="textdanger1">{props.t(phoneError)}</p> */}
                </div>
                <br />
                <div className="passwordValet">
                  <Label className={`label${classAlign}`}>
                    {props.t("Password")}
                  </Label>
                  <Field
                    className="form-control"
                    style={{ backgroundColor: "#f1f1f9" }}
                    type="text"
                    name="password"
                    placeholder={props.t("Password")}
                  />
                  <p className="textdanger1">
                    <ErrorMessage name="password" />
                  </p>
                </div>
                <br />
                <div className="passwordValet">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.companyid)}
                  </Label>
                  <Field
                    className="form-control"
                    style={{ backgroundColor: "#f1f1f9" }}
                    type="text"
                    name="companyId"
                    disabled={true}
                    placeholder={props.t(Constant.companyid)}
                  />
                  <p className="textdanger1">
                    <ErrorMessage name="companyId" />
                  </p>
                </div>
                <br />
                <div className="passwordValet">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.bookingfees)}
                  </Label>
                  <Field
                    className="form-control"
                    style={{ backgroundColor: "#f1f1f9" }}
                    type="text"
                    name="bookingFees"
                    // disabled={true}
                    placeholder={props.t(Constant.bookingfees)}
                  />
                  <p className="textdanger1">
                    <ErrorMessage name="bookingFees" />
                  </p>
                </div>

                <br />
                <div className="passwordValet">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.address)}
                  </Label>

                  <SearchInput
                    onPlaceChanged={handlePlaceChanged}
                    value={place}
                    defaultValue={place ? place : ViewCompanyData?.address}
                    name="address"
                  />
                  <p className="textdanger1">{searchInputError}</p>
                </div>
                <br />

                <div className="inlineFieldsBtn">
                  <button
                    className={`cancelbtn${classAlign}`}
                    type="button"
                    onClick={() => cancelHandler()}
                  >
                    {props.t("Cancel")}
                  </button>
                  <button className={`submitbtn${classAlign}`} type="submit">
                    {props.t("Update")}
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}
EditCustomer.propTypes = {
  t: PropTypes.any,
  state: PropTypes.object,
  location: PropTypes.func,
}

export default withTranslation()(EditCustomer)
