import { React, useState, useEffect } from "react"
import "../../../../App.css"
import PropTypes, { element, string } from "prop-types"
import "bootstrap/dist/css/bootstrap.min.css"
import { Label, Input, Form } from "reactstrap"
import {
  BrowserRouter as Router,
  useHistory,
  useParams,
} from "react-router-dom"
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Constant } from "pages/Resources/Constant"
import { fetchViewCompaniesRequest } from "store/actions"
//model
import Backdrop from "@mui/material/Backdrop"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Fade from "@mui/material/Fade"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"

const ViewCustomer = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()
  console.log("companyId==>", id)

  const ViewCompanyData = useSelector(
    state => state.fetchViewcompanyReducer?.Viewcompanies?.data
  )
  const ViewCompanyALLData = useSelector(
    state => state.fetchViewcompanyReducer?.Viewcompanies
  )
  console.log("ViewCompanyData=>", ViewCompanyData)

  const cancelHandler = () => {
    history.push("/company")
  }
  const TransactionHistoryHandler = () => {
    history.push(`/company_transaction/${ViewCompanyData._id}`)
  }

  useEffect(() => {
    dispatch(fetchViewCompaniesRequest(id))
  }, [])
  const DateFormate = startdate => {
    const date = new Date(startdate)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    return `${day}/${month}/${year}`
    console.log(`${day}-${month}-${year} ${hours}:${minutes}:${seconds}`)
  }
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    // bgcolor: "background.paper",
    bgcolor: "#e9ecef",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  }

  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  return (
    <div className={`main-content${classAlign}`}>
      {(document.title = "Company | Nater")}
      <div className="page-content">
        <div className="registerValet" style={{ marginLeft: "15px" }}>
          <div id="formValet">
            <h5>
              <strong>{props.t(Constant.viewcompany)}</strong>
            </h5>
            <br />
            <Form>
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.companyname)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="companyName"
                    value={ViewCompanyData?.companyName}
                    disabled={true}
                    placeholder={props.t(Constant.companyname)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.email)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="email"
                    name="email"
                    disabled={true}
                    onChange={e => setEmail(e.target.value)}
                    value={ViewCompanyData?.email}
                    placeholder={props.t(Constant.email)}
                  />
                </div>
              </div>
              <br />
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.phonenumber)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="number"
                    name="Phone"
                    onChange={e => setDesignation(e.target.value)}
                    value={ViewCompanyData?.phone}
                    disabled={true}
                    placeholder={props.t(Constant.phonenumber)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.password)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="password"
                    onChange={e => setName(e.target.value)}
                    value={ViewCompanyData?.password}
                    disabled={true}
                    placeholder={props.t(Constant.password)}
                  />
                </div>
              </div>
              <br />

              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.bookingfees)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="companyId"
                    onChange={e => setName(e.target.value)}
                    value={ViewCompanyData?.bookingFees}
                    disabled={true}
                    placeholder={props.t(Constant.bookingfees)}
                  />
                </div>

                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.companyid)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="companyId"
                    onChange={e => setName(e.target.value)}
                    value={ViewCompanyData?.companyId}
                    disabled={true}
                    placeholder={props.t(Constant.companyid)}
                  />
                </div>
              </div>
              <br />

              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.totalvalet)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="companyId"
                    onChange={e => setName(e.target.value)}
                    value={ViewCompanyData?.noOfValets}
                    disabled={true}
                    placeholder={props.t(Constant.totalvalet)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.totalbooking)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="companyId"
                    onChange={e => setName(e.target.value)}
                    value={ViewCompanyData?.totalBooking}
                    disabled={true}
                    placeholder={props.t(Constant.totalbooking)}
                  />
                </div>
              </div>
              <br />
              <Label className={`label${classAlign}`}>
                {props.t(Constant.companyregistrationdate)}
              </Label>
              <Input
                className="textfieldValet"
                type="text"
                name="address"
                onChange={e => setName(e.target.value)}
                value={
                  ViewCompanyData?.createdAt
                    ? DateFormate(ViewCompanyData?.createdAt)
                    : ""
                }
                disabled={true}
                placeholder={props.t(Constant.companyregistrationdate)}
              />
              <br />
              <Label className={`label${classAlign}`}>
                {props.t(Constant.address)}
              </Label>
              <Input
                className="textfieldValet"
                type="text"
                name="address"
                onChange={e => setName(e.target.value)}
                value={ViewCompanyData?.address}
                disabled={true}
                placeholder={props.t(Constant.address)}
              />
              <br />
              <div
                className="TransactionHistoryButtondiv"
                style={{ padding: "1px" }}
              >
                <button
                  // className={`cancelbtn${classAlign}`}
                  className="userTransactionButton"
                  type="button"
                  onClick={() => TransactionHistoryHandler()}
                >
                  {props.t(Constant.transactionhistory)}
                </button>
              </div>

              <br />
              {ViewCompanyData?.profilePicture ? (
                <div className="viewpicturediv">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.companyprofile)}
                  </Label>

                  <div>
                    <img
                      src={ViewCompanyData?.profilePicture}
                      className="viewpicture"
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* <div>
                <div className="ModelProfileButton" onClick={handleOpen}>
                  <Button style={{ color: "white" }}>
                    {props.t(Constant.companyprofile)}
                  </Button>
                
                </div>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open}
                  onClose={handleClose}
                  closeAfterTransition
                  slots={{ backdrop: Backdrop }}
                  slotProps={{
                    backdrop: {
                      timeout: 500,
                    },
                  }}
                >
                  <Fade in={open}>
                    <Box sx={style}>
                    
                      <Button
                        onClick={handleClose}
                        sx={{ position: "absolute", top: 0, right: 0 }}
                        style={{ color: "red" }}
                      >
                        X
                      </Button>
                      <Typography
                        id="transition-modal-description"
                        sx={{ mt: 1 }}
                      >
                        {ViewCompanyData?.profilePicture ? (
                          <img
                            src={ViewCompanyData.profilePicture}
                            className="ModelCompanyProfile"
                          />
                        ) : (
                          props.t(Constant.noprofileuploaded)
                        )}
                      </Typography>
                    </Box>
                  </Fade>
                </Modal>
              </div> */}
              <br />

              <div className="inlineFieldsBtn">
                <button
                  className={`cancelbtn${classAlign}`}
                  type="button"
                  onClick={() => cancelHandler()}
                >
                  {props.t("Cancel")}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}
ViewCustomer.propTypes = {
  t: PropTypes.any,
  state: PropTypes.object,
  location: PropTypes.func,
}

export default withTranslation()(ViewCustomer)
