import {
  EDIT_VALET_FAILURE,
  EDIT_VALET_REQUEST,
  EDIT_VALET_SUCCESS,
} from "./actionType"

export const editValetRequest = formData => ({
  type: EDIT_VALET_REQUEST,
  payload: formData,
})

export const editValetSuccess = () => ({
  type: EDIT_VALET_SUCCESS,
})

export const editValetFailure = error => ({
  type: EDIT_VALET_FAILURE,
  payload: error,
})
