import React from "react"
import "./Dashboard.style.css"
import PropTypes from "prop-types"

function DashCard({ heading, footerValue, icons }) {
  return (
    <div className="dashCard">
      <div className="dashCard-header">{heading}</div>
      {/* <br /> */}
      {/* <br /> */}
      {/* <br /> */}
      <div className="dashCard-footer">
        <div>{footerValue}</div>
        <div className="iconBackground">{icons}</div>
      </div>
    </div>
  )
}
DashCard.propTypes = {
  heading: PropTypes.any,
  footerValue: PropTypes.any,
  icons: PropTypes.any,
}

export default DashCard
