import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"
import { FETCH_VIEW_BOOKING_REQUEST } from "./actionType"
import { fetchViewBookingFailure, fetchViewBookingSuccess } from "./actions"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchViewBooking(action) {
  // const id = localStorage.getItem("_id")
  // console.log("valetId==>", id)
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/booking/details`,
      // "http://13.234.30.42:5000/booking/details",
      { bookingObjId: action.payload },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchViewBookingSuccess(response.data))
  } catch (error) {
    yield put(fetchViewBookingFailure(error))
  }
}

function* fetchViewbookingSaga() {
  yield takeEvery(FETCH_VIEW_BOOKING_REQUEST, fetchViewBooking)
}

export default fetchViewbookingSaga
