import { put, call, takeEvery, takeLatest } from "redux-saga/effects"
import axios from "axios"
import { ADD_COMPANY_REQUEST } from "./actionTypes"
import { addCompanyFailure, addCompanySuccess } from "./actions"
import { toast } from "react-toastify"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"
import { doc } from "prettier"
import { useHistory } from "react-router-dom"

function* addCompany(action, successCallBack) {
  let success = ""
  if (document.body.dir === "rtl") {
    success = "تم تسجيل الشركة بنجاح"
  } else {
    success = "Company registered successfully"
  }
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/company/add`,
      // "http://13.234.30.42:5000/company/add",
      action.payload.data,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(addCompanySuccess(response.data))
    console.log("addC-->", response.data, action.payload)
    action.payload.successCallBack()
    toast.success(`${success}`)
  } catch (error) {
    console.log("err====>", error.response?.data?.message)
    const loginError = error.response
    yield put(addCompanyFailure(error.message))
    toast.error(`${loginError?.data?.message}`)
  }
}

export default function* addCompanySaga() {
  yield takeLatest(ADD_COMPANY_REQUEST, addCompany)
}
