import {
  FETCH_DASHBOARDVALET_FAILURE,
  FETCH_DASHBOARDVALET_REQUEST,
  FETCH_DASHBOARDVALET_SUCCESS,
} from "./actionType"

export const fetchDashboardvaletRequest = (monthOption, yearOption) => ({
  type: FETCH_DASHBOARDVALET_REQUEST,
  payload: {
    month: monthOption,
    year: yearOption,
  },
})

export const fetchDashboardvaletSuccess = dashboardValetData => ({
  type: FETCH_DASHBOARDVALET_SUCCESS,
  payload: dashboardValetData,
})

export const fetchDashboardvaletFailure = error => ({
  type: FETCH_DASHBOARDVALET_FAILURE,
  payload: error,
})
