import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"
import { FETCH_BOOKING_REQUEST } from "./actionType"
import { fetchbookingFailure, fetchbookingSuccess } from "./actions"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchbooking(action) {
  console.log(action, ">>text<<<", action.payload.searchedText)
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/booking/all`,
      // "http://13.234.30.42:5000/booking/all",
      {
        filter: {
          bookingStatus: action.payload.bookingStatus,
        },
        page: action.payload.page,
        // page: 1,
        limit: 10,
        search: action.payload.searchedText ? action.payload.searchedText : "",
        sort: action.payload.sort,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        companyObjId: action.payload.companyObjId,
        amount: action.payload.amount,
        month: action.payload.month,
        day: action.payload.day,
        parkingDuration: action.payload.parkingDuration,

        // search: "",
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchbookingSuccess(response.data))
  } catch (error) {
    yield put(fetchbookingFailure(error))
  }
}

function* fetchbookingSaga() {
  yield takeEvery(FETCH_BOOKING_REQUEST, fetchbooking)
}

export default fetchbookingSaga
