import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"
import { FETCH_USER_TRANSACTION_REQUEST } from "./actionType"

import {
  fetchUserTransactionFailure,
  fetchUserTransactionSuccess,
} from "./action"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchUserTransactions(action) {
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/user/transactions`,
      // "http://13.234.30.42:5000/user/list",
      {
        page: action.payload.page,
        // page: 1,
        limit: 10,
        search: action.payload.searchedText ? action.payload.searchedText : "",
        userObjId: action.payload.userObjId,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchUserTransactionSuccess(response.data))
  } catch (error) {
    yield put(fetchUserTransactionFailure(error))
  }
}

function* fetchusertransactionSaga() {
  yield takeEvery(FETCH_USER_TRANSACTION_REQUEST, fetchUserTransactions)
}

export default fetchusertransactionSaga
