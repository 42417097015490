import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"

import { Label } from "reactstrap"
import "../../App.css"
import { useHistory } from "react-router-dom"

//redux
import { useSelector, useDispatch } from "react-redux"
// Formik validation
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.png"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import naterpurple_logo from "../../pages/Components/Assets/Images/NaterPurple_logo.png"
import new_nater_logo from "../../pages/Components/Assets/Images/new_nater_logo.jpg"
import naterlogin_logo from "../../pages/Components/Assets/Images/naterlogin_logo.png"
import "../../App.css"
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import { MdEmail } from "react-icons/md"

//Import config
import { withTranslation } from "react-i18next"

import { loginRequest } from "../../store/actions"

const Login = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [show, setShow] = useState(false)
  const initialValues = {
    email: "",
    password: "",
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i,
        "Invalid email"
      )
      .required("Email is required"),
    password: Yup.string()
      .required("Please enter your password")
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must contain at least one letter, one number, and one special character"
      ),
  })

  const formSubmit = values => {
    // handleSubmit()
    // alert(JSON.stringify(errors))
    dispatch(loginRequest(values.email, values.password, successCallBack))
    // setTimeout(() => {
    //   // role === "admin"
    //   //   ? history.push("/dashboard")
    //   //   : history.push("/dashboardvalet")
    //   if (role === "admin") {
    //     history.push("/dashboard")
    //   } else if (role === "company") {
    //     history.push("/dashboardvalet")
    //   } else {
    //     history.push("/")
    //   }
    // }, 2000)
    // Window.reload()
  }

  const successCallBack = response => {
    toast.success("Login Successfully")
    // alert("tfxdfgyugtfghyjugv")
    let role = sessionStorage.getItem("role")
    // if (role === "admin") {
    //   history.push("/dashboard")
    // } else if (role === "company") {
    //   history.push("/dashboardvalet")
    // }
    //if type == admin
  }

  const toggleButton = () => {
    setShow(prevState => !prevState)
  }

  const loginnevigate = () => {
    history.push("/valet_drivers")
  }
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Nater</title>
      </MetaTags>
      <ToastContainer autoClose={2000} />
      <div className="loginBackground">
        <div className="App">
          <div className="registerLogin">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={formSubmit}
            >
              <Form
                id="formLogin"
                // onSubmit={handleSubmit}
                // onClick={handleSubmit}
              >
                <img
                  src={naterlogin_logo}
                  style={{
                    // width: "145px",
                    height: "134px",
                    objectFit: "cover",
                    // borderRadius: "10%",
                  }}
                />

                {/* <h1>Login</h1> */}
                <br />
                <br />

                <div className="">
                  {/* <div className="inlineloginsBtn">
                                <button
                                    // className={`cancelbtn${classAlign}`}
                                    className="adminloginbutton"
                                    type="button"
                                    // onClick={() => cancelHandler()}
                                >
                                    {props.t("Admin")}
                                </button>
                                <button
                                    className="adminloginbutton"
                                    type="submit"
                                    // onSubmit={handleSubmit}
                                //   onClick={() => submitHandler()}
                                >
                                    {props.t("Company")}
                                </button>
                            </div>
                            <br/> */}
                  <div className="loginfields">
                    <div className="fields">
                      <Label className="labelltrClass">Email</Label>
                      <div className="password-lgn">
                        <Field
                          name="email"
                          className="form-control"
                          placeholder="Enter your email"
                          type="email"
                          // value={values.email}
                          // onChange={handleChange}
                          style={{ backgroundColor: "#f1f1f9" }}
                        />
                        <div className="fieldIcon">
                          <MdEmail />
                        </div>
                      </div>
                      <p className="text-danger">
                        <ErrorMessage name="email" />
                      </p>
                    </div>

                    <div className="fields">
                      <Label className="labelltrClass">Password</Label>
                      <div className="password-lgn">
                        <Field
                          name="password"
                          type={show ? "text" : "password"}
                          placeholder="Password"
                          className="form-control"
                          // value={values.password}
                          // onChange={handleChange}
                          style={{ backgroundColor: "#f1f1f9" }}
                        />
                        <button
                          type="button"
                          className="passwordBtn"
                          onClick={toggleButton}
                        >
                          {show ? <AiFillEye /> : <AiFillEyeInvisible />}
                        </button>
                      </div>
                      <p className="text-danger">
                        <ErrorMessage name="password" />
                      </p>
                    </div>
                  </div>
                  {/* <p
                  style={{
                    float: "right",
                  }}
                >
                  <Link to="/forgot_password" className="textfield">
                    Forgot Password?
                  </Link>
                </p> */}
                  {/* {error && <p>{error}</p>}
              <br /> */}
                  <div className="submit-btn-lgn">
                    <button
                      className="btnLgIn"
                      type="submit"
                      // disabled={isSubmitting}
                      // onClick={formSubmit}
                    >
                      Login
                    </button>
                  </div>
                  {/* <p
                  style={{
                    color: "grey",
                    marginTop: "10px",
                  }}
                >
                  {" Didn't have an Account?"}
                  <Link to="/register" className="textfield">
                    Signup
                  </Link>
                </p> */}
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>

      {/* <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                      </div> */}

      {/* <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div> */}

      {/* <div className="mt-5 text-center">
                <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
              </div> */}

      {/* <ToastContainer /> */}
    </React.Fragment>
  )
}

Login.propTypes = {
  // history: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(Login)
