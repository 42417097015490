import {
  FETCH_PAYMENTVALET_FAILURE,
  FETCH_PAYMENTVALET_REQUEST,
  FETCH_PAYMENTVALET_SUCCESS,
} from "./actionType"

export const fetchPaymentValetRequest = (
  n,
  search,
  sortOption,
  formattedStartDate,
  formattedEndDate,
  amountOption,
  paymentMethodOption,
  monthOption,
  dayOption,
  statusOption
) => ({
  type: FETCH_PAYMENTVALET_REQUEST,
  payload: {
    page: n,
    searchedText: search,
    sort: sortOption,
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    amount: amountOption,
    paymentMethod: paymentMethodOption,
    month: monthOption,
    day: dayOption,
    status: statusOption,
  },
})

export const fetchPaymentValetsSuccess = paymentvalets => ({
  type: FETCH_PAYMENTVALET_SUCCESS,
  payload: paymentvalets,
})

export const fetchPaymentValetFailure = error => ({
  type: FETCH_PAYMENTVALET_FAILURE,
  payload: error,
})
