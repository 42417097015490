import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"
import { FETCH_USER_REQUEST } from "./actionType"

import { fetchUserFailure, fetchUserSuccess } from "./actions"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchUsers(action) {
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/user/list`,
      // "http://13.234.30.42:5000/user/list",
      {
        page: action.payload.page,
        limit: 10,
        search: action.payload.searchedText ? action.payload.searchedText : "",
        sort: action.payload.sort,
        booking: action.payload.booking,
        car: action.payload.car,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchUserSuccess(response.data))
  } catch (error) {
    yield put(fetchUserFailure(error))
  }
}

function* fetchuserSaga() {
  yield takeEvery(FETCH_USER_REQUEST, fetchUsers)
}

export default fetchuserSaga
