import {
  FETCH_PAYMENT_FAILURE,
  FETCH_PAYMENT_REQUEST,
  FETCH_PAYMENT_SUCCESS,
} from "./actionType"

export const fetchPaymentRequest = (
  n,
  search,
  sortOption,
  formattedStartDate,
  formattedEndDate,
  amountOption,
  paymentMethodOption,
  companyOption,
  monthOption,
  dayOption,
  statusOption
) => ({
  type: FETCH_PAYMENT_REQUEST,
  payload: {
    page: n,
    searchedText: search,
    sort: sortOption,
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    amount: amountOption,
    paymentMethod: paymentMethodOption,
    companyObjId: companyOption,
    month: monthOption,
    day: dayOption,
    status: statusOption,
  },
})

export const fetchPaymentSuccess = payments => ({
  type: FETCH_PAYMENT_SUCCESS,
  payload: payments,
})

export const fetchPaymentFailure = error => ({
  type: FETCH_PAYMENT_FAILURE,
  payload: error,
})
