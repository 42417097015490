import { put, call, takeLatest } from "redux-saga/effects"
import axios from "axios"
import { ADD_VALET_REQUEST } from "./actionType"
import { addvaletSuccess, addvaletFailure } from "./actions"
import { toast } from "react-toastify"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* addvalet(action) {
  let success = ""
  if (document.body.dir === "rtl") {
    success = "تم تسجيل سائق الفاليه بنجاح"
  } else {
    success = "Valet Driver registered successfully"
  }
  try {
    yield call(
      axios.post,
      `${BASE_URL}/valet/add`,
      // "http://13.234.30.42:5000/valet/add",
      action.payload.data,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(addvaletSuccess())
    action.payload.successCallBack()
    toast.success(`${success}`)
  } catch (error) {
    const loginError = error.response
    yield put(addvaletFailure(error.message))
    toast.error(`${loginError.data.message}`)
  }
}

export default function* addvaletSaga() {
  yield takeLatest(ADD_VALET_REQUEST, addvalet)
}
