import {
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
} from "./actionType"

export const loginRequest = (email, password, successCallBack) => ({
  type: LOGIN_REQUEST,
  payload: { email, password, successCallBack },
})

export const loginsuccess = data => ({
  type: LOGIN_SUCCESS,
  payload: { data },
})

export const loginFailure = error => ({
  type: LOGIN_FAILURE,
  payload: { error },
})

export const logout = () => ({
  type: LOGOUT,
})
