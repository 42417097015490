import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//E-commerce
import ecommerce from "./e-commerce/reducer"

//Calendar
import calendar from "./calendar/reducer"

//chat
import chat from "./chat/reducer"

//crypto
import crypto from "./crypto/reducer"

//invoices
import invoices from "./invoices/reducer"

//projects
import projects from "./projects/reducer"

//tasks
import tasks from "./tasks/reducer"

//contacts
import contacts from "./contacts/reducer"

//mails
import mails from "./mails/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer"

//valet--------------------------------------------------
//1) Authentication :-
// Login
import authReducer from "./Valet/Authentication/reducer"
//2) Admin:-
//COMPANY
//Add Company
import addCompanyReducer from "./Valet/Admin/Company/AddCompany/reducers"
// company list
import fetchcompanyReducer from "./Valet/Admin/Company/CompanyList/reducers"
//cpmpany list filter
import fetchcompanyfilterReducer from "./Valet/Admin/Company/CompanyListFilter.js/reducers"
// view company
import fetchViewcompanyReducer from "./Valet/Admin/Company/ViewCompany/reducers"
//edit company
import editCompanyReducer from "./Valet/Admin/Company/EditCompany/reducers"
// delete company
import deletecompanyReducer from "./Valet/Admin/Company/DeleteCompany/reducers"
// company profile
import addCompanyprofileReducer from "./Valet/Admin/Company/CompanyProfile/reducers"
// USER
//user list
import fetchuserReducer from "./Valet/Admin/User/UserList/reducers"
// view user
import fetchViewuserReducer from "./Valet/Admin/User/ViewUser/reducers"
// user transaction
import fetchusertransactionReducer from "./Valet/Admin/User/UserTransaction/reducers"
// amount transfer
import fetchAmountTransferReducer from "./Valet/Admin/Company/AmountTransfer/reducers"
//BOOKING
//booking list
import fetchbookingReducer from "./Valet/Admin/Booking/Booking List/reducers"
// view booking
import fetchViewbookingReducer from "./Valet/Admin/Booking/View Booking/reducers"
// delete booking
import deletebookingReducer from "./Valet/Admin/Booking/Delete Booking/reducers"
//PAYMENT
//payment list
import fetchpaymentReducer from "./Valet/Admin/Payment/PaymentList/reducers"
// view payment
import fetchViewpaymentReducer from "./Valet/Admin/Payment/ViewPayment/reducers"
//Dashboard
//dashboardData
import fetchdashboardReducer from "./Valet/Admin/Dashboard/reducers"

//3) Company:-
//VALET
//Add Valet
import addvaletReducer from "./Valet/Company/Valet/AddValet/reducers"
// Valet List
import fetchvaletReducer from "./Valet/Company/Valet/ValetList/reducer"
// view valet
import fetchViewvaletsReducer from "./Valet/Company/Valet/ViewValet/reducers"
// delete valet
import deletevaletReducer from "./Valet/Company/Valet/DeleteValet/reducers"
//edit valet
import editValetReducer from "./Valet/Company/Valet/EditValet/reducers"
// company transaction
import fetchCompanytransactionReducer from "./Valet/Admin/Company/CompanyTransaction/reducers"
// BOOKING
//booking list
import fetchbookingvaletReducer from "./Valet/Company/BookingValet/BookingValet List/reducers"
// view booking
import fetchViewbookingvaletReducer from "./Valet/Company/BookingValet/ViewBookingValet/reducers"

//PAYMENT
//payment list
import fetchpaymentvaletReducer from "./Valet/Company/PaymentValet/PaymentValetList/reducers"

//Dashboard
//dashboardData
import fetchdashboardvaletReducer from "./Valet/Company/DashboardValet/reducers"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  projects,
  tasks,
  contacts,
  Dashboard,
  DashboardSaas,
  authReducer,
  addCompanyReducer,
  addvaletReducer,
  fetchcompanyReducer,
  fetchvaletReducer,
  fetchViewcompanyReducer,
  editCompanyReducer,
  deletecompanyReducer,
  fetchViewvaletsReducer,
  deletevaletReducer,
  editValetReducer,
  fetchuserReducer,
  fetchViewuserReducer,
  fetchbookingReducer,
  fetchViewbookingReducer,
  deletebookingReducer,
  fetchbookingvaletReducer,
  addCompanyprofileReducer,
  fetchpaymentReducer,
  fetchbookingvaletReducer,
  fetchViewbookingvaletReducer,
  fetchpaymentvaletReducer,
  fetchdashboardReducer,
  fetchdashboardvaletReducer,
  fetchViewpaymentReducer,
  fetchcompanyfilterReducer,
  fetchusertransactionReducer,
  fetchCompanytransactionReducer,
  fetchAmountTransferReducer,
})

export default rootReducer
