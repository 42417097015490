import React from "react"
import { Button } from "@material-ui/core"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { FaEdit, FaEye } from "react-icons/fa"
import { MdDeleteOutline } from "react-icons/md"
import { IoIosRefresh } from "react-icons/io"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import "../valetCSS/valetapp.css"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchCompaniesRequest } from "store/actions"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { deleteCompanyRequest } from "store/actions"
import Swal from "sweetalert2"
import Pagination from "react-responsive-pagination"
import Searchbar from "../Searchbar/Searchbar"
import { Constant } from "pages/Resources/Constant"
import { width } from "@mui/system"

const Customers = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)

  const [searchData, setSearchData] = useState("")
  const [sortOption, setSortOption] = useState("")
  const [filterOption, setFilterOption] = useState("")
  const [valetOption, setValetOption] = useState("")
  const [rotation, setRotation] = useState(0)

  const handleSearchData = searchtext => {
    console.log("searchbarValue==>", searchtext)
    setSearchData(searchtext)
    setCurrentPage(1)
  }
  const handleSortChange = event => {
    setSortOption(event.target.value)
  }

  const handleFilterChange = event => {
    setFilterOption(event.target.value)
    setValetOption("") // Reset amount option when changing filters
  }

  const handleValetChange = event => {
    setValetOption(event.target.value)
  }

  const CompanyData = useSelector(state => state.fetchcompanyReducer.companies)
  console.log("companyDATA=>", CompanyData)

  const CompanyDelete = useSelector(state => state.deletecompanyReducer)
  console.log("CompanyDelete=>", CompanyDelete)

  useEffect(() => {
    dispatch(
      fetchCompaniesRequest(currentPage, searchData, sortOption, valetOption)
    )
  }, [currentPage, searchData, sortOption, valetOption])

  function createData(
    name,
    Email,
    Phone_Number,
    Vehicle_Number,
    Company_ID,
    Action
  ) {
    return { name, Email, Phone_Number, Vehicle_Number, Company_ID, Action }
  }

  const rows = [
    createData(
      "Aayush",
      "aayush00@gmail.com",
      9658741265,
      "MP09 0443",
      "Active",
      <div className="action"></div>
    ),
    createData(
      "Rohit",
      "aayush00@gmail.com",
      9658741265,
      "MP09 0443",
      "Active"
    ),
    createData(
      "Aditya",
      "aayush00@gmail.com",
      9658741265,
      "MP09 0443",
      "Active"
    ),
    createData("Neha", "aayush00@gmail.com", 9658741265, "MP09 0443", "Active"),
    createData(
      "Shubham",
      "aayush00@gmail.com",
      9658741265,
      "MP09 0443",
      "Active"
    ),
  ]

  const handleDelete = id => {
    Swal.fire({
      text: props.t("Are you sure you want to remove this company?"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
      confirmButtonText: props.t("OK"),
      cancelButtonText: props.t("Cancel"),
      showConfirmButton: true,
      showCancelButton: true,
    }).then(willDelete => {
      if (willDelete.isConfirmed) {
        dispatch(deleteCompanyRequest(id))
        if (CompanyDelete?.error == null) {
          toast.success(props.t("Company deleted successfully"))
        } else {
          toast.error(CompanyDelete.error)
        }

        dispatch(fetchCompaniesRequest())
      } else {
      }
    })
  }

  const addCustomerHandler = () => {
    history.push("/add_company")
  }
  const editCustomerHandler = id => {
    history.push(`/edit_company/${id}`)
  }
  const viewCustomerHandler = id => {
    history.push(`/view_company/${id}`)
  }
  const HandleRefreshFilter = () => {
    setRotation(rotation + 360)
    setFilterOption("")
    setSortOption("")
    setValetOption("")
    setCurrentPage(1)
  }
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }

  return (
    <div className={`main-content${classAlign}`}>
      {(document.title = "Company | Nater")}
      <div className="page-content">
        <ToastContainer />
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "40%",
            }}
          >
            <h3>
              <strong>{props.t(Constant.company)}</strong>
            </h3>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              width: "60%",
            }}
          >
            <div>
              <Searchbar
                placeholder={props.t(Constant.search)}
                searchHandler={handleSearchData}
              />
            </div>
            <div className="addButton" onClick={addCustomerHandler}>
              <Button className="MUI-addbutton">
                {props.t(Constant.addplus)}
              </Button>
            </div>
          </div>
        </div>
        <br />

        <div className="companyfiltermaindiv">
          <div className="newcompanyfilterseconddiv">
            <select
              value={sortOption}
              onChange={handleSortChange}
              className="newfilterstyle"
            >
              <option value="">{props.t(Constant.sortby)}</option>
              <option value="A-Z">{props.t(Constant.az)}</option>
              <option value="Z-A">{props.t(Constant.za)}</option>
              <option value="Newest">{props.t(Constant.newest)}</option>
              <option value="Oldest">{props.t(Constant.oldest)}</option>
            </select>

            <select
              value={filterOption}
              onChange={handleFilterChange}
              className="newfilterstyle"
            >
              <option value="">{props.t(Constant.filterby)}</option>
              <option value="valet">{props.t(Constant.valet)}</option>
            </select>
          </div>
          <div className="newcompanyfilterthirddiv">
            {filterOption === "valet" && (
              // <label style={{ marginRight: "10px" }}>
              //   Amount Range:
              <select
                value={valetOption}
                onChange={handleValetChange}
                className="newfilterstyle"
              >
                <option value="">{props.t(Constant.valetrange)}</option>
                <option value="0-10">0-10</option>
                <option value="11-15">11-15</option>
                <option value="16-20">16-20</option>
                <option value="20+">20+</option>
              </select>
              // </label>
            )}

            <div className="refreshButton" onClick={HandleRefreshFilter}>
              <IoIosRefresh
                className="refreshicon"
                style={{ transform: `rotate(${rotation}deg)` }}
              />
            </div>
          </div>
        </div>

        <br />
        {CompanyData?.data?.length === 0 ? (
          <div className="datanotavailablemessage">
            <div>
              <h1>{props.t("No Company Available")}</h1>
            </div>
          </div>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.companyname)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong> {props.t(Constant.email)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong> {props.t(Constant.valets)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.phonenumber)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.companyid)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.action)}</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {CompanyData.data &&
                  CompanyData.data.map(row => (
                    <TableRow
                      key={row._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" align="center">
                        {row.companyName}
                      </TableCell>
                      <TableCell align="center">{row.email}</TableCell>
                      <TableCell align="center">
                        {row.noOfValets ? row.noOfValets : "0"}
                      </TableCell>
                      <TableCell align="center">{row.phone}</TableCell>
                      <TableCell align="center">{row.companyId}</TableCell>
                      <TableCell align="center">
                        {
                          <div className="Action-button">
                            {" "}
                            <span onClick={() => viewCustomerHandler(row._id)}>
                              {" "}
                              <FaEye className="userListView" />
                            </span>{" "}
                            <span onClick={() => editCustomerHandler(row._id)}>
                              {" "}
                              <FaEdit className="userListEdit" />
                            </span>{" "}
                            <span onClick={() => handleDelete(row._id)}>
                              <MdDeleteOutline className="userListDelete" />
                            </span>
                          </div>
                        }
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {CompanyData?.data?.length === 0 ? (
          ""
        ) : (
          <div style={{ marginTop: 10 }}>
            <Pagination
              current={currentPage}
              maxWidth={5}
              total={CompanyData.totalPages}
              onPageChange={n => {
                setCurrentPage(n)
                // dispatch(fetchCompaniesRequest(n))
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
Customers.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Customers)
