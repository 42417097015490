import {
  FETCH_COMPANIES_FILTER_FAILURE,
  FETCH_COMPANIES_FILTER_SUCCESS,
  FETCH_COMPANIES_FILTER_REQUEST,
} from "./actionType"

const initialState = {
  companiesfilter: [],
  loading: false,
  error: null,
}

const fetchcompanyfilterReducer = (state = initialState, action) => {
  // console.log("Action==>", action)
  switch (action.type) {
    case FETCH_COMPANIES_FILTER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case FETCH_COMPANIES_FILTER_SUCCESS:
      return {
        ...state,
        companiesfilter: action.payload,
        loading: false,
        error: null,
      }
    case FETCH_COMPANIES_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default fetchcompanyfilterReducer
