import {
  FETCH_VIEW_BOOKING_FAILURE,
  FETCH_VIEW_BOOKING_REQUEST,
  FETCH_VIEW_BOOKING_SUCCESS,
} from "./actionType"

export const fetchViewBookingRequest = id => (
  console.log("viewbid==>", id),
  {
    type: FETCH_VIEW_BOOKING_REQUEST,
    payload: id,
  }
)

export const fetchViewBookingSuccess = Viewbookings => ({
  type: FETCH_VIEW_BOOKING_SUCCESS,
  payload: Viewbookings,
})

export const fetchViewBookingFailure = error => ({
  type: FETCH_VIEW_BOOKING_FAILURE,
  payload: error,
})
