// /********************************************** /
import { React, Suspense } from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import { authProtectedRoutes, companyProtectedRoutes } from "routes"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {
  const tokendata = sessionStorage.getItem("token")
  const role = sessionStorage.getItem("role")
  let currentPath = window.location.hash.split("#")[1]
  return (
    <>
      {(tokendata === null &&
        authProtectedRoutes.filter(a => window.location.hash.includes(a.path))
          .length > 0) ||
      (tokendata === null &&
        companyProtectedRoutes.filter(a =>
          window.location.hash.includes(a.path)
        ).length > 0) ? (
        <Redirect to="/" />
      ) : tokendata != null && currentPath == "/" && role === "admin" ? (
        <Redirect to={"/dashboard"} />
      ) : tokendata != null && currentPath == "/" && role === "company" ? (
        <Redirect to={"/dashboardvalet"} />
      ) : (
        <Route
          exact
          {...rest}
          render={props => (
            <Layout>
              <Suspense fallback={<div>Loading...</div>}>
                <Component {...props} />
              </Suspense>
            </Layout>
          )}
        ></Route>
      )}
    </>
  )
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware
