import { React, useState, useEffect } from "react"
import "./Chart.css"
import PropTypes from "prop-types"
import { userData } from "pages/Components/Chart/dummyData"
import moment from "moment"
// import schoolDataObject from "../Controllers/SchoolController"

// import {
//   LineChart,
//   Line,
//   Bar,
//   XAxis,
//   CartesianGrid,
//   Tooltip,
//   ResponsiveContainer,
// } from "recharts"

// composed chart //

import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  ResponsiveContainer,
} from "recharts"

function Chart() {
  const [totalStudents, setTotalStudents] = useState("")
  // const [preseStudents, setPresentStudents] = useState([])
  // const [absentStudents, setAbsentStudents] = useState([])
  // const [halfDayStudents, setHalfDayStudents] = useState([])
  // const [shortLeaveStudents, setShortLeaveStudents] = useState([])
  const [graphDataBeacon, setGraphDataBeacon] = useState([])

  let presentStudents = [],
    absentStudents = [],
    halfDayStudents = [],
    shortLeaveStudents = []

  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD")
  const endOfMonth = moment().endOf("month").format("YYYY-MM-DD")
  // console.log("zzzzzzzzzz", startOfMonth, endOfMonth)

  const graphData = () => {
    let body = {
      schoolid: localStorage.getItem("schoolId"),
      from: startOfMonth,
      to: endOfMonth,
    }
    // schoolDataObject.getDashboardData(body, result => {
    //   console.log("result", result)
    //   if (result.data.status === true) {
    //     setGraphDataBeacon(result.data.graph_data)
    //   }
    // })
  }
  var dates = []
  dates = graphDataBeacon.map(d => {
    return d.date
  })
  console.log("graphData===>", dates)
  // const graphDataFilter = () => {
  //   graphDataBeacon
  // }
  var count_days = [],
    // dates = [],
    x
  graphDataBeacon.forEach(el => {
    if (el.time >= 7) {
      x = count_days.findIndex(f => f.date == el.date)
      if (x > -1) {
        count_days[x].present_day += 1
      } else {
        count_days.push({
          date: el.date,
          present_day: 1,
        })
      }
    }
    return count_days
  })
  console.log("count==>", count_days)
  var formatDate = "2022-08-12"

  var responseDate = dates.map(e => {
    return moment(e).format("MMMDD")
  })
  console.log("rrrrrrrr", responseDate)

  useEffect(() => {
    graphData()
    // graphDataFilter()
  }, [])

  const chartData = graphDataBeacon.map(data => {
    return {
      date: responseDate,
      present: count_days.present,
      absent: 100,
      shortLeave: 50,
      halfDay: 150,
    }
  })

  return (
    <div className="chart">
      {/* <h3 className="chartTitle">{title}</h3> */}
      <ResponsiveContainer aspect={4 / 2}>
        {/* <LineChart data={data}>
          <XAxis dataKey="name" stroke="#5550bd" />
          <Line type="monotone" dataKey={dataKey} stroke="#5550bd" />
          <Bar dataKey="pv" barSize={20} fill="#413ea0" />
          <Tooltip />
          {grid && <CartesianGrid stroke="#e0dfdf" strokeDasharray="5 5" />}
        </LineChart> */}
        <ComposedChart
          data={chartData}
          margin={{
            top: 20,
            right: 10,
            bottom: 20,
            left: 10,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="date" />
          <YAxis interval={100} />
          <Tooltip />
          <Legend />
          {/* <Area
            type="monotone"
            dataKey="Active User"
            fill="#8884d8"
            stroke="#8884d8"
          /> */}
          <Bar dataKey="pv" fill="#413ea0" />
          {/* <Line type="monotone" dataKey="Active User" stroke="#ff7300" /> */}
          {/* <Scatter dataKey="cnt" fill="red" /> */}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}
Chart.prototypes = {
  t: PropTypes.any,
  title: PropTypes.any,
  data: PropTypes.any,
  dataKey: PropTypes.any,
  grid: PropTypes.any,
}
export default Chart
