import "../../App.css"
import "../Components/Pages/Common/Home.css"
import { React, useState, useEffect } from "react"
// import FeaturedInfo from "pages/Components/FeaturedInfo/FeaturedInfo"
// import Chart from "pages/Components/Chart/Chart"
import DashboardValetCards from "./DashboardValetCards.component"
import BarchartValetCompany from "./BarchartValetCompany"
// import FeaturedinfoCompany from "./FeaturedinfoCompany"
// import BarChart from "pages/AllCharts/chartjs/barchart"
// import { userData } from "pages/Components/Chart/dummyData"
import { Route, Redirect, useHistory } from "react-router-dom"
import { FaEdit, FaEye } from "react-icons/fa"
import { MdDeleteOutline } from "react-icons/md"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
// import studentDataObject from "../Components/Controllers/studentController"
import { useSelector } from "react-redux"
import { ToastContainer, toast } from "react-toastify"

function DashboardValet(props) {
  const history = useHistory()
  const [tableData, setTableData] = useState([])
  const LoginData = useSelector(state => state.authReducer)
  console.log("LoginData=>", LoginData)

  const schoolId = parseInt(localStorage.getItem("schoolId"))
  const graphData = () => {
    const body = {
      schoolid: schoolId,
    }
  }

  useEffect(() => {
    if (LoginData?.status.code == 200) {
      toast.success(LoginData.message)
      window.location.reload()
    } else toast.error(LoginData.message)
  }, [LoginData])

  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  return (
    <div className={`main-content${classAlign}`}>
      {(document.title = "Dashboard | Nater")}
      <div className="page-content">
        <div className="home">
          <div className="container2">
            <ToastContainer autoClose={2000} />
            <DashboardValetCards />
            {/* <FeaturedinfoCompany /> */}
            <br />
            <BarchartValetCompany />
          </div>
        </div>
        {/* <h1>Under Development</h1> */}
      </div>
    </div>
  )
}
DashboardValet.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(DashboardValet)
