import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"
import {
  FETCH_VALET_REQUEST,
  FETCH_VALET_SUCCESS,
  FETCH_VALET_FAILURE,
} from "./actionType"
import { fetchvaletsFailure, fetchvaletsSuccess } from "./actions"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchvalets(action) {
  const id = sessionStorage.getItem("_id")
  console.log("valetId==>", id)
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/valet/company/all`,
      // "http://13.234.30.42:5000/valet/company/all",
      {
        page: action.payload.page,
        limit: 10,
        search: action.payload.searchedText,
        sort: action.payload.sort,
        booking: action.payload.booking,
        companyObjId: id,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchvaletsSuccess(response.data))
  } catch (error) {
    yield put(fetchvaletsFailure(error))
  }
}

function* fetchvaletSaga() {
  yield takeEvery(FETCH_VALET_REQUEST, fetchvalets)
}

export default fetchvaletSaga
