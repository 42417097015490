import { call, put, takeLatest } from "redux-saga/effects"
import axios from "axios"
import { toast } from "react-toastify"
import { DELETE_BOOKING_REQUEST } from "./actionType"
import { deleteBookingFailure, deleteBookingSuccess } from "./actions"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* deleteBooking(action) {
  console.log("idAction>>>", action.payload)

  try {
    yield call(
      axios.post,
      `${BASE_URL}/booking/delete`,
      // "http://13.234.30.42:5000/booking/delete",
      { bookingObjId: action.payload },

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(deleteBookingSuccess())
    toast.success("Booking deleted successfully")
    // dispatch(fetchCompaniesRequest(id))
  } catch (error) {
    yield put(deleteBookingFailure(error.message))
    toast.error("Failed to delete the Booking")
  }
}

export function* DeleteBookingsaga() {
  yield takeLatest(DELETE_BOOKING_REQUEST, deleteBooking)
}
