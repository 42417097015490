import React from "react"
import PropTypes from "prop-types"
export const ValetIconsvg = ({ color }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      fill={`${color}`}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <metadata>
        Created by potrace 1.16, written by Peter Selinger 2001-2019
      </metadata>
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={`${color}`}
        stroke="none"
      >
        <path
          d="M2295 4793 c-318 -37 -593 -100 -643 -146 -52 -48 -52 -50 -52 -442
     l0 -365 960 0 960 0 0 365 c0 392 0 394 -52 442 -43 41 -329 109 -574 138
     -116 14 -509 19 -599 8z"
        />
        <path
          d="M1604 3494 c-4 -14 -4 -91 -1 -172 4 -109 10 -166 26 -221 96 -330
     345 -577 677 -673 69 -20 101 -23 254 -23 153 0 185 3 254 23 332 96 581 343
     677 673 16 55 22 112 26 221 3 81 3 158 -1 172 l-6 26 -950 0 -950 0 -6 -26z"
        />
        <path
          d="M1689 2336 c-450 -263 -748 -671 -860 -1181 -20 -90 -22 -135 -26
     -467 l-5 -368 481 0 481 0 0 713 c0 391 5 730 10 751 5 22 17 51 28 65 10 14
     101 83 201 152 100 70 182 130 182 135 0 4 -27 18 -60 30 -90 34 -234 111
     -302 160 -32 24 -62 44 -67 43 -4 0 -32 -15 -63 -33z"
        />
        <path
          d="M3325 2344 c-44 -36 -181 -118 -248 -148 -90 -39 -207 -74 -320 -94
     l-102 -19 -287 -199 -288 -199 0 -682 0 -683 1121 0 1121 0 -5 368 c-4 332 -6
     377 -26 467 -113 513 -416 925 -873 1188 -26 15 -50 27 -55 27 -4 -1 -21 -12
     -38 -26z m-687 -774 c130 -79 74 -280 -78 -280 -85 0 -150 65 -150 150 0 116
     129 190 228 130z m0 -640 c130 -79 74 -280 -78 -280 -85 0 -150 65 -150 150 0
     85 65 150 150 150 27 0 58 -8 78 -20z"
        />
      </g>
    </svg>
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   // xmlns:xlink="http://www.w3.org/1999/xlink"
    //   width="20px"
    //   height="20px"
    //   viewBox="0 0 20 20"
    //   version="1.1"
    // >
    //   <g id="surface1">
    //     <path
    //       style={{
    //         stroke: "none",
    //         fillRule: "nonzero",
    //         fill: `${color}`,
    //         fillOpacity: 1,
    //       }}
    //       d="M 8.964844 1.277344 C 7.722656 1.421875 6.648438 1.667969 6.453125 1.847656 C 6.25 2.035156 6.25 2.042969 6.25 3.574219 L 6.25 5 L 13.75 5 L 13.75 3.574219 C 13.75 2.042969 13.75 2.035156 13.546875 1.847656 C 13.378906 1.6875 12.261719 1.421875 11.304688 1.308594 C 10.851562 1.253906 9.316406 1.234375 8.964844 1.277344 Z M 8.964844 1.277344 "
    //     />
    //     <path
    //       style={{
    //         stroke: "none",
    //         fillRule: "nonzero",
    //         fill: `${color}`,
    //         fillOpacity: 1,
    //       }}
    //       d="M 6.265625 6.351562 C 6.25 6.40625 6.25 6.707031 6.261719 7.023438 C 6.277344 7.449219 6.300781 7.671875 6.363281 7.886719 C 6.738281 9.175781 7.710938 10.140625 9.007812 10.515625 C 9.277344 10.59375 9.402344 10.605469 10 10.605469 C 10.597656 10.605469 10.722656 10.59375 10.992188 10.515625 C 12.289062 10.140625 13.261719 9.175781 13.636719 7.886719 C 13.699219 7.671875 13.722656 7.449219 13.738281 7.023438 C 13.75 6.707031 13.75 6.40625 13.734375 6.351562 L 13.710938 6.25 L 6.289062 6.25 Z M 6.265625 6.351562 "
    //     />
    //     <path
    //       style={{
    //         stroke: "none",
    //         fillRule: "nonzero",
    //         fill: `${color}`,
    //         fillOpacity: 1,
    //       }}
    //       d="M 6.597656 10.875 C 4.839844 11.902344 3.675781 13.496094 3.238281 15.488281 C 3.160156 15.839844 3.152344 16.015625 3.136719 17.3125 L 3.117188 18.75 L 6.875 18.75 L 6.875 15.964844 C 6.875 14.4375 6.894531 13.113281 6.914062 13.03125 C 6.933594 12.945312 6.980469 12.832031 7.023438 12.777344 C 7.0625 12.722656 7.417969 12.453125 7.808594 12.183594 C 8.199219 11.910156 8.519531 11.675781 8.519531 11.65625 C 8.519531 11.640625 8.414062 11.585938 8.285156 11.539062 C 7.933594 11.40625 7.371094 11.105469 7.105469 10.914062 C 6.980469 10.820312 6.863281 10.742188 6.84375 10.746094 C 6.828125 10.746094 6.71875 10.804688 6.597656 10.875 Z M 6.597656 10.875 "
    //     />
    //     <path
    //       style={{
    //         stroke: "none",
    //         fillRule: "nonzero",
    //         fill: `${color}`,
    //         fillOpacity: 1,
    //       }}
    //       d="M 12.988281 10.84375 C 12.816406 10.984375 12.28125 11.304688 12.019531 11.421875 C 11.667969 11.574219 11.210938 11.710938 10.769531 11.789062 L 10.371094 11.863281 L 9.25 12.640625 L 8.125 13.417969 L 8.125 18.75 L 16.882812 18.75 L 16.863281 17.3125 C 16.847656 16.015625 16.839844 15.839844 16.761719 15.488281 C 16.320312 13.484375 15.136719 11.875 13.351562 10.847656 C 13.25 10.789062 13.15625 10.742188 13.136719 10.742188 C 13.121094 10.746094 13.054688 10.789062 12.988281 10.84375 Z M 10.304688 13.867188 C 10.8125 14.175781 10.59375 14.960938 10 14.960938 C 9.667969 14.960938 9.414062 14.707031 9.414062 14.375 C 9.414062 13.921875 9.917969 13.632812 10.304688 13.867188 Z M 10.304688 16.367188 C 10.8125 16.675781 10.59375 17.460938 10 17.460938 C 9.667969 17.460938 9.414062 17.207031 9.414062 16.875 C 9.414062 16.542969 9.667969 16.289062 10 16.289062 C 10.105469 16.289062 10.226562 16.320312 10.304688 16.367188 Z M 10.304688 16.367188 "
    //     />
    //   </g>
    // </svg>
  )
}
ValetIconsvg.propTypes = {
  color: PropTypes.string,
  t: PropTypes.any,
}
