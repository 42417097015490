import { React, useEffect } from "react"
import "../../../../App.css"
import PropTypes from "prop-types"
import "bootstrap/dist/css/bootstrap.min.css"
import { Label } from "reactstrap"
import {
  BrowserRouter as Router,
  useHistory,
  useParams,
} from "react-router-dom"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import { withTranslation } from "react-i18next"
import { Constant } from "pages/Resources/Constant"
import { useDispatch, useSelector } from "react-redux"
import { fetchViewValetsRequest } from "store/actions"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { editValetRequest } from "store/actions"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/bootstrap.css"
import { useState } from "react"

const EditValetDrivers = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()
  console.log("valetId==>", id)

  const ViewValetData = useSelector(
    state => state.fetchViewvaletsReducer.Viewvalets.data
  )
  console.log("ViewValetData=>", ViewValetData)

  const Editvaletdata = useSelector(state => state.editValetReducer)

  console.log("Editvaletdata=>", Editvaletdata)
  const [phone, setPhone] = useState("")
  const [phoneError, setPhoneError] = useState("")
  useEffect(() => {
    dispatch(fetchViewValetsRequest(id))
  }, [])
  // useEffect(() => {
  //   setPhone(initialValues)
  // }, [initialValues])
  const addPhoneNumber = value => {
    setPhone(value)
    setPhoneError("")
  }
  const handleSubmit = values => {
    console.log("values-->", values)
    // if (!phone) {
    //   setPhoneError(props.t("Phone Number is Required"))
    //   return
    // } else {
    //   setPhoneError("")
    // }
    const formData = new FormData()
    formData.append("valetDriverObjId", id)
    formData.append("valetDriverName", values.valetDriverName)
    formData.append("email", values.email)
    formData.append("phone", phone ? phone : ViewValetData?.phone)

    formData.append("valetDriverId", values.valetDriverId)

    dispatch(editValetRequest(formData))
    if (Editvaletdata?.error == null) {
      toast.success(props.t("Valet Driver Updated successfully"))
    } else toast.error(Editvaletdata.error)

    setTimeout(() => {
      history.push("/valet_drivers")
    }, 2000)
  }

  const initialValues = {
    valetDriverName: ViewValetData?.valetDriverName,
    email: ViewValetData?.email,
    // phone: ViewValetData?.phone,
    valetDriverId: ViewValetData?.valetDriverId,
  }

  const validationSchema = Yup.object({
    valetDriverName: Yup.string().required(props.t("Name is Required")),
    email: Yup.string()
      .matches(
        /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i,
        props.t("Invalid email")
      )
      .required(props.t("Email is required")),
    // phone: Yup.string().required(props.t("Phone Number is Required")),

    valetDriverId: Yup.string().required(props.t("Company ID is Required")),
  })

  const cancelHandler = () => {
    history.push("/valet_drivers")
  }
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  return (
    <div className={`main-content${classAlign}`}>
      {(document.title = "Valet Driver | Nater")}
      <div className="page-content">
        <ToastContainer />
        <div className="registerValet" style={{ marginLeft: "15px" }}>
          <div id="formValet">
            <h5>
              <strong>{props.t(Constant.updatevalet)}</strong>
            </h5>
            <br />
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <Label className={`label${classAlign}`}>
                  {props.t(Constant.name)}
                </Label>
                <div className="passwordValet">
                  <Field
                    className="form-control"
                    style={{ backgroundColor: "#f1f1f9" }}
                    type="text"
                    name="valetDriverName"
                    placeholder={props.t(Constant.name)}
                  />
                  <p className="textdanger1">
                    <ErrorMessage name="valetDriverName" />
                  </p>
                </div>

                <br />
                <Label className={`label${classAlign}`}>
                  {props.t(Constant.email)}
                </Label>
                <div className="passwordValet">
                  <Field
                    className="form-control"
                    style={{ backgroundColor: "#f1f1f9" }}
                    type="text"
                    name="email"
                    placeholder={props.t(Constant.email)}
                  />
                  <p className="textdanger1">
                    <ErrorMessage name="email" />
                  </p>
                </div>

                <br />
                {/* <Label className={`label${classAlign}`}>
                  {props.t(Constant.phonenumber)}
                </Label>
                <div className="passwordValet">
                  <Field
                    className="form-control"
                    style={{ backgroundColor: "#f1f1f9" }}
                    type="text"
                    name="phone"
                    placeholder={props.t(Constant.phonenumber)}
                  />
                  <p className="textdanger1">
                    <ErrorMessage name="phone" />
                  </p>
                </div> */}
                <Label className={`label${classAlign}`}>
                  {props.t(Constant.phonenumber)}
                </Label>
                <br />
                <div className="passwordValet">
                  <PhoneInput
                    country={"eg"}
                    enableSearch={true}
                    value={phone ? phone : ViewValetData?.phone}
                    onChange={addPhoneNumber}
                    // initialValues={ViewValetData?.phone}
                  />
                  {/* <p className="textdanger1">{props.t(phoneError)}</p> */}
                </div>
                <br />

                <Label className={`label${classAlign}`}>
                  {props.t(Constant.valetID)}
                </Label>
                <div className="passwordValet">
                  <Field
                    className="form-control"
                    style={{ backgroundColor: "#f1f1f9" }}
                    type="text"
                    name="valetDriverId"
                    placeholder={props.t(Constant.valetID)}
                  />
                  <p className="textdanger1">
                    <ErrorMessage name="valetDriverId" />
                  </p>
                </div>

                <br />

                <div className="inlineFieldsBtn">
                  <button
                    className={`cancelbtn${classAlign}`}
                    type="button"
                    onClick={() => cancelHandler()}
                  >
                    {props.t("Cancel")}
                  </button>
                  <button
                    className={`submitbtn${classAlign}`}
                    type="submit"
                    onSubmit={handleSubmit}
                  >
                    {props.t("Update")}
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}
EditValetDrivers.propTypes = {
  t: PropTypes.any,
  state: PropTypes.object,
  location: PropTypes.func,
}

export default withTranslation()(EditValetDrivers)
