import {
  FETCH_VALET_REQUEST,
  FETCH_VALET_SUCCESS,
  FETCH_VALET_FAILURE,
} from "./actionType"

export const fetchvaletsRequest = (n, search, sortOption, bookingOption) => ({
  type: FETCH_VALET_REQUEST,
  payload: {
    page: n,
    searchedText: search,
    sort: sortOption,
    booking: bookingOption,
  },
})

export const fetchvaletsSuccess = valets => ({
  type: FETCH_VALET_SUCCESS,
  payload: valets,
})

export const fetchvaletsFailure = error => ({
  type: FETCH_VALET_FAILURE,
  payload: error,
})
