import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"
import { FETCH_VIEW_PAYMENT_REQUEST } from "./actionType"
import { fetchViewPaymentFailure, fetchViewPaymentSuccess } from "./actions"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchViewPayment(action) {
  // const id = localStorage.getItem("_id")
  // console.log("valetId==>", id)
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/admin/transaction/detail`,

      { transactionObjId: action.payload },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchViewPaymentSuccess(response.data))
  } catch (error) {
    yield put(fetchViewPaymentFailure(error))
  }
}

function* fetchViewPaymentSaga() {
  yield takeEvery(FETCH_VIEW_PAYMENT_REQUEST, fetchViewPayment)
}

export default fetchViewPaymentSaga
