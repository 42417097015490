import { React, useEffect, useState } from "react"
import { useHistory } from "react-router"
// import "./FeaturedInfo.css"
import PropTypes from "prop-types"

import {
  FaUserGraduate,
  FaHome,
  FaChalkboardTeacher,
  FaJenkins,
} from "react-icons/fa"
import { MdFamilyRestroom } from "react-icons/md"

import moment from "moment"
// import schoolDataObject from "../Controllers/SchoolController"
import { withTranslation } from "react-i18next"

import { MdPregnantWoman } from "react-icons/md"
import { SlCalender } from "react-icons/sl"
import { SiCashapp } from "react-icons/si"

function FeaturedinfoCompany(props) {
  const [totalStudents, setTotalStudents] = useState("")
  const [totalTeachers, setTotalTeachers] = useState("")
  const [totalParents, setTotalParents] = useState("")

  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD")
  const endOfMonth = moment().endOf("month").format("YYYY-MM-DD")
  // console.log("zzzzzzzzzz", startOfMonth, endOfMonth)
  const history = useHistory()
  const cardData = () => {
    let body = {
      schoolid: localStorage.getItem("schoolId"),
      from: startOfMonth,
      to: endOfMonth,
    }
    // schoolDataObject.getDashboardData(body, result => {
    //   console.log("result", result)
    //   if (result.data.status === true) {
    //     setTotalStudents(result.data.StudentCount[0].total_student)
    //     setTotalTeachers(result.data.TeacherCount[0].total_teacher)
    //     setTotalParents(result.data.ParentsCount[0].total_parents)
    //   } else {
    //   }
    // })
  }
  useEffect(() => {
    cardData()
  }, [])

  return (
    <div className="featured">
      <div
        className="featuredItem"
        onClick={() => history.push("/student_management")}
      >
        <div className="featuredMoneyContainer">
          <MdPregnantWoman className="featuredIcon " />
          <span className="featuredMoney">{totalStudents}</span>
          <span className="featuredMoneyRate">{props.t("Valet Driver")}</span>
        </div>
      </div>
      <div
        className="featuredItem"
        onClick={() => history.push("/teacher_management")}
      >
        <div className="featuredMoneyContainer">
          <SlCalender className="featuredIcon " />
          <span className="featuredMoney">{totalTeachers}</span>
          <span className="featuredMoneyRate">{props.t("Booking")}</span>
        </div>
      </div>
      <div
        className="featuredItem"
        onClick={() => history.push("/parents_management")}
      >
        <div className="featuredMoneyContainer">
          <SiCashapp className="featuredIcon" />
          <span className="featuredMoney">{totalParents}</span>
          <span className="featuredMoneyRate">{props.t("Payment")}</span>
        </div>
      </div>
    </div>
  )
}
FeaturedinfoCompany.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(FeaturedinfoCompany)
