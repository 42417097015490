import { call, put, takeLatest } from "redux-saga/effects"
import axios from "axios"
import { FETCH_COMPANIES_FILTER_REQUEST } from "./actionType"
import {
  fetchCompaniesFilterFailure,
  fetchCompaniesFilterSuccess,
} from "./actions"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchCompaniesfilter() {
  // console.log("SearcH==>", action.payload.searchedText)
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/company/list`,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchCompaniesFilterSuccess(response.data))
  } catch (error) {
    yield put(fetchCompaniesFilterFailure(error))
  }
}

function* fetchcompanyfilterSaga() {
  yield takeLatest(FETCH_COMPANIES_FILTER_REQUEST, fetchCompaniesfilter)
}

export default fetchcompanyfilterSaga
