import React, { useEffect } from "react"
import { Button } from "@material-ui/core"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Link, useHistory } from "react-router-dom"
import { FaEdit, FaEye } from "react-icons/fa"
import { MdDeleteOutline } from "react-icons/md"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { SpaRounded } from "@mui/icons-material"
import { Constant } from "pages/Resources/Constant"
import Searchbar from "../Searchbar/Searchbar"
import Pagination from "react-responsive-pagination"
import { fetchPaymentValetRequest } from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"
// import "react-dates/initialize"
// import { DateRangePicker } from "react-dates"
// import "react-dates/lib/css/_datepicker.css"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import { IoIosRefresh } from "react-icons/io"

const PaymentValet = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [focusedInput, setFocusedInput] = useState("")
  const isOutsideRange = date => date.isAfter()

  const [searchData, setSearchData] = useState("")
  const [sortOption, setSortOption] = useState("")
  const [filterOption, setFilterOption] = useState("")
  const [monthOption, setMonthOption] = useState("")
  const [dayOption, setDayOption] = useState("")
  const [amountOption, setAmountOption] = useState("")
  const [paymentMethodOption, setPaymentMethodOption] = useState("")
  const [statusOption, setStatusOption] = useState("")
  const [rotation, setRotation] = useState(0)
  const [arrayFilter, setArrayFilter] = useState([])
  console.log("sortOption>>", sortOption)
  console.log("filterOption>>", filterOption)
  console.log("amountOption>>", amountOption)
  console.log("paymentMethodOption>>", paymentMethodOption)
  console.log("startDate>>", startDate)
  console.log("endDate>>", endDate)
  console.log("month>>", monthOption)
  console.log("day>>", dayOption)

  // const originalStartDate = startDate ? new Date(startDate?._d) : ""
  // const formattedStartDate = originalStartDate
  //   ? originalStartDate.toISOString()
  //   : ""
  // console.log("formattedStartDate>>", formattedStartDate)

  // const originalEndDate = endDate ? new Date(endDate._d) : ""
  // const formattedEndDate = originalEndDate ? originalEndDate.toISOString() : ""
  // console.log("formattedEndDate>>", formattedEndDate)
  const originalStartDate = startDate ? moment(new Date(startDate)) : ""

  const formattedStartDate = originalStartDate
    ? originalStartDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    : ""
  console.log("formattedStartDate", formattedStartDate)
  const originalEndDate = endDate ? moment(new Date(endDate)) : ""
  const formattedEndDate = originalEndDate
    ? originalEndDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    : ""
  console.log("formattedStartDate->", formattedStartDate)
  console.log("formattedEndDate", formattedEndDate)

  const DateFormate = createdAt => {
    const date = new Date(createdAt)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    return `${day}/${month}/${year}`
    console.log(`${day}-${month}-${year} ${hours}:${minutes}:${seconds}`)
  }
  const DayFormate = bookingStartTime => {
    const date = new Date(bookingStartTime)
    const options = { weekday: "long" }
    const dayOfWeek = new Intl.DateTimeFormat("en-US", options).format(date)
    console.log("dayweek", dayOfWeek)
    return `${dayOfWeek}`
  }

  const handleSearchData = searchtext => {
    console.log("searchbarValue==>", searchtext)
    setSearchData(searchtext)
    setCurrentPage(1)
  }
  const handleSortChange = event => {
    setSortOption(event.target.value)
  }

  const handleFilterChange = event => {
    setFilterOption(event.target.value)
    // setAmountOption("") // Reset amount option when changing filters
    // setPaymentMethodOption("") // Reset payment method option when changing filters
    // setStartDate("")
    // setEndDate("")
    // setFocusedInput("")
    // setMonthOption("")
    // setDayOption("")
    setArrayFilter([...arrayFilter, event.target.value])
  }
  var newArrayFilter = Array.from(new Set(arrayFilter))
  const handleAmountChange = event => {
    setAmountOption(event.target.value)
  }
  const handleMonthChange = event => {
    setMonthOption(event.target.value)
  }
  const handleDayChange = event => {
    setDayOption(event.target.value)
  }
  const handlePaymentMethodChange = event => {
    setPaymentMethodOption(event.target.value)
  }
  const handleStatusChange = event => {
    setStatusOption(event.target.value)
  }
  useEffect(() => {
    dispatch(
      fetchPaymentValetRequest(
        currentPage,
        searchData,
        sortOption,
        formattedStartDate,
        formattedEndDate,
        amountOption,
        paymentMethodOption,
        monthOption,
        dayOption,
        statusOption
      )
    )
  }, [
    currentPage,
    searchData,
    sortOption,
    formattedStartDate,
    formattedEndDate,
    amountOption,
    paymentMethodOption,
    startDate,
    endDate,
    monthOption,
    dayOption,
    statusOption,
  ])

  const PaymentValetData = useSelector(
    state => state.fetchpaymentvaletReducer.paymentvalets
  )
  console.log("PaymentValetData=>", PaymentValetData)

  function createData(name, Email, Phone_Number, Country, Status, Action) {
    return { name, Email, Phone_Number, Status, Country, Action }
  }

  const rows = [
    createData("Aayush", "aayush00@gmail.com", 9658741265, "Active", "India"),
    createData(
      "Rohit",
      "aayush00@gmail.com",
      9658741265,
      "Active",
      "India",
      4.3
    ),
    createData(
      "Aditya",
      "aayush00@gmail.com",
      9658741265,
      "Active",
      "India",
      6.0
    ),
    createData(
      "Neha",
      "aayush00@gmail.com",
      9658741265,
      "Active",
      "India",
      4.3
    ),
    createData(
      "Shubham",
      "aayush00@gmail.com",
      9658741265,
      "Active",
      "India",
      3.9
    ),
  ]
  const deleteAlret = () => {
    alert("Delete parking")
  }

  const addParkingHandler = () => {
    history.push("/add_parkinglocation")
  }
  const editParkingHandler = () => {
    history.push("/edit_parkinglocation")
  }
  const viewPaymentvaletHandler = id => {
    history.push(`/view_paymentvalet/${id}`)
  }
  const handleStartDateChange = date => {
    setStartDate(date)
  }
  const handleEndDateChange = date => {
    setEndDate(date)
  }
  const HandleRefreshFilter = () => {
    setRotation(rotation + 360)
    // setSearchData("")
    setAmountOption("")
    setPaymentMethodOption("")
    setStatusOption("")
    setStartDate("")
    setEndDate("")
    setFocusedInput("")
    setFilterOption("")
    setSortOption("")
    setMonthOption("")
    setDayOption("")
    setCurrentPage(1)
    setArrayFilter([])
  }
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  return (
    <div className={`main-content${classAlign}`}>
      {(document.title = "Payment | Nater")}
      <div className="page-content">
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginLeft: "10px",
              width: "40%",
            }}
          >
            <h3>
              <strong>{props.t(Constant.payment)}</strong>
            </h3>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              width: "60%",
            }}
          >
            <div>
              <Searchbar
                placeholder={props.t(Constant.search)}
                searchHandler={handleSearchData}
              />
            </div>
          </div>
        </div>
        <br />
        <div className="bookingfiltermaindiv">
          <div className="companyfilterseconddiv">
            <select
              value={sortOption}
              onChange={handleSortChange}
              className="filterstyle"
            >
              <option value="">{props.t(Constant.sortby)}</option>
              <option value="-1">{props.t(Constant.newest)}</option>
              <option value="1">{props.t(Constant.oldest)}</option>
            </select>

            <select
              value={filterOption}
              onChange={handleFilterChange}
              className="filterstyle"
            >
              <option value="">{props.t(Constant.filterby)}</option>
              <option value="date">{props.t(Constant.date)}</option>
              <option value="month">{props.t(Constant.month)}</option>
              <option value="day">{props.t(Constant.day)}</option>
              <option value="amount">{props.t(Constant.amount)}</option>
              <option value="status">{props.t(Constant.status)}</option>
              <option value="paymentMethod">
                {props.t(Constant.paymentmethod)}
              </option>
            </select>
            {newArrayFilter.map((option, i) => {
              console.log("option", option)
              // })}
              if (option === "date") {
                return (
                  <div key={i}>
                    <div className="startenddatediv">
                      <DatePicker
                        selected={startDate}
                        onChange={handleStartDateChange}
                        placeholderText={props.t(Constant.startdate)}
                        className="custom-datepicker"
                      />
                      <DatePicker
                        selected={endDate}
                        onChange={handleEndDateChange}
                        placeholderText={props.t(Constant.enddate)}
                        className="custom-datepicker"
                      />
                    </div>
                  </div>
                )
              }
              if (option === "month") {
                return (
                  <select
                    value={monthOption}
                    onChange={handleMonthChange}
                    className="filterstyle"
                    key={i}
                  >
                    <option value="">{props.t(Constant.month)}</option>
                    <option value="1">{props.t(Constant.january)}</option>
                    <option value="2">{props.t(Constant.february)}</option>
                    <option value="3">{props.t(Constant.march)}</option>
                    <option value="4">{props.t(Constant.april)}</option>
                    <option value="5">{props.t(Constant.may)}</option>
                    <option value="6">{props.t(Constant.june)}</option>
                    <option value="7">{props.t(Constant.july)}</option>
                    <option value="8">{props.t(Constant.august)}</option>
                    <option value="9">{props.t(Constant.september)}</option>
                    <option value="10">{props.t(Constant.october)}</option>
                    <option value="11">{props.t(Constant.november)}</option>
                    <option value="12">{props.t(Constant.december)}</option>
                  </select>
                )
              }
            })}
          </div>
          <div className="companyfilterthirddiv">
            {newArrayFilter.map((option, i) => {
              console.log("option1", option)

              if (option === "day") {
                return (
                  <select
                    value={dayOption}
                    onChange={handleDayChange}
                    className="filterstyle"
                    key={i}
                  >
                    <option value="">{props.t(Constant.day)}</option>
                    <option value="0">{props.t(Constant.sunday)}</option>
                    <option value="1">{props.t(Constant.monday)}</option>
                    <option value="2">{props.t(Constant.tuesday)}</option>
                    <option value="3">{props.t(Constant.wednesday)}</option>
                    <option value="4">{props.t(Constant.thursday)}</option>
                    <option value="5">{props.t(Constant.friday)}</option>
                    <option value="6">{props.t(Constant.saturday)}</option>
                  </select>
                )
              }
              if (option === "amount") {
                return (
                  <select
                    value={amountOption}
                    onChange={handleAmountChange}
                    className="filterstyle"
                    key={i}
                  >
                    <option value="">{props.t(Constant.amountrange)}</option>
                    <option value="0-50">0 - 50</option>
                    <option value="50-100">50 - 100</option>
                    <option value="100-150">100 - 150</option>
                    <option value="150">150+</option>
                  </select>
                )
              }
              if (option === "status") {
                return (
                  <select
                    value={statusOption}
                    onChange={handleStatusChange}
                    className="filterstyle"
                    key={i}
                  >
                    <option value="">{props.t(Constant.status)}</option>
                    <option value="success">Success</option>
                    <option value="failed">Failed</option>
                    <option value="pending">Pending</option>
                  </select>
                )
              }
              if (option === "paymentMethod") {
                return (
                  <select
                    value={paymentMethodOption}
                    onChange={handlePaymentMethodChange}
                    className="filterstyle"
                    key={i}
                  >
                    <option value="">{props.t(Constant.paymentmethod)}</option>
                    <option value="card">{props.t(Constant.card)}</option>
                    <option value="wallet">{props.t(Constant.wallet)}</option>
                    <option value="applePay">
                      {props.t(Constant.applepay)}
                    </option>
                  </select>
                )
              }
            })}
            {/* {filterOption === "date" && (
              <div>
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  placeholderText={props.t(Constant.startdate)}
                  className="custom-datepicker"
                />
              </div>
            )}
            {filterOption === "date" && (
              <div>
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  placeholderText={props.t(Constant.enddate)}
                  className="custom-datepicker"
                />
              </div>
            )} */}
            {/* {filterOption === "month" && (
              // <label style={{ marginRight: "10px" }}>
              //   Amount Range:
              <select
                value={monthOption}
                onChange={handleMonthChange}
                className="filterstyle"
              >
                <option value="">{props.t(Constant.month)}</option>
                <option value="1">{props.t(Constant.january)}</option>
                <option value="2">{props.t(Constant.february)}</option>
                <option value="3">{props.t(Constant.march)}</option>
                <option value="4">{props.t(Constant.april)}</option>
                <option value="5">{props.t(Constant.may)}</option>
                <option value="6">{props.t(Constant.june)}</option>
                <option value="7">{props.t(Constant.july)}</option>
                <option value="8">{props.t(Constant.august)}</option>
                <option value="9">{props.t(Constant.september)}</option>
                <option value="10">{props.t(Constant.october)}</option>
                <option value="11">{props.t(Constant.november)}</option>
                <option value="12">{props.t(Constant.december)}</option>
              </select>
              // </label>
            )} */}
            {/* {filterOption === "day" && (
              // <label style={{ marginRight: "10px" }}>
              //   Amount Range:
              <select
                value={dayOption}
                onChange={handleDayChange}
                className="filterstyle"
              >
                <option value="">{props.t(Constant.day)}</option>
                <option value="0">{props.t(Constant.sunday)}</option>
                <option value="1">{props.t(Constant.monday)}</option>
                <option value="2">{props.t(Constant.tuesday)}</option>
                <option value="3">{props.t(Constant.wednesday)}</option>
                <option value="4">{props.t(Constant.thursday)}</option>
                <option value="5">{props.t(Constant.friday)}</option>
                <option value="6">{props.t(Constant.saturday)}</option>
              </select>
              // </label>
            )}
            {filterOption === "amount" && (
              // <label style={{ marginRight: "10px" }}>
              //   Amount Range:
              <select
                value={amountOption}
                onChange={handleAmountChange}
                className="filterstyle"
              >
                <option value="">{props.t(Constant.amountrange)}</option>
                <option value="0-50">0 - 50</option>
                <option value="50-100">50 - 100</option>
                <option value="100-150">100 - 150</option>
                <option value="150">150+</option>
              </select>
              // </label>
            )}
            {filterOption === "paymentMethod" && (
              // <label>
              //   Payment Method:
              <select
                value={paymentMethodOption}
                onChange={handlePaymentMethodChange}
                className="filterstyle"
              >
                <option value="">{props.t(Constant.paymentmethod)}</option>
                <option value="card">{props.t(Constant.card)}</option>
                <option value="wallet">{props.t(Constant.wallet)}</option>
                <option value="applePay">{props.t(Constant.applepay)}</option>
              </select>
              // </label>
            )} */}
            {/* <span className="refreshButton" onClick={HandleRefreshFilter}>
              <IoIosRefresh
                className="refreshicon"
                style={{ transform: `rotate(${rotation}deg)` }}
              />
            </span> */}
          </div>
          <div className="companyfilterthirddiv">
            <span className="refreshButton" onClick={HandleRefreshFilter}>
              <IoIosRefresh
                className="refreshicon"
                style={{ transform: `rotate(${rotation}deg)` }}
              />
            </span>
          </div>
        </div>
        <br />
        {PaymentValetData.data?.docs.length === 0 ? (
          <div className="datanotavailablemessage">
            <div>
              <h1>{props.t("No Payment Available")}</h1>
            </div>
          </div>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.username)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.date)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.day)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.currency)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.status)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.paymentmode)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.totalamountcharged)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.action)}</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {PaymentValetData.data?.docs &&
                  PaymentValetData.data?.docs.map(row => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" align="center">
                        {row.userObjId?.name}
                      </TableCell>
                      <TableCell align="center">
                        {row.createdAt ? DateFormate(row.createdAt) : "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {row.createdAt ? DayFormate(row.createdAt) : "NA"}
                      </TableCell>
                      <TableCell align="center">{row.currency}</TableCell>
                      <TableCell align="center">{row.status}</TableCell>
                      <TableCell align="center">{row.type}</TableCell>
                      <TableCell align="center">{row.amount}</TableCell>
                      <TableCell align="center">
                        {
                          <div>
                            {/* <span onClick={editParkingHandler}>
                          {" "}
                          <FaEdit className="userListEdit" />
                        </span>{" "} */}
                            <span
                              onClick={() => viewPaymentvaletHandler(row._id)}
                            >
                              {" "}
                              <FaEye className="userListView" />
                            </span>{" "}
                            {/* <span onClick={() => deleteAlret()}>
                          <MdDeleteOutline className="userListDelete" />
                        </span> */}
                          </div>
                        }
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {PaymentValetData.data?.docs.length === 0 ? (
          ""
        ) : (
          <div style={{ marginTop: 10 }}>
            <Pagination
              current={currentPage}
              maxWidth={5}
              total={PaymentValetData.data?.totalPages}
              onPageChange={n => {
                setCurrentPage(n)
                // dispatch(fetchPaymentValetRequest(n))
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
PaymentValet.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(PaymentValet)
