import {
  FETCH_BOOKING_FAILURE,
  FETCH_BOOKING_REQUEST,
  FETCH_BOOKING_SUCCESS,
} from "./actionType"

export const fetchbookingRequest = (
  n,
  search,
  sortOption,
  formattedStartDate,
  formattedEndDate,
  amountOption,
  bookingStatusOption,
  companyOption,
  monthOption,
  dayOption,
  parkingdurationOption
) => (
  console.log("nnnnnnnnnnnn==>", search),
  {
    type: FETCH_BOOKING_REQUEST,
    payload: {
      page: n,
      searchedText: search,
      sort: sortOption,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      amount: amountOption,
      bookingStatus: bookingStatusOption,
      companyObjId: companyOption,
      month: monthOption,
      day: dayOption,
      parkingDuration: parkingdurationOption,
    },
  }
)

export const fetchbookingSuccess = bookings => ({
  type: FETCH_BOOKING_SUCCESS,
  payload: bookings,
})

export const fetchbookingFailure = error => ({
  type: FETCH_BOOKING_FAILURE,
  payload: error,
})
