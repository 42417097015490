import {
  FETCH_BOOKINGVALET_FAILURE,
  FETCH_BOOKINGVALET_REQUEST,
  FETCH_BOOKINGVALET_SUCCESS,
} from "./actionType"

export const fetchbookingvaletRequest = (
  n,
  search,
  sortOption,
  formattedStartDate,
  formattedEndDate,
  amountOption,
  bookingStatusOption,
  monthOption,
  dayOption,
  parkingdurationOption
) => ({
  type: FETCH_BOOKINGVALET_REQUEST,
  payload: {
    page: n,
    searchedText: search,
    sort: sortOption,
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    amount: amountOption,
    bookingStatus: bookingStatusOption,
    month: monthOption,
    day: dayOption,
    parkingDuration: parkingdurationOption,
  },
})

export const fetchbookingvaletSuccess = bookingvalets => ({
  type: FETCH_BOOKINGVALET_SUCCESS,
  payload: bookingvalets,
})

export const fetchbookingvaletFailure = error => ({
  type: FETCH_BOOKINGVALET_FAILURE,
  payload: error,
})
