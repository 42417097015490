import {
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
} from "./actionType"

export const fetchCompaniesRequest = (n, search, sortOption, valetOption) => (
  console.log("nnnnn==>"),
  {
    type: FETCH_COMPANIES_REQUEST,
    payload: {
      page: n,
      searchedText: search,
      sort: sortOption,
      valets: valetOption,
    },
  }
)

export const fetchCompaniesSuccess = companies => ({
  type: FETCH_COMPANIES_SUCCESS,
  payload: companies,
})

export const fetchCompaniesFailure = error => ({
  type: FETCH_COMPANIES_FAILURE,
  payload: error,
})
