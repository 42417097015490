import { React, useEffect } from "react"

import DashCard from "./Card.Component"
import "./Dashboard.style.css"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

import { Constant } from "pages/Resources/Constant"
import { useDispatch } from "react-redux"
import { fetchDashboardRequest } from "store/actions"
import { useSelector } from "react-redux"

import { HiBuildingOffice2 } from "react-icons/hi2" //company
import { FaParking } from "react-icons/fa" // booking
import { MdPayment } from "react-icons/md" // payment
import { BiUser } from "react-icons/bi" // user

function DashboardCards(props) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchDashboardRequest())
  }, [])

  const DashboardCardData = useSelector(
    state => state.fetchdashboardReducer?.dashboardData.data
  )
  console.log("DashboardCardData=>", DashboardCardData)

  const cardHeader = [
    {
      headers: props.t(Constant.company),
      value: DashboardCardData?.companyCount,
      icon: <HiBuildingOffice2 className="icons" />,
    },
    {
      headers: props.t(Constant.booking),
      value: DashboardCardData?.bookingCount.total,
      icon: <FaParking className="icons" />,
    },
    {
      headers: props.t(Constant.totalamountQAR),
      value: DashboardCardData?.totalPayment,
      icon: <MdPayment className="icons" />,
    },
    {
      headers: props.t(Constant.user),
      value: DashboardCardData?.userCount,
      icon: <BiUser className="icons" />,
    },
  ]
  return (
    <div className="dashCard-list">
      {cardHeader.map(h => {
        return (
          <DashCard
            key={h.value}
            heading={h.headers}
            footerValue={h.value}
            icons={h.icon}
          />
        )
      })}
    </div>
  )
}
DashboardCards.propTypes = {
  t: PropTypes.any,
}
export default withTranslation()(DashboardCards)
