import React from "react"
import MetaTags from "react-meta-tags"

import { Formik, Form } from "formik"
import * as Yup from "yup"
import TextField from "../Common/TextField"
import "../../../../App.css"
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"
import logo from "../../Assets/Images/aac_logo.png"
import forgot from "../../Assets/Images/forgot_password.png"

const ForgotPasswordAfter = () => {
  const validate = Yup.object({
    schoolName: Yup.string().required("Required"),
    lastName: Yup.string()
      .max(15, "Must be 20 characters or less")
      .required("Required"),
    email: Yup.string().email("Email is Invalid").required("Email is Required"),
    password: Yup.string()
      .min(8, "Password must have 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password must match")
      .required("Confirm Password is required"),
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Forgot Password | AAC</title>
      </MetaTags>
      <div className="App">
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={validate}
          onSubmit={values => {}}
        >
          {formik => (
            <div className="forgotPasswordAfter">
              <form id="form" style={{ marginTop: "20px" }}>
                <img src={logo} style={{ width: "145px", height: "134px" }} />
                <h1 style={{ fontSize: "25px" }}>Forgot Password</h1>
                <br />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={forgot}
                    style={{ width: "100px", height: "100px" }}
                  />
                  <br />
                  <p style={{ fontSize: "20px", color: "grey" }}>Thank You!</p>
                  <p
                    style={{
                      fontSize: "20px",
                      color: "grey",
                      maxWidth: "250px",
                    }}
                  >
                    We have sent an email to reset your password.
                  </p>
                </div>
                <br />
                <p
                  style={{
                    color: "grey",
                    marginTop: "10px",
                    float: "center",
                  }}
                >
                  <Link to="/">{"< Back to login"}</Link>
                </p>
              </form>
            </div>
          )}
        </Formik>
      </div>
    </React.Fragment>
  )
}

export default ForgotPasswordAfter
