import React from "react"
import { Button } from "@material-ui/core"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Link, useHistory } from "react-router-dom"
import { FaEdit, FaEye } from "react-icons/fa"
import { MdDeleteOutline } from "react-icons/md"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { SpaRounded } from "@mui/icons-material"

const ParkingLocation = props => {
  const history = useHistory()

  function createData(name, Email, Phone_Number, Country, Status, Action) {
    return { name, Email, Phone_Number, Status, Country, Action }
  }

  const rows = [
    createData(
      "Aayush",
      "aayush00@gmail.com",
      9658741265,
      "Active",
      "India",
      <div className="action">
        {/* <FaEdit className="userListEdit" />&nbsp;&nbsp;<FaEye className="userListView" /><MdDeleteOutline className="userListDelete" /> */}
      </div>
    ),
    createData(
      "Rohit",
      "aayush00@gmail.com",
      9658741265,
      "Active",
      "India",
      4.3
    ),
    createData(
      "Aditya",
      "aayush00@gmail.com",
      9658741265,
      "Active",
      "India",
      6.0
    ),
    createData(
      "Neha",
      "aayush00@gmail.com",
      9658741265,
      "Active",
      "India",
      4.3
    ),
    createData(
      "Shubham",
      "aayush00@gmail.com",
      9658741265,
      "Active",
      "India",
      3.9
    ),
  ]
  const deleteAlret = () => {
    alert("Delete parking")
  }

  const addParkingHandler = () => {
    history.push("/add_parkinglocation")
  }
  const editParkingHandler = () => {
    history.push("/edit_parkinglocation")
  }
  const viewParkingHandler = () => {
    history.push("/view_parkinglocation")
  }

  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  return (
    <div className={`main-content${classAlign}`}>
      {(document.title = "Parent | AAC")}
      <div className="page-content">
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div className="addButton" onClick={addParkingHandler}>
            <Button style={{ color: "white" }}>{props.t("Add +")}</Button>
            {/* <div>+</div> */}
          </div>
        </div>
        <br />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="tableHeaderCell">
                  <strong>Name</strong>
                </TableCell>
                <TableCell className="tableHeaderCell" align="center">
                  <strong> Email</strong>
                </TableCell>
                <TableCell className="tableHeaderCell" align="center">
                  <strong>Phone_Number</strong>
                </TableCell>
                <TableCell className="tableHeaderCell" align="center">
                  <strong>Status</strong>
                </TableCell>
                <TableCell className="tableHeaderCell" align="center">
                  <strong>Country</strong>
                </TableCell>
                <TableCell className="tableHeaderCell" align="center">
                  <strong>Action</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="center">{row.Email}</TableCell>
                  <TableCell align="center">{row.Phone_Number}</TableCell>
                  <TableCell align="center">{row.Country}</TableCell>
                  <TableCell align="center">{row.Status}</TableCell>
                  <TableCell align="center">
                    {
                      <div>
                        <span onClick={editParkingHandler}>
                          {" "}
                          <FaEdit className="userListEdit" />
                        </span>{" "}
                        <span onClick={viewParkingHandler}>
                          {" "}
                          <FaEye className="userListView" />
                        </span>{" "}
                        <span onClick={() => deleteAlret()}>
                          <MdDeleteOutline className="userListDelete" />
                        </span>
                      </div>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}
ParkingLocation.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ParkingLocation)
