import { React, useEffect, useState } from "react"
import "../../../../App.css"
import PropTypes from "prop-types"
import "bootstrap/dist/css/bootstrap.min.css"
import { FormGroup, Label, Input, Button, Form } from "reactstrap"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useHistory,
  useParams,
} from "react-router-dom"
import { withTranslation } from "react-i18next"

const EditParkingLocation = props => {
  const history = useHistory()
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [designation, setDesignation] = useState("")
  const [nameError, setNameError] = useState("")
  const [emailError, setEmailError] = useState("")
  const [designationError, setDesignationError] = useState("")

  const cancelHandler = () => {
    history.push("/parking_location")
  }
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  return (
    <div className={`main-content${classAlign}`}>
      {(document.title = "Parent | AAC")}
      <div className="page-content">
        <div className="registerValet" style={{ marginLeft: "15px" }}>
          <div id="formValet">
            <h5>{props.t("Update Parking Location")}</h5>
            <br />
            <Form>
              <Label className={`label${classAlign}`}>{props.t("Name")}</Label>
              <Input
                className="textfieldValet"
                type="text"
                name=""
                onChange={e => setName(e.target.value)}
                value={name}
                placeholder={props.t("Name")}
              />
              {nameError && (
                <p style={{ color: "red" }}>{props.t(nameError)}</p>
              )}

              <br />
              <Label className={`label${classAlign}`}>{props.t("Email")}</Label>
              <Input
                className="textfieldValet"
                type="email"
                name="email"
                disabled={true}
                onChange={e => setEmail(e.target.value)}
                value={email}
                placeholder={props.t("Email")}
              />
              {emailError && (
                <p style={{ color: "red" }}>{props.t(emailError)}</p>
              )}

              <br />
              <Label className={`label${classAlign}`}>
                {props.t("Phone Number")}
              </Label>
              <Input
                className="textfieldValet"
                type="number"
                name="Phone Number"
                onChange={e => setDesignation(e.target.value)}
                value={designation}
                placeholder={props.t("Phone Number")}
              />
              {designationError && (
                <p style={{ color: "red" }}>{props.t(designationError)}</p>
              )}
              <br />

              <Label className={`label${classAlign}`}>
                {props.t("Address")}
              </Label>
              <Input
                className="textfieldValet"
                type="text"
                name="Address"
                onChange={e => setName(e.target.value)}
                value={name}
                placeholder={props.t("Address")}
              />
              {nameError && (
                <p style={{ color: "red" }}>{props.t(nameError)}</p>
              )}

              <br />
              <Label className={`label${classAlign}`}>
                {props.t("Status")}
              </Label>
              <Input
                className="textfieldValet"
                type="text"
                name="Status"
                onChange={e => setName(e.target.value)}
                value={name}
                placeholder={props.t("Status")}
              />
              {nameError && (
                <p style={{ color: "red" }}>{props.t(nameError)}</p>
              )}

              <br />

              <Label className={`label${classAlign}`}>
                {props.t("Country")}
              </Label>
              <Input
                className="textfieldValet"
                type="text"
                name="Country"
                onChange={e => setName(e.target.value)}
                value={name}
                placeholder={props.t("Country")}
              />
              {nameError && (
                <p style={{ color: "red" }}>{props.t(nameError)}</p>
              )}

              <br />

              <div className="inlineFieldsBtn">
                <button
                  className={`cancelbtn${classAlign}`}
                  type="button"
                  onClick={() => cancelHandler()}
                >
                  {props.t("Cancel")}
                </button>
                <button
                  className={`submitbtn${classAlign}`}
                  type="button"
                  onClick={() => updateHandler()}
                >
                  {props.t("Update")}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}
EditParkingLocation.propTypes = {
  t: PropTypes.any,
  state: PropTypes.object,
  location: PropTypes.func,
}

export default withTranslation()(EditParkingLocation)
