import { React, useEffect, useRef, useState } from "react"
import "../../../../App.css"
import PropTypes from "prop-types"
import GoogleMapAPI from './GoogleMapAPI'
import "bootstrap/dist/css/bootstrap.min.css"
import { Label, Input, Form } from "reactstrap"
import {
    BrowserRouter as Router,
    useHistory,
} from "react-router-dom"
import { withTranslation } from "react-i18next"
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Constant } from "pages/Resources/Constant"



const ViewParkingLocation = (props) => {
    const history = useHistory()
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [designation, setDesignation] = useState("")

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // bgcolor: 'black',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const cancelHandler = () => {
        history.push("/parking_location")
    }
    var classAlign = ""
    if (document.body.dir === "rtl") {
        classAlign = "rtlClass"
    } else {
        classAlign = "ltrClass"
    }
    return (
        <div className={`main-content${classAlign}`}>
            {(document.title = "Parent | AAC")}
            <div className="page-content">
                <div className="registerTeacher" style={{ marginLeft: "15px" }}>
                    <div id="formValet">
                        <h3>{props.t("View Parking Location")}</h3>
                        <br />
                        <Form>


                            <Label className={`label${classAlign}`}>{props.t(Constant.name)}</Label>
                            <Input
                                className="textfieldValet"
                                type="text"
                                name="name"
                                onChange={e => setName(e.target.value)}
                                value={name}
                                disabled={true}
                                placeholder={props.t(Constant.name)}
                            />


                            <br />
                            <Label className={`label${classAlign}`}>
                                {props.t(Constant.email)}
                            </Label>
                            <Input
                                className="textfieldValet"
                                type="email"
                                name="email"
                                disabled={true}
                                onChange={e => setEmail(e.target.value)}
                                value={email}
                                placeholder={props.t(Constant.email)}
                            />


                            <br />
                            <Label className={`label${classAlign}`}>
                                {props.t(Constant.phonenumber)}
                            </Label>
                            <Input
                                className="textfieldValet"
                                type="text"
                                name="Phone Number"
                                onChange={e => setDesignation(e.target.value)}
                                value={designation}
                                disabled={true}
                                placeholder={props.t(Constant.phonenumber)}
                            />

                            <br />

                            <Label className={`label${classAlign}`}>{props.t("Address")}</Label>
                            <Input
                                className="textfieldValet"
                                type="text"
                                name="Address"
                                onChange={e => setName(e.target.value)}
                                value={name}
                                disabled={true}
                                placeholder={props.t("Address")}
                            />
                            <br />


                            <Label className={`label${classAlign}`}>{props.t("Status")}</Label>
                            <Input
                                className="textfieldValet"
                                type="text"
                                name="Status"
                                onChange={e => setName(e.target.value)}
                                value={name}
                                disabled={true}
                                placeholder={props.t("Status")}
                            />
                            <br />

                            <Label className={`label${classAlign}`}>{props.t("Country")}</Label>
                            <Input
                                className="textfieldValet"
                                type="text"
                                name="Country"
                                onChange={e => setName(e.target.value)}
                                value={name}
                                disabled={true}
                                placeholder={props.t("Country")}
                            />
                            <br />

                            <div>
                                {/* <Button onClick={handleOpen}>Open modal</Button> */}
                                <div className="addButton" onClick={handleOpen}>
                                    <Button style={{ color: "white" }}>{props.t("view on map")}</Button>
                                    {/* <div>+</div> */}
                                </div>
                                <Modal
                                    aria-labelledby="transition-modal-title"
                                    aria-describedby="transition-modal-description"
                                    open={open}
                                    onClose={handleClose}
                                    closeAfterTransition
                                    slots={{ backdrop: Backdrop }}
                                    slotProps={{
                                        backdrop: {
                                            timeout: 500,
                                        },
                                    }}
                                >
                                    <Fade in={open}>
                                        <Box sx={style}>
                                            <Typography id="transition-modal-title" align='center' variant="h6" component="h2">
                                                Map
                                            </Typography>
                                            <Typography id="transition-modal-description" sx={{ mt: 4 }}>
                                                <GoogleMapAPI />
                                            </Typography>
                                        </Box>
                                    </Fade>
                                </Modal>
                            </div>





                            <br />


                            <div className="inlineFieldsBtn">
                                <button
                                    className={`cancelbtn${classAlign}`}
                                    type="button"
                                    onClick={() => cancelHandler()}
                                >
                                    {props.t("Cancel")}
                                </button>

                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}
ViewParkingLocation.propTypes = {
    t: PropTypes.any,
    state: PropTypes.object,
    location: PropTypes.func,
}

export default withTranslation()(ViewParkingLocation)
