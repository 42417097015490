import {
  DELETE_VALET_FAILURE,
  DELETE_VALET_REQUEST,
  DELETE_VALET_SUCCESS,
} from "./actionType"

export const deleteValetRequest = id => (
  console.log("viewid==>>>>>", id),
  {
    type: DELETE_VALET_REQUEST,
    payload: id,
  }
)

export const deleteValetSuccess = () => ({
  type: DELETE_VALET_SUCCESS,
})

export const deleteValetFailure = error => ({
  type: DELETE_VALET_FAILURE,
  payload: error,
})
