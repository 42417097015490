import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"

import { FETCH_VIEW_COMPANIES_REQUEST } from "./actionType"

import { fetchViewCompaniesFailure, fetchViewCompaniesSuccess } from "./actions"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchViewCompanies(action) {
  // const id = localStorage.getItem("_id")
  // console.log("valetId==>", id)
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/company/details`,
      // "http://13.234.30.42:5000/company/details",
      { companyObjId: action.payload },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchViewCompaniesSuccess(response.data))
  } catch (error) {
    yield put(fetchViewCompaniesFailure(error))
  }
}

function* fetchViewcompanySaga() {
  yield takeEvery(FETCH_VIEW_COMPANIES_REQUEST, fetchViewCompanies)
}

export default fetchViewcompanySaga
