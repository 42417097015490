import {
  DELETE_BOOKING_FAILURE,
  DELETE_BOOKING_REQUEST,
  DELETE_BOOKING_SUCCESS,
} from "./actionType"

export const deleteBookingRequest = id => (
  console.log("viewid==>>>>>", id),
  {
    type: DELETE_BOOKING_REQUEST,
    payload: id,
  }
)

export const deleteBookingSuccess = () => ({
  type: DELETE_BOOKING_SUCCESS,
})

export const deleteBookingFailure = error => ({
  type: DELETE_BOOKING_FAILURE,
  payload: error,
})
