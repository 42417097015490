import { React, useEffect } from "react"
import "../../../../App.css"
import PropTypes, { element, string } from "prop-types"
import "bootstrap/dist/css/bootstrap.min.css"
import { Label, Input, Form } from "reactstrap"
import {
  BrowserRouter as Router,
  useHistory,
  useParams,
} from "react-router-dom"
import { withTranslation } from "react-i18next"
import { Constant } from "pages/Resources/Constant"
import { useDispatch, useSelector } from "react-redux"
import { fetchViewUserRequest } from "store/actions"
import { Button } from "@material-ui/core"
import LazyLoad from "react-lazyload"

const ViewUser = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()
  console.log("valetId==>", id)

  const ViewUserData = useSelector(
    state => state.fetchViewuserReducer.Viewusers.data
  )
  console.log("ViewValetData=>", ViewUserData)

  useEffect(() => {
    dispatch(fetchViewUserRequest(id))
  }, [])
  const DateFormate = createdAt => {
    const date = new Date(createdAt)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    return `${day}/${month}/${year}`
    console.log(`${day}-${month}-${year} ${hours}:${minutes}:${seconds}`)
  }
  const cancelHandler = () => {
    history.push("/user_list")
  }
  const userTransactionHandler = () => {
    history.push(`/user_transaction/${id}`)
  }
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  return (
    <div className={`main-content${classAlign}`}>
      {(document.title = "Valet Driver | Nater")}
      <div className="page-content">
        <div className="registerValet" style={{ marginLeft: "15px" }}>
          <div id="formValet">
            <h5>
              <strong>{props.t(Constant.viewuser)}</strong>
            </h5>
            <br />
            <Form>
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.userid)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Phone"
                    value={ViewUserData?.userId}
                    disabled={true}
                    placeholder={props.t(Constant.userid)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.username)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="name"
                    value={ViewUserData?.name}
                    disabled={true}
                    placeholder={props.t(Constant.username)}
                  />
                </div>
              </div>
              <br />
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.phonenumber)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="number"
                    name="Phone"
                    value={ViewUserData?.phone}
                    disabled={true}
                    placeholder={props.t(Constant.phonenumber)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.totalcar)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="valetId"
                    value={ViewUserData?.totalCars}
                    disabled={true}
                    placeholder={props.t(Constant.totalcar)}
                  />
                </div>
              </div>
              <br />
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.totalbooking)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="valetId"
                    value={ViewUserData?.totalBooking}
                    disabled={true}
                    placeholder={props.t(Constant.totalbooking)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.registrationdate)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="valetId"
                    value={DateFormate(ViewUserData?.createdAt)}
                    disabled={true}
                    placeholder={props.t(Constant.registrationdate)}
                  />
                </div>
              </div>
              <br />
              {ViewUserData?.carList &&
                ViewUserData?.carList.map((row, i) => (
                  <div key={i}>
                    <div className="viewRowDiv">
                      <div className="viewDivGap">
                        <Label className={`label${classAlign}`}>
                          {props.t(`${i + 1}.Car Detail`)}
                        </Label>
                      </div>
                    </div>

                    <div className="viewRowDiv">
                      <div className="viewDivGap">
                        <Label className={`label${classAlign}`}>
                          {props.t(Constant.carname)}
                        </Label>
                        <Input
                          className="ViewtextfieldValet"
                          type="text"
                          name="valetId"
                          value={row.carName}
                          disabled={true}
                          placeholder={props.t(Constant.carname)}
                        />
                      </div>
                      <div>
                        <Label className={`label${classAlign}`}>
                          {props.t(Constant.carcolor)}
                        </Label>
                        <Input
                          className="ViewtextfieldValet"
                          type="text"
                          name="valetId"
                          value={row.carColor}
                          disabled={true}
                          placeholder={props.t(Constant.carcolor)}
                        />
                      </div>
                    </div>

                    <br />
                    <div className="viewRowDiv">
                      <div className="viewDivGap">
                        <Label className={`label${classAlign}`}>
                          {props.t(Constant.carnumber)}
                        </Label>
                        <Input
                          className="ViewtextfieldValet"
                          type="text"
                          name="valetId"
                          value={row.licensePlateNumber}
                          disabled={true}
                          placeholder={props.t(Constant.carnumber)}
                        />
                      </div>
                      <div>
                        <Label className={`label${classAlign}`}>
                          {props.t(Constant.carmake)}
                        </Label>
                        <Input
                          className="ViewtextfieldValet"
                          type="text"
                          name="valetId"
                          value={row.carMake}
                          disabled={true}
                          placeholder={props.t(Constant.carmake)}
                        />
                      </div>
                    </div>
                    <br />
                  </div>
                ))}

              {/* <Label className={`label${classAlign}`}>
                {props.t(Constant.totalbooking)}
              </Label>
              <Input
                className="textfieldValet"
                type="text"
                name="valetId"
                value={ViewUserData?.totalBooking}
                disabled={true}
                placeholder={props.t(Constant.totalbooking)}
              />
              <br /> */}
              {/* <div
                className="userTransactionButton"
                onClick={userTransactionHandler}
              >
                <Button className="MUI-addbutton">
                  {props.t(Constant.usertransaction)}
                </Button>
              </div> */}

              <div className="userTransactionButtondiv">
                <button
                  // className={`cancelbtn${classAlign}`}
                  className="userTransactionButton"
                  type="button"
                  onClick={() => userTransactionHandler()}
                >
                  {props.t(Constant.usertransaction)}
                </button>
              </div>
              <br />
              {ViewUserData?.carList[0]?.license ? (
                <div className="viewpicturediv">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.userlicence)}
                  </Label>

                  <div>
                    <LazyLoad height={200} offset={100}>
                      <img
                        src={ViewUserData?.carList[0]?.license}
                        alt=""
                        className="viewpicture"
                        // loading="lazy"
                      />
                    </LazyLoad>
                  </div>
                </div>
              ) : (
                ""
              )}
              <br />
              {/* <Label className={`label${classAlign}`}>
                {props.t(Constant.transactionsummery)}
              </Label>
              <Input
                className="textfieldValet"
                type="text"
                name="valetId"
                // value={DateFormate(ViewUserData?.createdAt)}
                disabled={true}
                placeholder={props.t(Constant.transactionsummery)}
              />
              <br /> */}

              <div className="inlineFieldsBtn">
                <button
                  className={`cancelbtn${classAlign}`}
                  type="button"
                  onClick={() => cancelHandler()}
                >
                  {props.t("Cancel")}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}
ViewUser.propTypes = {
  t: PropTypes.any,
  state: PropTypes.object,
  location: PropTypes.func,
}

export default withTranslation()(ViewUser)
