import {
  FETCH_USER_FAILURE,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
} from "./actionType"

export const fetchUserRequest = (
  n,
  search,
  sortOption,
  bookingOption,
  carOption
) => ({
  type: FETCH_USER_REQUEST,
  payload: {
    page: n,
    searchedText: search,
    sort: sortOption,
    booking: bookingOption,
    car: carOption,
  },
})

export const fetchUserSuccess = users => ({
  type: FETCH_USER_SUCCESS,
  payload: users,
})

export const fetchUserFailure = error => ({
  type: FETCH_USER_FAILURE,
  payload: error,
})
