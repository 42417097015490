import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { logoutUser } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import i18n from "i18n"

const Logout = props => {
  // const dispatch = useDispatch()

  // const [isLogout, setIsLogout] = useState(true)

  useEffect(() => {
    i18n.changeLanguage("en")
    props.history.push("/")
    localStorage.clear()
    sessionStorage.clear()
  }, [])

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Logout)
