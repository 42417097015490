import { React, useEffect } from "react"
import "../../../../App.css"
import PropTypes from "prop-types"
import "bootstrap/dist/css/bootstrap.min.css"
import { Label, Input, Form } from "reactstrap"
import {
  BrowserRouter as Router,
  useHistory,
  useParams,
} from "react-router-dom"
import { withTranslation } from "react-i18next"
import { Constant } from "pages/Resources/Constant"
import { useDispatch, useSelector } from "react-redux"
import { fetchViewBookingRequest } from "store/actions"

const ViewBookings = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()

  useEffect(() => {
    dispatch(fetchViewBookingRequest(id))
  }, [])

  const ViewBookingData = useSelector(
    state => state.fetchViewbookingReducer.Viewbookings.data
  )
  console.log("ViewCompanyData=>", ViewBookingData)

  const StartDateFormate = startdate => {
    const date = new Date(startdate)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    return `${day}/${month}/${year}`
    console.log(`${day}-${month}-${year} ${hours}:${minutes}:${seconds}`)
  }
  const StartTimeFormate = starttime => {
    const date = new Date(starttime)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    const formattedTime = date.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
    })
    return `${formattedTime}`
    // return `${hours}:${minutes}:${seconds}`
    // console.log(`${day}-${month}-${year} ${hours}:${minutes}:${seconds}`)
  }
  const EndDateFormate = startdate => {
    const date = new Date(startdate)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()

    return `${day}/${month}/${year}`
    // console.log(`${day}-${month}-${year} ${hours}:${minutes}:${seconds}`)
  }
  const EndTimeFormate = starttime => {
    const date = new Date(starttime)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    const formattedTime = date.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
    })
    return `${formattedTime}`
    // return `${hours}:${minutes}:${seconds}`
    console.log(`${day}-${month}-${year} ${hours}:${minutes}:${seconds}`)
  }

  const cancelHandler = () => {
    history.push("/bookings")
  }
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  return (
    <div className={`main-content${classAlign}`}>
      {(document.title = "Booking | Valet")}
      <div className="page-content">
        <div className="registerValet" style={{ marginLeft: "15px" }}>
          <div id="formValet">
            <h5>
              <strong>{props.t(Constant.viewbooking)}</strong>
            </h5>
            <br />
            <Form>
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t("Booking ID")}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Booking ID"
                    onChange={e => setName(e.target.value)}
                    value={ViewBookingData?.bookingId}
                    disabled={true}
                    placeholder={props.t("Booking ID")}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.username)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="name"
                    onChange={e => setName(e.target.value)}
                    value={ViewBookingData?.carOwnerObjId?.name}
                    disabled={true}
                    placeholder={props.t(Constant.username)}
                  />
                </div>
              </div>
              <br />
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.userphonenumber)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="name"
                    onChange={e => setName(e.target.value)}
                    value={ViewBookingData?.carOwnerObjId?.phone}
                    disabled={true}
                    placeholder={props.t(Constant.userphonenumber)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.companyname)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Status"
                    disabled={true}
                    onChange={e => setEmail(e.target.value)}
                    value={ViewBookingData?.companyObjId?.companyName}
                    placeholder={props.t(Constant.companyname)}
                  />
                </div>
              </div>
              <br />
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.comapnyemail)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Status"
                    disabled={true}
                    onChange={e => setEmail(e.target.value)}
                    value={ViewBookingData?.companyObjId?.email}
                    placeholder={props.t(Constant.comapnyemail)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.carname)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Type"
                    onChange={e => setDesignation(e.target.value)}
                    value={ViewBookingData?.carObjId?.carName}
                    disabled={true}
                    placeholder={props.t(Constant.carname)}
                  />
                </div>
              </div>
              <br />
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.carnumber)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Type"
                    onChange={e => setDesignation(e.target.value)}
                    value={ViewBookingData?.carObjId?.licensePlateNumber}
                    disabled={true}
                    placeholder={props.t(Constant.carnumber)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.pickupby)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Type"
                    onChange={e => setDesignation(e.target.value)}
                    value={
                      ViewBookingData?.pickUpValetDriverObjId?.valetDriverName
                    }
                    disabled={true}
                    placeholder={props.t(Constant.pickupby)}
                  />
                </div>
              </div>
              <br />
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.retrieveby)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Type"
                    onChange={e => setDesignation(e.target.value)}
                    value={
                      ViewBookingData?.dropOffValetDriverObjId?.valetDriverName
                    }
                    disabled={true}
                    placeholder={props.t(Constant.retrieveby)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.bookingstartdate)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Type"
                    onChange={e => setDesignation(e.target.value)}
                    value={
                      ViewBookingData?.bookingStartTime
                        ? StartDateFormate(ViewBookingData?.bookingStartTime)
                        : ""
                    }
                    disabled={true}
                    placeholder={props.t(Constant.bookingstartdate)}
                  />
                </div>
              </div>
              <br />
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.bookingstarttime)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Car Name"
                    onChange={e => setName(e.target.value)}
                    value={
                      ViewBookingData?.bookingStartTime
                        ? StartTimeFormate(ViewBookingData?.bookingStartTime)
                        : ""
                    }
                    disabled={true}
                    placeholder={props.t(Constant.bookingstarttime)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.bookingenddate)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Type"
                    onChange={e => setDesignation(e.target.value)}
                    value={
                      ViewBookingData?.reachedDestinationTime
                        ? EndDateFormate(
                            ViewBookingData?.reachedDestinationTime
                          )
                        : ""
                    }
                    disabled={true}
                    placeholder={props.t(Constant.bookingenddate)}
                  />
                </div>
              </div>
              <br />
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.bookingendtime)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Car Name"
                    onChange={e => setName(e.target.value)}
                    value={
                      ViewBookingData?.reachedDestinationTime
                        ? EndTimeFormate(
                            ViewBookingData?.reachedDestinationTime
                          )
                        : ""
                    }
                    disabled={true}
                    placeholder={props.t(Constant.bookingendtime)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.durationminutes)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Satrt Date"
                    onChange={e => setName(e.target.value)}
                    value={ViewBookingData?.parkingDuration}
                    disabled={true}
                    placeholder={props.t(Constant.durationminutes)}
                  />
                </div>
              </div>
              {/* <br />
              <Label className={`label${classAlign}`}>
                {props.t(Constant.adminamount)}
              </Label>
              <Input
                className="textfieldValet"
                type="text"
                name="End Date"
                onChange={e => setName(e.target.value)}
                // value={ViewBookingData?.bookingFees}
                disabled={true}
                placeholder={props.t(Constant.adminamount)}
              /> */}
              <br />
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.tip)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="End Date"
                    onChange={e => setName(e.target.value)}
                    value={ViewBookingData?.tip}
                    disabled={true}
                    placeholder={props.t(Constant.tip)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.amountchargedQAR)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="End Date"
                    onChange={e => setName(e.target.value)}
                    value={ViewBookingData?.totalAmount}
                    disabled={true}
                    placeholder={props.t(Constant.amountchargedQAR)}
                  />
                </div>
              </div>
              <br />
              <Label className={`label${classAlign}`}>
                {props.t(Constant.vatvalueaddedtax)}
              </Label>
              <Input
                className="textfieldValet"
                type="text"
                name="Status"
                disabled={true}
                value={ViewBookingData?.VAT}
                placeholder={props.t(Constant.vatvalueaddedtax)}
              />
              <br />
              <Label className={`label${classAlign}`}>
                {props.t(Constant.companyaddress)}
              </Label>
              <Input
                className="textfieldValet"
                type="text"
                name="Status"
                disabled={true}
                onChange={e => setEmail(e.target.value)}
                value={ViewBookingData?.companyObjId?.address}
                placeholder={props.t(Constant.companyaddress)}
              />
              <br />
              <div className="inlineFieldsBtn">
                <button
                  className={`cancelbtn${classAlign}`}
                  type="button"
                  onClick={() => cancelHandler()}
                >
                  {props.t("Cancel")}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}
ViewBookings.propTypes = {
  t: PropTypes.any,
  state: PropTypes.object,
  location: PropTypes.func,
}

export default withTranslation()(ViewBookings)
