import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { FaUserGraduate, FaHome, FaChalkboardTeacher } from "react-icons/fa"
import { MdFamilyRestroom, MdTextsms } from "react-icons/md"
import { AiTwotoneAlert } from "react-icons/ai"
import { BsCarFrontFill } from "react-icons/bs"
import { SiCashapp } from "react-icons/si" // payment
import { MdPayment } from "react-icons/md" // payment
import { MdMan2 } from "react-icons/md"
import { MdOutlineMan3 } from "react-icons/md"
import { SlCalender } from "react-icons/sl" // booking
import { FaParking } from "react-icons/fa" // booking
import { GrUserWorker } from "react-icons/gr"
import { MdPregnantWoman } from "react-icons/md"
import { ImManWoman } from "react-icons/im"
import { FaRegUser } from "react-icons/fa" // user
import { BiUser } from "react-icons/bi" // user
import { MdDashboard } from "react-icons/md" // dashboard

import { TbReportMoney } from "react-icons/tb"
import { BsFillBuildingsFill } from "react-icons/bs" //company
import { HiBuildingOffice2 } from "react-icons/hi2" //company
import { AiFillCar } from "react-icons/ai" //valet
import { Constant } from "pages/Resources/Constant"
// import ValetIcon from "../../pages/Components/Assets/Images/bellboy 1.png"
// import ValetIcon from "../../pages/Components/Assets/Images/bellboy 2.png"
// import ValetIcon from "../../pages/Components/Assets/Images/bellboy 3.png"
import { ValetIconsvg } from "store/Valet/SvgIcon/SvgIcon"

import "../../App.css"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { Icon } from "@iconify/react"
// import { color } from "@mui/system"

const SidebarContent = props => {
  const ref = useRef()

  const [color, setColor] = useState({
    a: {},
    b: null,
    c: null,
    d: null,
  })
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously

  const loc = window.location.hash
  const selectedTab = loc.slice(0, -5)
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }

  let role = sessionStorage.getItem("role")
  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu" style={{ position: "fixed", top: "70px" }}>
          <ul
            className={`metismenu list-unstyled ${classAlign}`}
            id="side-menu"
          >
            {/* <li className="menu-title">{props.t("Menu")} </li> */}
            {/* <li
              className={
                props.location.pathname === "/dashboard" ? "activeTab" : ""
              }
            >
              <Link to="/dashboard" className="sidebarListItem">
                <FaHome className={`sidebarIcon${classAlign}`} />
                <span className="sidebarListText">
                  {props.t(Constant.dashboard)}
                </span>
              </Link>
            </li> */}

            {role === "admin" ? (
              <li
                className={
                  props.location.pathname === "/dashboard" ? "activeTab" : ""
                }
              >
                <Link to="/dashboard" className="sidebarListItem">
                  <MdDashboard className={`sidebarIcon${classAlign}`} />
                  <span className="sidebarListText">
                    {props.t(Constant.dashboard)}
                  </span>
                </Link>
              </li>
            ) : (
              <li
                className={
                  props.location.pathname === "/dashboardvalet"
                    ? "activeTab"
                    : ""
                }
              >
                <Link to="/dashboardvalet" className="sidebarListItem">
                  <MdDashboard className={`sidebarIcon${classAlign}`} />
                  <span className="sidebarListText">
                    {props.t(Constant.dashboard)}
                  </span>
                </Link>
              </li>
            )}

            {role === "admin" ? (
              <li
                className={
                  props.location.pathname === "/company" ||
                  props.location.pathname === "/add_company" ||
                  props.location.pathname.split("/")[1] === "view_company" ||
                  props.location.pathname.split("/")[1] === "edit_company" ||
                  props.location.pathname.split("/")[1] ===
                    "company_transaction"
                    ? "activeTab"
                    : ""
                }
              >
                <Link to="/company" className="sidebarListItem">
                  <HiBuildingOffice2 className={`sidebarIcon${classAlign}`} />
                  <span className="sidebarListText">
                    {props.t(Constant.company)}
                  </span>
                </Link>
              </li>
            ) : (
              <li
                className={
                  props.location.pathname === "/valet_drivers" ||
                  props.location.pathname === "/add_valetdrivers" ||
                  props.location.pathname.split("/")[1] ===
                    "view_valetdrivers" ||
                  props.location.pathname.split("/")[1] === "edit_valetdrivers"
                    ? "activeTab"
                    : ""
                }
              >
                <Link to="/valet_drivers" className="sidebarListItem">
                  <AiFillCar className={`sidebarIcon${classAlign}`} />
                  {/* <ValetIconsvg
                    color={
                      props.location.pathname === "/valet_drivers" ||
                      props.location.pathname === "/add_valetdrivers" ||
                      props.location.pathname.split("/")[1] ===
                        "view_valetdrivers" ||
                      props.location.pathname.split("/")[1] ===
                        "edit_valetdrivers"
                        ? "#0a6ac8"
                        : "#a6b0cf"
                    }
                    className={`sidebarIcon${classAlign}`}
                  /> */}
                  {/* <img
                    className={`sidebarIcon${classAlign}`}
                    src={ValetIcon}
                    style={{ height: "20px", width: "20px" }}
                  /> */}
                  <span
                    className="sidebarListText"
                    // style={
                    //   document.body.dir === "ltr"
                    //     ? { marginLeft: "15px" }
                    //     : { marginRight: "15px" }
                    // }
                  >
                    {props.t(Constant.valetdriver)}
                  </span>
                </Link>
              </li>
            )}

            {role === "admin" ? (
              <li
                className={
                  props.location.pathname === "/bookings" ||
                  props.location.pathname.split("/")[1] === "view_bookings" ||
                  selectedTab === "#/view_teacher" ||
                  selectedTab === "#/edit_teacher"
                    ? "activeTab"
                    : ""
                }
              >
                <Link to="/bookings" className="sidebarListItem">
                  <FaParking className={`sidebarIcon${classAlign}`} />
                  <span className="sidebarListText">
                    {props.t(Constant.booking)}
                  </span>
                </Link>
              </li>
            ) : (
              <li
                className={
                  props.location.pathname === "/bookingvalet" ||
                  props.location.pathname.split("/")[1] ===
                    "view_bookingvalet" ||
                  selectedTab === "#/view_teacher" ||
                  selectedTab === "#/edit_teacher"
                    ? "activeTab"
                    : ""
                }
              >
                <Link to="/bookingvalet" className="sidebarListItem">
                  <FaParking className={`sidebarIcon${classAlign}`} />
                  <span className="sidebarListText">
                    {props.t(Constant.booking)}
                  </span>
                </Link>
              </li>
            )}
            {/* payment */}
            {role === "admin" ? (
              <li
                className={
                  props.location.pathname === "/Payments" ||
                  props.location.pathname.split("/")[1] === "view_payment" ||
                  selectedTab === "#/view_student" ||
                  selectedTab === "#/edit_student"
                    ? "activeTab"
                    : ""
                }
              >
                <Link to="/Payments" className="sidebarListItem">
                  <MdPayment className={`sidebarIcon${classAlign}`} />
                  <span className="sidebarListText">
                    {props.t(Constant.payment)}
                  </span>
                </Link>
              </li>
            ) : (
              <li
                className={
                  props.location.pathname === "/paymentvalet" ||
                  props.location.pathname.split("/")[1] ===
                    "view_paymentvalet" ||
                  selectedTab === "#/view_student" ||
                  selectedTab === "#/edit_student"
                    ? "activeTab"
                    : ""
                }
              >
                <Link to="/paymentvalet" className="sidebarListItem">
                  <MdPayment className={`sidebarIcon${classAlign}`} />
                  <span className="sidebarListText">
                    {props.t(Constant.payment)}
                  </span>
                </Link>
              </li>
            )}

            {role === "admin" ? (
              <li
                className={
                  props.location.pathname === "/user_list" ||
                  props.location.pathname.split("/")[1] === "view_user" ||
                  props.location.pathname.split("/")[1] === "user_transaction"
                    ? "activeTab"
                    : ""
                }
              >
                <Link to="/user_list" className="sidebarListItem">
                  <BiUser className={`sidebarIcon${classAlign}`} />
                  <span className="sidebarListText">{props.t("User")}</span>
                </Link>
              </li>
            ) : (
              ""
            )}
            {role === "admin" ? (
              ""
            ) : (
              <li
                className={
                  props.location.pathname === "/transaction" ? "activeTab" : ""
                }
              >
                <Link to="/transaction" className="sidebarListItem">
                  <TbReportMoney className={`sidebarIcon${classAlign}`} />
                  <span className="sidebarListText">
                    {props.t("Transaction")}
                  </span>
                </Link>
              </li>
            )}

            {/* <li
              className={
                props.location.pathname === "/parking_location" ? "activeTab" : ""
              }
            >
              <Link to="/parking_location" className="sidebarListItem">
                <BsCarFrontFill className={`sidebarIcon${classAlign}`} />
                <span className="sidebarListText">{props.t("Parking Locations")}</span>
              </Link>
            </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
