import React, { useRef, useEffect, useState } from "react"
import { Map, GoogleApiWrapper } from "google-maps-react"
import PropTypes from "prop-types"

const SearchInput = (
  { google, onPlaceChanged, value, defaultValue, placeholder },
  props
) => {
  const inputRef = useRef(null)
  const [address, setAddress] = useState("")

  let autoComplete = new window.google.maps.places.Autocomplete(
    inputRef.current,
    {
      // limit to North America for now
      // componentRestrictions: { country: ["us", "ca"] },
      // fields: ["address_component", "geometry"],
      // types: ["address"],
    }
  )
  autoComplete.addListener("place_changed", fillInAddress)

  function fillInAddress() {
    // Get the place details from the autocomplete object.
    const place = autoComplete.getPlace()
    console.log("fillInAddress", place, autoComplete.getPlace)
    if (place) {
      console.log(place)
      onPlaceChanged(place)
    }
  }
  //
  useEffect(() => {
    setAddress(defaultValue)
  }, [defaultValue])

  return (
    <div>
      <input
        className="form-control"
        style={{ backgroundColor: "#f1f1f9" }}
        type="text"
        id="myInput"
        ref={inputRef}
        // value={value ? value : address}
        defaultValue={address}
        // onChange={e => setAddress(e.target.value)}
        name="address"
        placeholder={placeholder}
      />
    </div>
  )
}
SearchInput.propTypes = {
  t: PropTypes.any,
  google: PropTypes.func,
  onPlaceChanged: PropTypes.func,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.any,
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBYuOTL5QWLq9-gs7_4hy7ijNwYmr_Dn88",
})(SearchInput)
