import React from "react"
import { ErrorMessage, useField } from "formik"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"

const Textfield = ({ label, ...props }) => {
  const [field, meta] = useField(props)

  return (
    <div className="mb-2 textfield" style={{ textAlign: "left" }}>
      <label htmlFor={field.name}>{label}</label>
      <input
        style={{ fontSize: "small" }}
        className={`form-control shadow-none ${
          meta.touched && meta.error && "is-invalid"
        }`}
        {...field}
        {...props}
        autoComplete="off"
      />
      <ErrorMessage component="div" name={field.name} className="error" />
    </div>
  )
}
Textfield.propTypes = {
  label: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(Textfield)
