import {
  FETCH_VIEW_USER_FAILURE,
  FETCH_VIEW_USER_REQUEST,
  FETCH_VIEW_USER_SUCCESS,
} from "./actionType"

export const fetchViewUserRequest = id => (
  console.log("viewid==>", id),
  {
    type: FETCH_VIEW_USER_REQUEST,
    payload: id,
  }
)

export const fetchViewUserSuccess = Viewusers => ({
  type: FETCH_VIEW_USER_SUCCESS,
  payload: Viewusers,
})

export const fetchViewUserFailure = error => ({
  type: FETCH_VIEW_USER_FAILURE,
  payload: error,
})
