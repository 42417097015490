import { call, put, takeLatest } from "redux-saga/effects"
import axios from "axios"
import { toast } from "react-toastify"
import { DELETE_VALET_REQUEST } from "./actionType"
import { deleteValetFailure, deleteValetSuccess } from "./actions"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* deleteValet(action) {
  console.log("idAction>>>", action.payload)

  try {
    yield call(
      axios.post,
      `${BASE_URL}/valet/delete`,
      // "http://13.234.30.42:5000/valet/delete",
      { valetDriverObjId: action.payload },

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(deleteValetSuccess())
    // toast.success("Valet Driver deleted successfully")
  } catch (error) {
    yield put(deleteValetFailure(error.message))
    toast.error("Failed to delete the Valet Driver")
  }
}

export function* DeleteValetsaga() {
  yield takeLatest(DELETE_VALET_REQUEST, deleteValet)
}
