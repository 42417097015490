import {
  FETCH_VIEW_COMPANIES_SUCCESS,
  FETCH_VIEW_COMPANIES_FAILURE,
  FETCH_VIEW_COMPANIES_REQUEST,
} from "./actionType"

export const fetchViewCompaniesRequest = id => (
  console.log("viewid==>", id),
  {
    type: FETCH_VIEW_COMPANIES_REQUEST,
    payload: id,
  }
)

export const fetchViewCompaniesSuccess = Viewcompanies => ({
  type: FETCH_VIEW_COMPANIES_SUCCESS,
  payload: Viewcompanies,
})

export const fetchViewCompaniesFailure = error => ({
  type: FETCH_VIEW_COMPANIES_FAILURE,
  payload: error,
})
