// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Maps
import MapsVector from "../pages/Maps/MapsVector"
import MapsLeaflet from "../pages/Maps/MapsLeaflet"

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons"
import IconDripicons from "../pages/Icons/IconDripicons"
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign"
import IconFontawesome from "../pages/Icons/IconFontawesome"

//components
import Customers from "pages/Components/Pages/Customers/Customers"
import AddCustomer from "pages/Components/Pages/Customers/AddCustomer"
import EditCustomer from "pages/Components/Pages/Customers/EditCustomer"
import ViewCustomer from "pages/Components/Pages/Customers/ViewCustomer"
import CompanyTransaction from "pages/Components/Pages/Customers/CompanyTransaction"
import Bookings from "pages/Components/Pages/Bookings/Bookings"
import AddBookings from "pages/Components/Pages/Bookings/AddBookings"
import EditBookings from "pages/Components/Pages/Bookings/EditBookings"
import ViewBookings from "pages/Components/Pages/Bookings/ViewBookings"
import Payments from "pages/Components/Pages/Payments/Payments"
import ValetDrivers from "pages/Components/Pages/ValetDrivers/ValetDrivers"
import AddValetDrivers from "pages/Components/Pages/ValetDrivers/AddValetDrivers"
import EditValetDrivers from "pages/Components/Pages/ValetDrivers/EditValetDrivers"
import ViewValetDrivers from "pages/Components/Pages/ValetDrivers/ViewValetDrivers"
import ParkingLocation from "pages/Components/Pages/ParkingLocation/ParkingLocation"
import AddParkingLocation from "pages/Components/Pages/ParkingLocation/AddParkingLocation"
import EditParkingLocation from "pages/Components/Pages/ParkingLocation/EditParkingLocation"
import ViewParkingLocation from "pages/Components/Pages/ParkingLocation/ViewParkingLocation"
import GoogleMapAPI from "pages/Components/Pages/ParkingLocation/GoogleMapAPI"
import ValetManagement from "pages/Components/Pages/ValetManagement/ValetManagement"
import UserList from "pages/Components/Pages/Users/UserList"
import ViewUser from "pages/Components/Pages/Users/ViewUser"
import BookingsValet from "pages/Components/Pages/BookingsValet/BookingsValet"
import ViewBookingValet from "pages/Components/Pages/BookingsValet/ViewBookingValet"
import PaymentValet from "pages/Components/Pages/PaymentValet/PaymentValet"
import ViewPayment from "pages/Components/Pages/Payments/ViewPayment"
import ViewPaymentValet from "pages/Components/Pages/PaymentValet/ViewPaymentValet"
import UserTransaction from "pages/Components/Pages/Users/UserTransaction"
import ValetTransaction from "pages/Components/Pages/TransactionValet/ValetTransaction"
import ForgotPasswordAfter from "pages/Components/Pages/ForgotPassword/ForgotPasswordAfter"

import ResetPassword from "pages/Components/Pages/ForgotPassword/ResetPassword"
import DashboardValet from "pages/DashboardValet/DashboardValet"

var authProtectedRoutes = [
  /* after login */
  { path: "/dashboard", component: Dashboard },
  // { path: "/dashboardnew", component: Dashboard },

  { path: "/company", component: Customers },
  { path: "/add_company", component: AddCustomer },
  // { path: "/edit_customer", component: EditCustomer },
  { path: "/edit_company/:id", component: EditCustomer },
  // { path: "/view_customer", component: ViewCustomer },
  { path: "/view_company/:id", component: ViewCustomer },
  { path: "/company_transaction/:id", component: CompanyTransaction },
  { path: "/bookings", component: Bookings },
  { path: "/add_bookings", component: AddBookings },
  { path: "/edit_bookings", component: EditBookings },
  { path: "/view_bookings/:id", component: ViewBookings },
  { path: "/payments", component: Payments },
  { path: "/valet_drivers", component: ValetDrivers },
  { path: "/add_valetdrivers", component: AddValetDrivers },
  { path: "/edit_valetdrivers", component: EditValetDrivers },
  { path: "/view_valetdrivers", component: ViewValetDrivers },
  { path: "/view_valetdrivers", component: ViewValetDrivers },
  { path: "/parking_location", component: ParkingLocation },
  { path: "/add_parkinglocation", component: AddParkingLocation },
  { path: "/edit_parkinglocation", component: EditParkingLocation },
  { path: "/view_parkinglocation", component: ViewParkingLocation },
  { path: "/google_map_api", component: GoogleMapAPI },
  { path: "/user_list", component: UserList },
  // { path: "/view_user", component: ViewUser },
  { path: "/view_user/:id", component: ViewUser },
  { path: "/user_transaction/:id", component: UserTransaction },
  { path: "/view_payment/:id", component: ViewPayment },

  // { path: "/forgot-password", component: ForgotPassword },

  // //profile
  { path: "/profile", component: UserProfile },

  // Icons
  { path: "/icons-boxicons", component: IconBoxicons },
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: "/icons-fontawesome", component: IconFontawesome },

  // Maps
  { path: "/maps-vector", component: MapsVector },
  { path: "/maps-leaflet", component: MapsLeaflet },
]

var companyProtectedRoutes = [
  /* after login */
  // { path: "/dashboard", component: Dashboard },
  { path: "/dashboardvalet", component: DashboardValet },
  // { path: "/dashboardnew", component: Dashboard },
  { path: "/valetmanagement", component: ValetManagement },
  // { path: "/customers", component: Customers },
  { path: "/add_customer", component: AddCustomer },
  { path: "/edit_customer", component: EditCustomer },
  { path: "/view_customer", component: ViewCustomer },
  { path: "/bookings", component: Bookings },
  { path: "/add_bookings", component: AddBookings },
  { path: "/edit_bookings", component: EditBookings },
  { path: "/view_bookings", component: ViewBookings },
  { path: "/payments", component: Payments },
  { path: "/valet_drivers", component: ValetDrivers },
  { path: "/add_valetdrivers", component: AddValetDrivers },
  // { path: "/edit_valetdrivers", component: EditValetDrivers },
  { path: "/edit_valetdrivers/:id", component: EditValetDrivers },
  // { path: "/view_valetdrivers", component: ViewValetDrivers },
  { path: "/view_valetdrivers/:id", component: ViewValetDrivers },
  { path: "/parking_location", component: ParkingLocation },
  { path: "/add_parkinglocation", component: AddParkingLocation },
  { path: "/edit_parkinglocation", component: EditParkingLocation },
  { path: "/view_parkinglocation", component: ViewParkingLocation },
  { path: "/google_map_api", component: GoogleMapAPI },
  { path: "/bookingvalet", component: BookingsValet },
  { path: "/view_bookingvalet/:id", component: ViewBookingValet },
  { path: "/paymentvalet", component: PaymentValet },
  { path: "/transaction", component: ValetTransaction },
  { path: "/view_paymentvalet/:id", component: ViewPaymentValet },

  // //profile
  { path: "/profile", component: UserProfile },

  // Icons
  { path: "/icons-boxicons", component: IconBoxicons },
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: "/icons-fontawesome", component: IconFontawesome },

  // Maps
  // { path: "/maps-google", component: MapsGoogle },
  { path: "/maps-vector", component: MapsVector },
  { path: "/maps-leaflet", component: MapsLeaflet },
]

const publicRoutes = [
  { path: "/", component: Login },
  // { path: "/admin", component: Login },
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  // { path: "/register", component: Signup },
  { path: "/reset_password/:token", component: ResetPassword },
  // { path: "/forgot_password", component: ForgotPassword },
  { path: "/forgot_password_after", component: ForgotPasswordAfter },
]

export { authProtectedRoutes, publicRoutes, companyProtectedRoutes }
