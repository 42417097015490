import React from "react"
import { useState } from "react"
import GoogleMapAPI from "./GoogleMapAPI"
import { useEffect } from "react"
import "../../../../App.css"
import PropTypes from "prop-types"
import "bootstrap/dist/css/bootstrap.min.css"
import { Label, Input, Form } from "reactstrap"
import {
  BrowserRouter as Router,
  useHistory,
  useParams,
} from "react-router-dom"
import { withTranslation } from "react-i18next"
import Backdrop from "@mui/material/Backdrop"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Fade from "@mui/material/Fade"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"

const AddParkingLocation = props => {
  const history = useHistory()
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [designation, setDesignation] = useState("")
  const [address, setAddress] = useState("")
  const [latLng, setLatLng] = useState({})
  const [nameError, setNameError] = useState("")
  const [emailError, setEmailError] = useState("")
  const [designationError, setDesignationError] = useState("")

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // bgcolor: 'black',
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  }

  const mapData = (position, address) => {
    console.log("mapData==>", position, address)
    setLatLng(position)
    setAddress(address)
  }
  console.log("googleData==>", latLng, address)

  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  useEffect(() => {
    document.title = "Parking | Valet"
  }, [])
  return (
    <div className={`main-content${classAlign}`}>
      <div className="page-content">
        <div className="registerValet" style={{ marginLeft: "15px" }}>
          <div id="formValet">
            <h5>{props.t("Add Parking Location")}</h5>
            <br />
            <Form>
              <Label className={`label${classAlign}`}>{props.t("Name")}</Label>
              <Input
                className="textfieldValet"
                type="text"
                name=""
                onChange={e => setName(e.target.value)}
                value={name}
                placeholder={props.t("Name")}
              />
              {nameError && (
                <p style={{ color: "red" }}>{props.t(nameError)}</p>
              )}

              <br />
              <Label className={`label${classAlign}`}>{props.t("Email")}</Label>
              <Input
                className="textfieldValet"
                type="text"
                name="email"
                onChange={e => setEmail(e.target.value)}
                value={email}
                placeholder={props.t("Email")}
              />
              {emailError && (
                <p style={{ color: "red" }}>{props.t(emailError)}</p>
              )}

              <br />
              <Label className={`label${classAlign}`}>
                {props.t("Phone Number")}
              </Label>
              <Input
                className="textfieldValet"
                type="text"
                name="Phone Number"
                onChange={e => setDesignation(e.target.value)}
                value={designation}
                placeholder={props.t("Phone Number")}
              />
              {designationError && (
                <p style={{ color: "red" }}>{props.t(designationError)}</p>
              )}
              <br />

              <div>
                {/* <Button onClick={handleOpen}>Open modal</Button> */}
                <div className="addButton" onClick={handleOpen}>
                  <Button style={{ color: "white" }}>{props.t("MAP")}</Button>
                  {/* <div>+</div> */}
                </div>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open}
                  onClose={handleClose}
                  closeAfterTransition
                  slots={{ backdrop: Backdrop }}
                  slotProps={{
                    backdrop: {
                      timeout: 500,
                    },
                  }}
                >
                  <Fade in={open}>
                    <Box sx={style}>
                      <Typography
                        id="transition-modal-title"
                        align="center"
                        variant="h6"
                        component="h2"
                      >
                        Map
                      </Typography>
                      <Typography
                        id="transition-modal-description"
                        sx={{ mt: 4 }}
                      >
                        <GoogleMapAPI googleData={mapData} />
                      </Typography>
                    </Box>
                  </Fade>
                </Modal>
              </div>

              <br />

              <Label className={`label${classAlign}`}>
                {props.t("Address")}
              </Label>
              <Input
                className="textfieldValet"
                type="text"
                name=""
                // onChange={e => setName(e.target.value)}
                value={address}
                placeholder={props.t("Address")}
              />
              {nameError && (
                <p style={{ color: "red" }}>{props.t(nameError)}</p>
              )}

              <br />

              <Label className={`label${classAlign}`}>
                {props.t("Latitude")}
              </Label>
              <Input
                className="textfieldValet"
                type="text"
                name=""
                value={latLng.lat}
                placeholder={props.t("Latitude")}
              />
              {nameError && (
                <p style={{ color: "red" }}>{props.t(nameError)}</p>
              )}

              <br />

              <Label className={`label${classAlign}`}>
                {props.t("Longitude")}
              </Label>
              <Input
                className="textfieldValet"
                type="text"
                name=""
                // onChange={e => setName(e.target.value)}
                value={latLng.lng}
                placeholder={props.t("Longitude")}
              />
              {nameError && (
                <p style={{ color: "red" }}>{props.t(nameError)}</p>
              )}

              <br />
              <Label className={`label${classAlign}`}>
                {props.t("Status")}
              </Label>
              <Input
                className="textfieldValet"
                type="text"
                name="Status"
                onChange={e => setName(e.target.value)}
                value={name}
                placeholder={props.t("Status")}
              />
              {nameError && (
                <p style={{ color: "red" }}>{props.t(nameError)}</p>
              )}

              <br />

              <Label className={`label${classAlign}`}>
                {props.t("Country")}
              </Label>
              <Input
                className="textfieldValet"
                type="text"
                name=""
                onChange={e => setName(e.target.value)}
                value={name}
                placeholder={props.t("Country")}
              />
              {nameError && (
                <p style={{ color: "red" }}>{props.t(nameError)}</p>
              )}

              <br />

              <div className="inlineFieldsBtn">
                <button
                  className={`cancelbtn${classAlign}`}
                  type="button"
                  onClick={() => cancelHandler()}
                >
                  {props.t("Cancel")}
                </button>
                <button
                  className={`submitbtn${classAlign}`}
                  type="button"
                  onClick={() => updateHandler()}
                >
                  {props.t("Update")}
                </button>
              </div>
            </Form>
          </div>
        </div>

        <br />
        {/* <GoogleMapAPI /> */}
      </div>
    </div>
  )
}
AddParkingLocation.propTypes = {
  t: PropTypes.any,
  state: PropTypes.object,
  location: PropTypes.func,
}

export default withTranslation()(AddParkingLocation)
