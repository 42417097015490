import {
  DELETE_BOOKING_FAILURE,
  DELETE_BOOKING_REQUEST,
  DELETE_BOOKING_SUCCESS,
} from "./actionType"

const initialState = {
  loading: false,
  error: null,
}

const deletebookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_BOOKING_REQUEST:
      return { ...state, loading: true, error: null }
    case DELETE_BOOKING_SUCCESS:
      return { ...state, loading: false, error: null }
    case DELETE_BOOKING_FAILURE:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export default deletebookingReducer
