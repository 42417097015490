import { React, useState } from "react"
import "react-toastify/dist/ReactToastify.css"
import "../../../DashboardValet/DashboardValet.style.css"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Constant } from "pages/Resources/Constant"
import Searchbar from "../Searchbar/Searchbar"
import Pagination from "react-responsive-pagination"
import { IoMdArrowRoundBack } from "react-icons/io"
import { fetchCompanyTransactionRequest } from "store/actions"
import { useParams } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Label } from "reactstrap"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import Swal from "sweetalert2"
import { fetchAmountTransferRequest } from "store/actions"
import { fetchViewCompaniesRequest } from "store/actions"
//modal
import Backdrop from "@mui/material/Backdrop"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Fade from "@mui/material/Fade"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
const CompanyTransaction = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()
  const [currentPage, setCurrentPage] = useState(1)

  const [searchData, setSearchData] = useState("")
  const [amount, setAmount] = useState(0)
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    // bgcolor: "background.paper",
    bgcolor: "#e9ecef",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  }
  const handleSearchData = searchtext => {
    console.log("searchbarValue==>", searchtext)
    setSearchData(searchtext)
    setCurrentPage(1)
  }

  const HandleBackButton = () => {
    history.push(`/view_company/${id}`)
  }
  const ViewCompanyData = useSelector(
    state => state.fetchViewcompanyReducer?.Viewcompanies?.data
  )
  console.log("ViewCompanyData", ViewCompanyData)
  const CompanyTransactionData = useSelector(
    state => state.fetchCompanytransactionReducer.Companytransactions?.data
  )
  console.log("CompanyTransactionData=>", CompanyTransactionData)
  useEffect(() => {
    dispatch(fetchViewCompaniesRequest(id))
  }, [])
  useEffect(() => {
    dispatch(fetchCompanyTransactionRequest(currentPage, searchData, id))
  }, [currentPage, searchData, id])

  const cardHeader = [
    {
      headers: props.t(Constant.totalamount),
      value: ViewCompanyData?.payments,
    },
    {
      headers: props.t(Constant.paidamount),
      value: ViewCompanyData?.paidPayment,
    },
    {
      headers: props.t(Constant.pendingamount),
      value: ViewCompanyData?.pendingPayment,
    },
  ]

  const initialValues = {
    amount: "",
  }

  const validationSchema = Yup.object({
    amount: Yup.string()
      .required("Amount is required")
      .matches(/^\d+$/, "Amount must contain only numbers"),
  })
  const handleSubmit = values => {
    setAmount(values.amount)
    handleClose()
    handleDelete(values.amount)
  }
  console.log("amount", amount)
  const handleDelete = amounttransfer => {
    Swal.fire({
      text: props.t("Are you sure you want to transfer the amount?"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
      confirmButtonText: props.t("OK"),
      cancelButtonText: props.t("Cancel"),
      showConfirmButton: true,
      showCancelButton: true,
    }).then(willDelete => {
      if (willDelete.isConfirmed) {
        AmountSubmit(amounttransfer)
      } else {
      }
    })
  }
  const amountSuccessCallBack = response => {
    console.log("response44", response)
    dispatch(fetchViewCompaniesRequest(id))
    toast.success(props.t("Amount transfer successfully"))
  }
  const AmountSubmit = amounttransfer => {
    dispatch(
      fetchAmountTransferRequest(amounttransfer, id, amountSuccessCallBack)
    )
    console.log("amount2", amount)
    console.log("amounttransfer", amounttransfer)
  }

  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  return (
    <div className={`main-content${classAlign}`}>
      {(document.title = "Company | Nater")}
      <div className="page-content">
        <ToastContainer />
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "50%",
              gap: "15px",
            }}
          >
            <div className="backButton" onClick={HandleBackButton}>
              <IoMdArrowRoundBack className="backicon" />
            </div>
            <div>
              <h3>
                <strong>{props.t(Constant.companytransaction)}</strong>
              </h3>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              width: "50%",
            }}
          >
            <div>
              <Searchbar
                placeholder={props.t(Constant.search)}
                searchHandler={handleSearchData}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: "5px",
            display: "flex",
            width: "100%",
            justifyContent: "end",
          }}
        >
          <div
            className="amountButtonDiv"
            onClick={handleOpen}
            // style={{ padding: "1px" }}
          >
            <button
              // className={`cancelbtn${classAlign}`}
              className="amountTransactionButton"
              style={{ fontSize: "20px" }}
              type="button"

              // onClick={() => TransactionHistoryHandler()}
            >
              {props.t("Amount Transfer")}

              {/* {props.t(Constant.transactionhistory)} */}
            </button>
          </div>
        </div>
        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <Button
                  onClick={handleClose}
                  sx={{ position: "absolute", top: 0, right: 0 }}
                  style={{ color: "red" }}
                >
                  X
                </Button>
                <Typography id="transition-modal-description" sx={{ mt: 1 }}>
                  <div>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ touched }) => (
                        <Form>
                          <div>
                            <Label className={`label${classAlign}`}>
                              {props.t(Constant.amount)}
                            </Label>
                            <Field
                              className="form-control"
                              style={{
                                backgroundColor: "#f1f1f9",
                                width: "300px !important",
                              }}
                              type="number"
                              name="amount"
                              onWheel={() => document.activeElement.blur()}
                              placeholder={props.t(Constant.amount)}
                            />
                            <p className="textdanger1">
                              <ErrorMessage name={props.t("amount")} />
                            </p>
                          </div>

                          <br />

                          <div>
                            <button
                              className={`cancelbtn${classAlign}`}
                              type="button"
                              style={{ width: "150px" }}
                              onClick={() => handleClose()}
                            >
                              {props.t(Constant.cancel)}
                            </button>
                            <button
                              className={`submitbtn${classAlign}`}
                              type="submit"
                              style={{ width: "150px" }}
                            >
                              {props.t(Constant.submit)}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Typography>
              </Box>
            </Fade>
          </Modal>
        </div>
        <div className="dashCardvalet-list" style={{ marginTop: "5px" }}>
          {cardHeader.map((h, index) => {
            return (
              <div className="dashCardvalet" key={h.value}>
                <div className="dashCardvalet-header">{h.headers}</div>

                <div
                  className="dashCardvalet-footer"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {h.value}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <br />
        {CompanyTransactionData?.docs.length === 0 ? (
          <div className="transactionnotavailablemessage">
            <div>
              <h1>{props.t(Constant.notransactionavailable)}</h1>
            </div>
          </div>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.username)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.companyname)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.currency)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.status)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.paymentmode)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.amountcharged)}</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {CompanyTransactionData?.docs &&
                  CompanyTransactionData?.docs.map(row => (
                    <TableRow
                      key={row._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" align="center">
                        {row.userObjId?.name}
                      </TableCell>
                      <TableCell align="center">
                        {row.companyObjId?.companyName}
                      </TableCell>
                      <TableCell align="center">{row?.currency}</TableCell>
                      <TableCell align="center">{row?.status}</TableCell>
                      <TableCell align="center">{row?.type}</TableCell>
                      <TableCell align="center">{row?.amount}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {CompanyTransactionData?.docs.length === 0 ? (
          ""
        ) : (
          <div style={{ marginTop: 10 }}>
            <Pagination
              current={currentPage}
              maxWidth={5}
              total={CompanyTransactionData?.totalPages}
              onPageChange={n => {
                setCurrentPage(n)
              }}
            />
          </div>
        )}
        {/* <div
          style={{
            marginTop: "30px",
            display: "flex",
            justifyContent: "center",
            justifyItems: "center",
            alignItems: "center",
            height: "300px",
            border: "2px solid black",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              justifyItems: "center",
              alignItems: "center",
              width: "50%",
              height: "100%",
              border: "2px solid black",
              border: "2px solid red",
            }}
          >
            sdd
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              justifyItems: "center",
              alignItems: "center",
              height: "100%",
              width: "50%",
              border: "2px solid red",
            }}
          >
            sddf
          </div>
        </div> */}
      </div>
    </div>
  )
}
CompanyTransaction.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(CompanyTransaction)
