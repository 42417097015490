import { React, useState, useEffect, useRef } from "react"
import "../../../../App.css"
import PropTypes, { element, string } from "prop-types"
import "bootstrap/dist/css/bootstrap.min.css"
import { FormGroup, Label, Input, Button, Form } from "reactstrap"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useHistory,
  useParams,
} from "react-router-dom"
import { withTranslation } from "react-i18next"
import { Constant } from "pages/Resources/Constant"
import { useDispatch, useSelector } from "react-redux"
import { fetchViewBookingRequest } from "store/actions"
import { constant } from "lodash"

const ViewBookingValet = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()
  console.log("BookingId==>", id)

  useEffect(() => {
    dispatch(fetchViewBookingRequest(id))
  }, [])

  const ViewBookingData = useSelector(
    state => state.fetchViewbookingReducer.Viewbookings.data
  )
  console.log("ViewCompanyData=>", ViewBookingData)

  const StartDateFormate = createdAt => {
    const date = new Date(createdAt)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    return `${day}/${month}/${year}`
    console.log(`${day}-${month}-${year} ${hours}:${minutes}:${seconds}`)
  }
  const EndDateFormate = createdAt => {
    const date = new Date(createdAt)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    return `${day}/${month}/${year}`
    console.log(`${day}-${month}-${year} ${hours}:${minutes}:${seconds}`)
  }
  const TimeFormate = createdAt => {
    const date = new Date(createdAt)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    return `${hours}:${minutes}:${seconds}`
    console.log(`${day}-${month}-${year} ${hours}:${minutes}:${seconds}`)
  }

  const StartTimeFormate = starttime => {
    const date = new Date(starttime)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    const formattedTime = date.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
    })
    return `${formattedTime}`
    // return `${hours}:${minutes}:${seconds}`
    // console.log(`${day}-${month}-${year} ${hours}:${minutes}:${seconds}`)
  }
  const EndTimeFormate = endtime => {
    const date = new Date(endtime)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    const formattedTime = date.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
    })
    return `${formattedTime}`
    // return `${hours}:${minutes}:${seconds}`
    // console.log(`${day}-${month}-${year} ${hours}:${minutes}:${seconds}`)
  }

  const cancelHandler = () => {
    history.push("/bookingvalet")
  }
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  return (
    <div className={`main-content${classAlign}`}>
      {(document.title = "Booking | Valet")}
      <div className="page-content">
        <div className="registerValet" style={{ marginLeft: "15px" }}>
          <div id="formValet">
            <h5>
              <strong>{props.t(Constant.viewbooking)}</strong>
            </h5>
            <br />
            <Form>
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.bookingid)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Booking ID"
                    onChange={e => setName(e.target.value)}
                    value={ViewBookingData?.bookingId}
                    disabled={true}
                    placeholder={props.t(Constant.bookingid)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.username)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="name"
                    onChange={e => setName(e.target.value)}
                    value={ViewBookingData?.carOwnerObjId.name}
                    disabled={true}
                    placeholder={props.t(Constant.username)}
                  />
                </div>
              </div>
              <br />
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.car)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Status"
                    disabled={true}
                    onChange={e => setEmail(e.target.value)}
                    value={ViewBookingData?.carObjId?.carName}
                    placeholder={props.t(Constant.car)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.carnumber)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Type"
                    onChange={e => setDesignation(e.target.value)}
                    value={ViewBookingData?.carObjId?.licensePlateNumber}
                    disabled={true}
                    placeholder={props.t(Constant.carnumber)}
                  />
                </div>
              </div>
              <br />
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.startdate)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Status"
                    disabled={true}
                    onChange={e => setEmail(e.target.value)}
                    value={
                      ViewBookingData?.bookingStartTime
                        ? StartDateFormate(ViewBookingData?.bookingStartTime)
                        : ""
                    }
                    placeholder={props.t(Constant.startdate)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.enddate)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Type"
                    onChange={e => setDesignation(e.target.value)}
                    value={
                      ViewBookingData?.reachedDestinationTime
                        ? EndDateFormate(
                            ViewBookingData?.reachedDestinationTime
                          )
                        : ""
                    }
                    disabled={true}
                    placeholder={props.t(Constant.enddate)}
                  />
                </div>
              </div>
              <br />
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.starttime)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Car Name"
                    onChange={e => setName(e.target.value)}
                    value={
                      ViewBookingData?.bookingStartTime
                        ? StartTimeFormate(ViewBookingData?.bookingStartTime)
                        : ""
                    }
                    disabled={true}
                    placeholder={props.t(Constant.starttime)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.endtime)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Car Name"
                    onChange={e => setName(e.target.value)}
                    value={
                      ViewBookingData?.reachedDestinationTime
                        ? EndTimeFormate(
                            ViewBookingData?.reachedDestinationTime
                          )
                        : ""
                    }
                    disabled={true}
                    placeholder={props.t(Constant.endtime)}
                  />
                </div>
              </div>
              <br />
              <div className="viewRowDiv">
                {" "}
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.pickupby)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Car Name"
                    onChange={e => setName(e.target.value)}
                    value={
                      ViewBookingData?.pickUpValetDriverObjId?.valetDriverName
                    }
                    disabled={true}
                    placeholder={props.t(Constant.pickupby)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.retrieveby)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Car Name"
                    onChange={e => setName(e.target.value)}
                    value={
                      ViewBookingData?.dropOffValetDriverObjId?.valetDriverName
                    }
                    disabled={true}
                    placeholder={props.t(Constant.retrieveby)}
                  />
                </div>
              </div>
              <br />
              <div className="viewRowDiv">
                {" "}
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.durationminutes)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Satrt Date"
                    onChange={e => setName(e.target.value)}
                    value={ViewBookingData?.parkingDuration}
                    disabled={true}
                    placeholder={props.t(Constant.durationminutes)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.tip)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="End Date"
                    onChange={e => setName(e.target.value)}
                    value={ViewBookingData?.tip}
                    disabled={true}
                    placeholder={props.t(Constant.tip)}
                  />
                </div>
              </div>
              <br />
              <div className="viewRowDiv">
                {" "}
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.amountchargedQAR)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Satrt Date"
                    onChange={e => setName(e.target.value)}
                    value={ViewBookingData?.totalAmount}
                    disabled={true}
                    placeholder={props.t(Constant.amountchargedQAR)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.vatvalueaddedtax)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="End Date"
                    onChange={e => setName(e.target.value)}
                    value={ViewBookingData?.VAT}
                    disabled={true}
                    placeholder={props.t(Constant.vatvalueaddedtax)}
                  />
                </div>
              </div>
              <br />

              {/* <Label className={`label${classAlign}`}>
                {props.t(Constant.amountchargedQAR)}
              </Label>
              <Input
                className="textfieldValet"
                type="text"
                name="End Date"
                onChange={e => setName(e.target.value)}
                value={ViewBookingData?.totalAmount}
                disabled={true}
                placeholder={props.t(Constant.amountchargedQAR)}
              /> */}
              <div className="inlineFieldsBtn">
                <button
                  className={`cancelbtn${classAlign}`}
                  type="button"
                  onClick={() => cancelHandler()}
                >
                  {props.t("Cancel")}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}
ViewBookingValet.propTypes = {
  t: PropTypes.any,
  state: PropTypes.object,
  location: PropTypes.func,
}

export default withTranslation()(ViewBookingValet)
