import { put, call, takeLatest } from "redux-saga/effects"
import axios from "axios"
import { toast } from "react-toastify"
import { EDIT_VALET_REQUEST } from "./actionType"
import { editValetFailure, editValetSuccess } from "./action"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* editValet(action) {
  try {
    yield call(
      axios.post,
      `${BASE_URL}/valet/update`,
      // "http://13.234.30.42:5000/valet/update",
      action.payload,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(editValetSuccess())
    // toast.success("Valet Driver Updated successfully")
  } catch (error) {
    const loginError = error.response
    yield put(editValetFailure(error.message))
    toast.error(`${loginError.data.data[0].msg}`)
  }
}

export default function* editValetSaga() {
  yield takeLatest(EDIT_VALET_REQUEST, editValet)
}
