import {
  ADD_COMPANY_REQUEST,
  ADD_COMPANY_FAILURE,
  ADD_COMPANY_SUCCESS,
} from "./actionTypes"

export const addCompanyRequest = (formData, successCallBack) => ({
  type: ADD_COMPANY_REQUEST,
  payload: {
    data: formData,
    successCallBack: successCallBack,
  },
})

export const addCompanySuccess = (addcompanydatasaga, companycode) => ({
  type: ADD_COMPANY_SUCCESS,
  payload: addcompanydatasaga,
  companycode,
})

export const addCompanyFailure = error => ({
  type: ADD_COMPANY_FAILURE,
  payload: error,
})
