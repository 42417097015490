import { React, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Constant } from "pages/Resources/Constant"
import Searchbar from "../Searchbar/Searchbar"
import Pagination from "react-responsive-pagination"
import { fetchCompanyTransactionRequest } from "store/actions"
import { fetchViewCompaniesRequest } from "store/actions"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "../../../DashboardValet/DashboardValet.style.css"

const ValetTransaction = props => {
  const dispatch = useDispatch()
  const id = sessionStorage.getItem("_id")
  const [currentPage, setCurrentPage] = useState(1)

  const [searchData, setSearchData] = useState("")

  const handleSearchData = searchtext => {
    console.log("searchbarValue==>", searchtext)
    setSearchData(searchtext)
    setCurrentPage(1)
  }
  const ViewCompanyData = useSelector(
    state => state.fetchViewcompanyReducer?.Viewcompanies?.data
  )
  const CompanyTransactionData = useSelector(
    state => state.fetchCompanytransactionReducer.Companytransactions?.data
  )
  console.log("CompanyTransactionData=>", CompanyTransactionData)
  useEffect(() => {
    dispatch(fetchViewCompaniesRequest(id))
  }, [])
  useEffect(() => {
    dispatch(fetchCompanyTransactionRequest(currentPage, searchData, id))
  }, [currentPage, searchData, id])
  const cardHeader = [
    {
      headers: props.t(Constant.totalamount),
      value: ViewCompanyData?.payments,
    },
    {
      headers: props.t(Constant.receivedamount),
      value: ViewCompanyData?.paidPayment,
    },
    {
      headers: props.t(Constant.pendingamount),
      value: ViewCompanyData?.pendingPayment,
    },
  ]
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  return (
    <div className={`main-content${classAlign}`}>
      {(document.title = "Transaction | Nater")}
      <div className="page-content">
        <ToastContainer />
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "50%",
              gap: "15px",
            }}
          >
            {" "}
            <div>
              <h3>
                <strong>{props.t(Constant.transaction)}</strong>
              </h3>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              width: "50%",
            }}
          >
            <div>
              <Searchbar
                placeholder={props.t(Constant.search)}
                searchHandler={handleSearchData}
              />
            </div>
          </div>
        </div>
        <div className="dashCardvalet-list" style={{ marginTop: "30px" }}>
          {cardHeader.map((h, index) => {
            return (
              <div className="dashCardvalet" key={h.value}>
                <div className="dashCardvalet-header">{h.headers}</div>

                <div
                  className="dashCardvalet-footer"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {h.value}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <br />
        {CompanyTransactionData?.docs.length === 0 ? (
          <div className="transactionnotavailablemessage">
            <div>
              <h1>{props.t(Constant.notransactionavailable)}</h1>
            </div>
          </div>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.username)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.companyname)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.currency)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.status)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.paymentmode)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.amountcharged)}</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {CompanyTransactionData?.docs &&
                  CompanyTransactionData?.docs.map(row => (
                    <TableRow
                      key={row._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" align="center">
                        {row.userObjId?.name}
                      </TableCell>
                      <TableCell align="center">
                        {row.companyObjId?.companyName}
                      </TableCell>
                      <TableCell align="center">{row?.currency}</TableCell>
                      <TableCell align="center">{row?.status}</TableCell>
                      <TableCell align="center">{row?.type}</TableCell>
                      <TableCell align="center">{row?.amount}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {CompanyTransactionData?.docs.length === 0 ? (
          ""
        ) : (
          <div style={{ marginTop: 10 }}>
            <Pagination
              current={currentPage}
              maxWidth={5}
              total={CompanyTransactionData?.totalPages}
              onPageChange={n => {
                setCurrentPage(n)
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
ValetTransaction.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ValetTransaction)
