import {
  FETCH_VIEW_VALETS_FAILURE,
  FETCH_VIEW_VALETS_REQUEST,
  FETCH_VIEW_VALETS_SUCCESS,
} from "./actionType"

export const fetchViewValetsRequest = id => (
  console.log("viewid==>", id),
  {
    type: FETCH_VIEW_VALETS_REQUEST,
    payload: id,
  }
)

export const fetchViewValetsSuccess = Viewvalets => ({
  type: FETCH_VIEW_VALETS_SUCCESS,
  payload: Viewvalets,
})

export const fetchViewValetsFailure = error => ({
  type: FETCH_VIEW_VALETS_FAILURE,
  payload: error,
})
