import React from "react"

const ValetManagement = props => {
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  return (
    <div className={`main-content${classAlign}`}>
      {(document.title = "Parent | valet")}
      <div className="page-content">
        <h1>this is ValetManagement page</h1>
      </div>
    </div>
  )
}

export default ValetManagement
