import {
  FETCH_COMPANIES_FILTER_FAILURE,
  FETCH_COMPANIES_FILTER_SUCCESS,
  FETCH_COMPANIES_FILTER_REQUEST,
} from "./actionType"

export const fetchCompaniesFilterRequest = () => (
  console.log("nnnnn==>"),
  {
    type: FETCH_COMPANIES_FILTER_REQUEST,
  }
)

export const fetchCompaniesFilterSuccess = companiesfilter => ({
  type: FETCH_COMPANIES_FILTER_SUCCESS,
  payload: companiesfilter,
})

export const fetchCompaniesFilterFailure = error => ({
  type: FETCH_COMPANIES_FILTER_FAILURE,
  payload: error,
})
