import React from "react"
import "../../../../App.css"
import PropTypes from "prop-types"
import "bootstrap/dist/css/bootstrap.min.css"
import { Label } from "reactstrap"
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useState } from "react"
import { Constant } from "pages/Resources/Constant"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { addvaletRequest } from "store/actions"
import { RotatingLines } from "react-loader-spinner"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/bootstrap.css"

const AddValetDrivers = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [valet_pic, setValetLogo] = useState("")
  const [profileError, setProfileError] = useState("")
  const [loader, setLoader] = useState(false)
  const [phone, setPhone] = useState("")
  const [phoneError, setPhoneError] = useState("")
  console.log("phoneee", phone)
  const { loading, error } = useSelector(state => state.addvaletReducer)
  const addvalet = useSelector(state => state.addvaletReducer)
  console.log("addvalet=>", addvalet)
  const initialValues = {
    valetDriverName: "",
    email: "",
    password: "",
    // phone: "",
    valetDriverId: "",
    document: null,
  }
  console.log("error-->", addvalet.error)
  const validationSchema = Yup.object({
    valetDriverName: Yup.string()
      .matches(/^[^0-9]+$/, props.t("Name must not contain any numbers"))
      .required(props.t("Name is Required")),
    email: Yup.string()
      .matches(
        /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i,
        props.t("Invalid email")
      )
      .required(props.t("Email is required")),
    // phone: Yup.string().required(props.t("Phone Number is Required")),

    valetDriverId: Yup.string().required(props.t("Company ID is Required")),
    password: Yup.string()
      .required(props.t("Password is Required"))
      .min(8, props.t("Password must have atleast 8 Characters"))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        props.t(
          "Password must contain at least one letter, one number, and one special character"
        )
      ),
  })

  const addValetPic = e => {
    setValetLogo(e.target.files[0])
    setProfileError("")
  }
  const addPhoneNumber = value => {
    setPhone(value)
    setPhoneError("")
  }
  console.log("Company pic set file-->", valet_pic)

  const companyObjId = sessionStorage.getItem("_id")
  console.log("id--->", companyObjId)

  const handleSubmit = values => {
    console.log("values-->", values)

    if (!phone) {
      setPhoneError(props.t("Phone Number is Required"))
      return
    } else {
      setPhoneError("")
    }
    if (!valet_pic) {
      setProfileError(props.t("Document is Required"))
      return
    } else {
      setProfileError("")
    }
    setLoader(true)
    const formData = new FormData()
    formData.append("valetDriverName", values.valetDriverName)
    formData.append("email", values.email)
    formData.append("password", values.password)
    formData.append("phone", phone)
    formData.append("companyObjId", companyObjId)
    formData.append("valetDriverId", values.valetDriverId)
    formData.append("document", valet_pic)
    // dispatch(addvaletRequest(formData))
    dispatch(
      addvaletRequest(formData, () => {
        console.log("Hogaygayagyavalet")
        setTimeout(() => {
          history.push("/valet_drivers")
        }, 1500)
      })
    )
    setLoader(false)
    // setTimeout(() => {
    //   history.push("/valet_drivers")
    // }, 2000)

    console.log("formdata-->", formData, "$$$$", valet_pic)
  }

  const cancelHandler = () => {
    history.push("/valet_drivers")
  }
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  return (
    <div className={`main-content${classAlign}`}>
      {(document.title = "Valet Driver | Nater")}
      <div className="page-content">
        <ToastContainer />
        <div
          style={{
            position: "fixed",
            left: "50%",
            top: "50%",
            zIndex: "999",
          }}
        >
          <RotatingLines
            strokeColor="#0a6ac8"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={loader}
          />
        </div>
        <div className="registerValet" style={{ marginLeft: "15px" }}>
          <div id="formValet">
            <h5>
              <strong>{props.t(Constant.addvalet)}</strong>
            </h5>
            <br />
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <Label className={`label${classAlign}`}>
                  {props.t(Constant.name)}
                </Label>
                <div className="passwordValet">
                  <Field
                    className="form-control"
                    style={{ backgroundColor: "#f1f1f9" }}
                    type="text"
                    name="valetDriverName"
                    placeholder={props.t(Constant.name)}
                  />
                  <p className="textdanger1">
                    <ErrorMessage name="valetDriverName" />
                  </p>
                </div>

                <br />
                <Label className={`label${classAlign}`}>
                  {props.t(Constant.email)}
                </Label>
                <div className="passwordValet">
                  <Field
                    className="form-control"
                    style={{ backgroundColor: "#f1f1f9" }}
                    type="text"
                    name="email"
                    placeholder={props.t(Constant.email)}
                  />
                  <p className="textdanger1">
                    <ErrorMessage name="email" />
                  </p>
                </div>

                <br />

                <Label className={`label${classAlign}`}>
                  {props.t(Constant.password)}
                </Label>
                <div className="passwordValet">
                  <Field
                    className="form-control"
                    style={{ backgroundColor: "#f1f1f9" }}
                    type="text"
                    name="password"
                    placeholder={props.t(Constant.password)}
                  />
                  <p className="textdanger1">
                    <ErrorMessage name="password" />
                  </p>
                </div>

                <br />
                {/* <Label className={`label${classAlign}`}>
                  {props.t(Constant.phonenumber)}
                </Label>
                <div className="passwordValet">
                  <Field
                    className="form-control"
                    style={{ backgroundColor: "#f1f1f9" }}
                    type="text"
                    name="phone"
                    placeholder={props.t(Constant.phonenumber)}
                  />
                  <p className="textdanger1">
                    <ErrorMessage name="phone" />
                  </p>
                </div>
                <br /> */}
                <Label className={`label${classAlign}`}>
                  {props.t(Constant.phonenumber)}
                </Label>
                <br />
                <div className="passwordValet">
                  <PhoneInput
                    country={"eg"}
                    enableSearch={true}
                    value={phone}
                    onChange={addPhoneNumber}
                  />
                  <p className="textdanger1">{props.t(phoneError)}</p>
                </div>
                <br />
                <Label className={`label${classAlign}`}>
                  {props.t(Constant.valetID)}
                </Label>
                <div className="passwordValet">
                  <Field
                    className="form-control"
                    style={{ backgroundColor: "#f1f1f9" }}
                    type="text"
                    name="valetDriverId"
                    placeholder={props.t(Constant.valetID)}
                  />
                  <p className="textdanger1">
                    <ErrorMessage name="valetDriverId" />
                  </p>
                </div>

                <br />
                <div className="passwordValet">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.valetdocument)}
                  </Label>
                </div>
                <br />
                <div className="passwordValet">
                  <input
                    className="form-control"
                    type="file"
                    name="document"
                    onChange={addValetPic}
                  />
                  <p className="textdanger1">{props.t(profileError)}</p>
                </div>

                <br />

                <div className="inlineFieldsBtn">
                  <button
                    className={`cancelbtn${classAlign}`}
                    type="button"
                    onClick={() => cancelHandler()}
                  >
                    {props.t("Cancel")}
                  </button>
                  <button className={`submitbtn${classAlign}`} type="submit">
                    {props.t("Submit")}
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}
AddValetDrivers.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(AddValetDrivers)
