import { put, call, takeLatest, takeEvery, all } from "redux-saga/effects"
import { LOGIN_REQUEST } from "./actionType"
import { loginsuccess, loginFailure } from "./action "
// import { Login_API_URL } from "../BaseURL/baseURL";
import axios from "axios"
import { toast } from "react-toastify"
import { BASE_URL } from "../BaseURL/baseURL"

export function* loginSaga(action) {
  try {
    const { email, password, successCallBack } = action.payload
    const response = yield call(
      axios.post,
      `${BASE_URL}/admin/login`,

      { email, password }
    )
    yield put(loginsuccess(response.data))
    // toast.success("Login successful!")
    successCallBack(response)
  } catch (error) {
    console.log("errors=-->", error.response)
    const loginError = error.response
    yield put(loginFailure(error.message))
    toast.error(`${loginError?.data?.message}`)
  }
}

export function* watchLogin() {
  yield takeLatest(LOGIN_REQUEST, loginSaga)
}
