import React, { useEffect, useState } from "react"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import { get, map } from "lodash"
import { withTranslation } from "react-i18next"

//i18n
import i18n from "../../../i18n"
import languages from "common/languages"

const LanguageDropdown = () => {
  // Declare a new state variable, which we'll call "menu"
  const [selectedLang, setSelectedLang] = useState("")
  const [menu, setMenu] = useState(false)

  useEffect(() => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
    // console.log(localStorage.getItem("I18N_LANGUAGE"), selectedLang, languages)
    if (currentLanguage) setSelectedLang(currentLanguage)
    else {
      localStorage.setItem("I18N_LANGUAGE", "en")
    }
  }, [])

  const changeLanguageAction = lang => {
    // set language as i18n
    // console.log("lang::::", lang)
    i18n.changeLanguage(lang)
    localStorage.setItem("I18N_LANGUAGE", lang)
    setSelectedLang(lang)
    window.location.reload()
  }

  const toggle = () => {
    setMenu(!menu)
  }

  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
        <DropdownToggle className="btn header-item " tag="button">
          <img
            src={get(languages, `${selectedLang}.flag`)}
            alt="Skote"
            height="16"
            className="me-1"
          />
        </DropdownToggle>
        <div className={`dropdown-menu-end_${classAlign}`}>
          <DropdownMenu className="language-switch">
            {map(Object.keys(languages), key => (
              <DropdownItem
                key={key}
                onClick={() => changeLanguageAction(key)}
                className={`notify-item ${
                  selectedLang === key ? "active" : "none"
                }`}
              >
                <img
                  src={get(languages, `${key}.flag`)}
                  alt="Skote"
                  className="me-1"
                  height="12"
                />
                <span className="align-middle">
                  {get(languages, `${key}.label`)}
                </span>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </div>
      </Dropdown>
    </>
  )
}

export default withTranslation()(LanguageDropdown)
