import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"
import { FETCH_VIEW_VALETS_REQUEST } from "./actionType"
import { fetchViewValetsFailure, fetchViewValetsSuccess } from "./actions"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchViewValets(action) {
  // const id = localStorage.getItem("_id")
  // console.log("valetId==>", id)
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/valet/details`,
      // "http://13.234.30.42:5000/valet/details",
      { valetDriverObjId: action.payload },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchViewValetsSuccess(response.data))
  } catch (error) {
    yield put(fetchViewValetsFailure(error))
  }
}

function* fetchViewvaletSaga() {
  yield takeEvery(FETCH_VIEW_VALETS_REQUEST, fetchViewValets)
}

export default fetchViewvaletSaga
