import React from "react"
import { Route, Switch } from "react-router-dom"
import PropTypes from "prop-types"
import PrivateRoute from "./PrivateRoutes"

import Signup from "pages/Components/Pages/Common/Signup"
import Login from "pages/Authentication/Login"
import Dashboard from "../pages/Dashboard/index"
import FourZeroFour from "pages/Components/Pages/Page Not Found/FourZeroFour"
import ForgotPassword from "pages/Components/Pages/ForgotPassword/ForgotPassword"
import ForgotPasswordAfter from "pages/Components/Pages/ForgotPassword/ForgotPasswordAfter"

// const PrivateRoute = props => {
//   console.log("private", props)
//   const token = sessionStorage.getItem("token")
//   if (token) {
//     return <Route exact={true} path={props.path} component={props.component} />
//   } else {
//     return <Login {...props} />
//   }
// }

function Router(props) {
  return (
    <Switch>
      <Route path="/" component={Login} exact={true} />
      <Route path="/login" component={Login} />
      {/* <Route path="/signup" component={Signup} /> */}
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/forgot-password-after" component={ForgotPasswordAfter} />

      <PrivateRoute path="/dashboard" component={Dashboard} />
      <Route component={FourZeroFour} />
      {/* <PrivateRoute path="/profile" component={Profile} /> */}
    </Switch>
  )
}
Router.propTypes = {
  component: PropTypes.func,
  path: PropTypes.any,
  location: PropTypes.object,
  t: PropTypes.any,
}

export default Router
