import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"
import {
  FETCH_DASHBOARDVALET_REQUEST,
  FETCH_DASHBOARDVALET_SUCCESS,
  FETCH_DASHBOARDVALET_FAILURE,
} from "./actionType"
import {
  fetchDashboardvaletFailure,
  fetchDashboardvaletSuccess,
} from "./actions"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchDashboardValetData(action) {
  const id = sessionStorage.getItem("_id")
  // console.log("SearcH==>", action.payload.searchedText)
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/company/dashboard`,
      {
        companyObjId: id,
        month: action.payload.month,
        year: action.payload.year,
      },

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchDashboardvaletSuccess(response.data))
  } catch (error) {
    yield put(fetchDashboardvaletFailure(error))
  }
}

function* fetchdashboardvaletSaga() {
  yield takeEvery(FETCH_DASHBOARDVALET_REQUEST, fetchDashboardValetData)
}

export default fetchdashboardvaletSaga
