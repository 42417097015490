import { call, put, takeLatest } from "redux-saga/effects"
import axios from "axios"
import {
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
} from "./actionType"
import { fetchCompaniesFailure, fetchCompaniesSuccess } from "./actions"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchCompanies(action) {
  // console.log("SearcH==>", action.payload.searchedText)
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/company/all`,
      {
        page: action.payload.page,
        limit: 10,
        search: action.payload.searchedText ? action.payload.searchedText : "",
        sort: action.payload.sort,
        valets: action.payload.valets,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchCompaniesSuccess(response.data))
  } catch (error) {
    yield put(fetchCompaniesFailure(error))
  }
}

function* fetchcompanySaga() {
  yield takeLatest(FETCH_COMPANIES_REQUEST, fetchCompanies)
}

export default fetchcompanySaga
