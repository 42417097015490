import { VerticalSplit } from "@material-ui/icons"
import { React, useEffect } from "react"
// import DashCard from "./Card.component"
import DashCardValet from "./CardValet.Component"
import "./DashboardValet.style.css"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import { withTranslation } from "react-i18next"
// import { FaUserFriends, FaRegUser, FaAward, FaDollarSign } from "react-icons/fa"

import { AiOutlineSetting } from "react-icons/ai"
import { GiBullseye } from "react-icons/gi"
import { TbLayoutDashboard, TbArrowsUpDown, TbDiscount2 } from "react-icons/tb"
import { GoMegaphone } from "react-icons/go"
import { SlFlag } from "react-icons/sl"
import { HiUserGroup } from "react-icons/hi"
import { FaRegUser } from "react-icons/fa"
import { BsFillBuildingsFill } from "react-icons/bs"
import { SiCashapp } from "react-icons/si"
import { SlCalender } from "react-icons/sl"
import { MdPregnantWoman } from "react-icons/md"
import { Constant } from "pages/Resources/Constant"
import { useDispatch } from "react-redux"
import { fetchDashboardvaletRequest } from "store/actions"
import { useSelector } from "react-redux"
import { FaParking } from "react-icons/fa" // booking
import { MdPayment } from "react-icons/md" // payment
import { AiFillCar } from "react-icons/ai" // valet
import ValetIcon from "../../pages/Components/Assets/Images/bellboy 1.png"
// import totalConversion from "../Components/Assets/Images/conversion.png"
// import { ValetIcon } from "store/Valet/SvgIcon/SvgIcon"

function DashboardValetCards(props) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchDashboardvaletRequest())
  }, [])

  const DashboardValetCardData = useSelector(
    state => state.fetchdashboardvaletReducer?.dashboardValetData.data
  )
  console.log("DashboardValetCardData=>", DashboardValetCardData)
  const history = useHistory()
  const cardHeader = [
    {
      headers: props.t(Constant.valetdriver),
      value: DashboardValetCardData?.valetCount,
      icon: <AiFillCar className="icons" />,
      // icon: <img className="icons" src={ValetIcon} />,
    },
    {
      headers: props.t(Constant.booking),
      value: DashboardValetCardData?.bookingCount.total,
      icon: <FaParking className="icons" />,
    },
    {
      headers: props.t(Constant.totalamountQAR),
      value: DashboardValetCardData?.totalPayment,
      icon: <MdPayment className="icons" />,
    },
    // {
    //   headers: props.t(Constant.user),
    //   value: "1.4k",
    //   icon: <FaRegUser className="icons" />,
    // },
    // {
    //   headers: "Total initiatives",
    //   value: "224.5k",
    //   icon: <SlFlag className="icons" />,
    // },
    // {
    //   headers: "Total campaigns",
    //   value: "647",
    //   icon: <GoMegaphone className="icons" />,
    // },
    // {
    //   headers: "Total Discoveries",
    //   value: "834",
    //   icon: <TbDiscount2 className="icons" />,
    // },
    // {
    //   headers: "Total Conversions",
    //   value: "1.4k",
    //   // icon: <img src={totalConversion} className="icons" />,
    // },
    // {
    //   headers: "Total users",
    //   value: "1.4k",
    //   icon: <FaRegUser className="icons" />,
    // },
    // {
    //   headers: "Total cost",
    //   value: "1.4k",
    //   icon: <FaDollarSign className="icons" />,
    // },
  ]

  return (
    <div className="dashCardvalet-list">
      {cardHeader.map((h, index) => {
        // console.log("aaaaaa=>", index)z
        return (
          <DashCardValet
            key={h.value}
            heading={h.headers}
            footerValue={h.value}
            icons={h.icon}
          />
        )
      })}
      {/* <DashCardValet
        key={500}
        heading={"valet"}
        footerValue={500}
        icons={<BsFillBuildingsFill className="icons" />}
        onClick={() => history.push("/valet_drivers")}
      /> */}
    </div>
  )
}
DashboardValetCards.propTypes = {
  t: PropTypes.any,
}
export default withTranslation()(DashboardValetCards)
