import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"
import { FETCH_COMPANY_TRANSACTION_REQUEST } from "./actionType"

import {
  fetchCompanyTransactionFailure,
  fetchCompanyTransactionSuccess,
} from "./action"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchCompanyTransactions(action) {
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/admin/all/company/transaction`,
      // "http://13.234.30.42:5000/Company/list",
      {
        page: action.payload.page,
        // page: 1,
        limit: 10,
        search: action.payload.searchedText ? action.payload.searchedText : "",
        companyObjId: action.payload.companyObjId,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchCompanyTransactionSuccess(response.data))
  } catch (error) {
    yield put(fetchCompanyTransactionFailure(error))
  }
}

function* fetchCompanytransactionSaga() {
  yield takeEvery(FETCH_COMPANY_TRANSACTION_REQUEST, fetchCompanyTransactions)
}

export default fetchCompanytransactionSaga
