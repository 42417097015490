import React from "react"
import { Button } from "@material-ui/core"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { FaEdit, FaEye } from "react-icons/fa"
import { MdDeleteOutline } from "react-icons/md"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { useEffect } from "react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { fetchvaletsRequest } from "store/actions"
import { deleteValetRequest } from "store/actions"
import Swal from "sweetalert2"
import Pagination from "react-responsive-pagination"
import Searchbar from "../Searchbar/Searchbar"
import { Constant } from "pages/Resources/Constant"
import { IoIosRefresh } from "react-icons/io"

const ValetDrivers = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)

  const [searchData, setSearchData] = useState("")
  const [sortOption, setSortOption] = useState("")
  const [filterOption, setFilterOption] = useState("")
  const [bookingOption, setBookingOption] = useState("")
  const [rotation, setRotation] = useState(0)

  const handleSearchData = searchtext => {
    console.log("searchbarValue==>", searchtext)
    setSearchData(searchtext)
    setCurrentPage(1)
  }
  const handleSortChange = event => {
    setSortOption(event.target.value)
  }

  const handleFilterChange = event => {
    setFilterOption(event.target.value)
    setBookingOption("")
  }

  const handleBookingChange = event => {
    setBookingOption(event.target.value)
  }

  const ValetData = useSelector(state => state.fetchvaletReducer.valets)
  console.log("ValetDATA=>", ValetData)

  const ValetDelete = useSelector(state => state.deletevaletReducer)
  console.log("ValetDelete=>", ValetDelete)

  useEffect(() => {
    dispatch(
      fetchvaletsRequest(currentPage, searchData, sortOption, bookingOption)
    )
  }, [currentPage, searchData, sortOption, bookingOption])

  const Company_ID = sessionStorage.getItem("_id")
  console.log("Company_ID==>", Company_ID)

  function createData(name, Email, Phone_Number, Valet_ID, Action) {
    return { name, Email, Phone_Number, Valet_ID, Action }
  }

  const rows = [
    createData("Aayush", "aayush00@gmail.com", 9658741265, "Active"),
    createData("Rohit", "aayush00@gmail.com", 9658741265, "Active"),
    createData("Aditya", "aayush00@gmail.com", 9658741265, "Active"),
    createData("Neha", "aayush00@gmail.com", 9658741265, "Active"),
    createData("Shubham", "aayush00@gmail.com", 9658741265, "Active"),
  ]
  const handleDelete = id => {
    Swal.fire({
      text: props.t("Are you sure you want to remove this Valet Driver?"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
      confirmButtonText: props.t("OK"),
      cancelButtonText: props.t("Cancel"),
      showConfirmButton: true,
      showCancelButton: true,
    }).then(willDelete => {
      if (willDelete.isConfirmed) {
        dispatch(deleteValetRequest(id))
        if (ValetDelete?.error == null) {
          toast.success(props.t("Valet Driver deleted successfully"))
        } else toast.error(ValetDelete.error)
        dispatch(fetchvaletsRequest())
      } else {
      }
    })
  }

  const addValetDriverHandler = () => {
    history.push("/add_valetdrivers")
  }
  const editValetDriverHandler = id => {
    history.push(`/edit_valetdrivers/${id}`)
  }
  const viewValetDriverHandler = id => {
    history.push(`/view_valetdrivers/${id}`)
  }
  const HandleRefreshFilter = () => {
    setRotation(rotation + 360)
    setFilterOption("")
    setSortOption("")
    setBookingOption("")
    setCurrentPage(1)
  }
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  return (
    <div className={`main-content${classAlign}`}>
      {(document.title = "Valet Driver | Nater")}
      <div className="page-content">
        <ToastContainer />
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "40%",
            }}
          >
            <h3 className="valetheadingdiv">
              <strong>{props.t(Constant.valetdriver)}</strong>
            </h3>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              width: "60%",
            }}
          >
            <div>
              <Searchbar
                placeholder={props.t(Constant.search)}
                searchHandler={handleSearchData}
              />
            </div>
            <div className="addButton" onClick={addValetDriverHandler}>
              <Button className="MUI-addbutton">{props.t("Add +")}</Button>
            </div>
          </div>
        </div>
        <br />
        <div className="companyfiltermaindiv">
          <div className="newcompanyfilterseconddiv">
            {/* <label style={{ marginRight: "10px" }}>
              Sort By: */}
            <select
              value={sortOption}
              onChange={handleSortChange}
              className="newfilterstyle"
            >
              <option value="">{props.t(Constant.sortby)}</option>
              <option value="-1">{props.t(Constant.newest)}</option>
              <option value="1">{props.t(Constant.oldest)}</option>
            </select>
            {/* </label> */}
            {/* <label style={{ marginRight: "10px" }}>
              Filter By: */}
            <select
              value={filterOption}
              onChange={handleFilterChange}
              className="newfilterstyle"
            >
              <option value="">{props.t(Constant.filterby)}</option>
              <option value="booking">{props.t(Constant.booking)}</option>
            </select>
            {/* </label> */}
          </div>
          <div className="newcompanyfilterthirddiv">
            {filterOption === "booking" && (
              // <label style={{ marginRight: "10px" }}>
              //   Amount Range:
              <select
                value={bookingOption}
                onChange={handleBookingChange}
                className="newfilterstyle"
              >
                <option value="">{props.t(Constant.bookingrange)}</option>
                <option value="0-50">0-50</option>
                <option value="51-100">51-100</option>
                <option value="101-150">101-150</option>
                <option value="150+">150+</option>
              </select>
              // </label>
            )}
            <span className="refreshButton" onClick={HandleRefreshFilter}>
              <IoIosRefresh
                className="refreshicon"
                style={{ transform: `rotate(${rotation}deg)` }}
              />
            </span>
          </div>
        </div>
        <br />
        {ValetData.data?.length === 0 ? (
          <div className="datanotavailablemessage">
            <div>
              <h1>{props.t("No Valet Driver Available")}</h1>
            </div>
          </div>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.valetname)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong> {props.t(Constant.email)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.phonenumber)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.pickupno)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.retrieveno)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.totalbooking)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.valetID)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.action)}</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ValetData.data &&
                  ValetData.data.map(row => (
                    <TableRow
                      key={row._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" align="center">
                        {row.valetDriverName}
                      </TableCell>
                      <TableCell align="center">{row.email}</TableCell>
                      <TableCell align="center">{row.phone}</TableCell>
                      <TableCell align="center">{row.pickupBooking}</TableCell>
                      <TableCell align="center">{row.dropOffBooking}</TableCell>
                      <TableCell align="center">{row.totalBooking}</TableCell>
                      <TableCell align="center">{row.valetDriverId}</TableCell>
                      <TableCell align="center">
                        {
                          <div className="Action-button">
                            <span
                              onClick={() => viewValetDriverHandler(row._id)}
                            >
                              {" "}
                              <FaEye className="userListView" />
                            </span>{" "}
                            <span
                              onClick={() => editValetDriverHandler(row._id)}
                            >
                              {" "}
                              <FaEdit className="userListEdit" />
                            </span>{" "}
                            <span onClick={() => handleDelete(row._id)}>
                              <MdDeleteOutline className="userListDelete" />
                            </span>
                          </div>
                        }
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {ValetData.data?.length === 0 ? (
          ""
        ) : (
          <div style={{ marginTop: 10 }}>
            <Pagination
              current={currentPage}
              maxWidth={5}
              total={ValetData.totalPages}
              onPageChange={n => {
                setCurrentPage(n)
                // dispatch(fetchvaletsRequest(n))
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
ValetDrivers.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ValetDrivers)
