import { put, call, takeLatest } from "redux-saga/effects"
import axios from "axios"
import { EDIT_COMPANY_REQUEST } from "./actionType"
import { editCompanyFailure, editCompanySuccess } from "./actions"
import { toast } from "react-toastify"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"
function* editCompany(action) {
  try {
    yield call(
      axios.post,
      `${BASE_URL}/company/update`,
      // "http://13.234.30.42:5000/company/update",
      action.payload,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(editCompanySuccess())
    // toast.success("Company Updated successfully")
  } catch (error) {
    const loginError = error.response
    yield put(editCompanyFailure(error.message))
    toast.error(`${loginError.data.data[0].msg}`)
  }
}

export default function* editCompanySaga() {
  yield takeLatest(EDIT_COMPANY_REQUEST, editCompany)
}
