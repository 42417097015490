import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import { Route, Redirect, useHistory } from "react-router-dom"
import swal from "sweetalert2"

import { Link } from "react-router-dom"
import axios from "axios"

// import images

import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import aac_logo from "../../Assets/Images/aac_logo.png"
// import schoolDataObject from "../../Controllers/SchoolController.js"
import "../../../../App.css"
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import { MdEmail } from "react-icons/md"

//Import config
// import { BASE_URL } from "pages/Components/Controllers/API_Config"
import { withTranslation } from "react-i18next"

function ResetPassword(props) {
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [confirmPasswordError, setConfirmPasswordError] = useState("")
  const [token, setToken] = useState("")

  const [show, setShow] = useState(false)
  const [cShow, setcShow] = useState(false)

  // const dispatch = useDispatch()
  const history = useHistory()
  const emailRegex =
    /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i
  const alphaNumericRegex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/

  // const tokendata = sessionStorage.getItem("token")
  let confirmPasswordErrorCheck = false
  let PasswordErrorCheck = false
  useEffect(() => {
    setToken(window.location.hash.split("/")[2])
  }, [])
  const resetPasswordHandler = () => {
    if (password === "") {
      setPasswordError("Please enter the password")
    } else if (!alphaNumericRegex.test(password)) {
      setPasswordError(
        "Password must have atleast 8 Characters with one uppercase letter, one lowercase letter, one number & one special character. "
      )
    } else {
      setPasswordError("")
      PasswordErrorCheck = true
    }

    if (confirmPassword === "") {
      setConfirmPasswordError("Please enter the confirm password")
    } else if (confirmPassword !== password) {
      setConfirmPasswordError("Password and confirm password doesn't match")
    } else {
      setConfirmPasswordError("")
      confirmPasswordErrorCheck = true
    }
    const body = {
      confirm: confirmPassword,
      password: password,
      token: token,
    }
    if (confirmPasswordErrorCheck && PasswordErrorCheck && token) {
      schoolDataObject.resetPassword(body, result => {
        if (result.data.status === true) {
          swal.fire({
            title: "",
            text: `${result.data.message}`,
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
          })
          history.push("/")
        } else {
          swal.fire({
            title: "",
            text: `Invalid Credentials`,
            icon: "warning",
            timer: 4000,
            showConfirmButton: false,
            showConfirmButton: false,
          })
        }
      })
    }
  }

  const toggleButton = () => {
    setShow(prevState => !prevState)
  }
  const toggleCButton = () => {
    setcShow(prevState => !prevState)
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Reset Password | AAC</title>
      </MetaTags>
      <div className="App">
        <div className="registerLogin">
          <form id="formLogin">
            <img src={aac_logo} style={{ width: "145px", height: "134px" }} />
            <h1>Reset Password </h1>
            <br />

            <div className="">
              <Form className="">
                <div className="fields">
                  <Label className="labelltrClass">Password</Label>
                  <div className="password">
                    <Input
                      className="signUpFields"
                      name="password"
                      onChange={e => setPassword(e.target.value)}
                      value={password}
                      placeholder="Password"
                      type={show ? "text" : "password"}
                    />
                    <button
                      type="button"
                      className="passwordBtn"
                      onClick={toggleButton}
                    >
                      {show ? <AiFillEye /> : <AiFillEyeInvisible />}
                    </button>
                  </div>
                  {passwordError && (
                    <p style={{ color: "red", textAlign: "left" }}>
                      {props.t(passwordError)}
                    </p>
                  )}
                  {/* <br /> */}
                  <Label className="labelltrClass">Confirm Password</Label>
                  <div className="password">
                    <Input
                      className="signUpFields"
                      name="Confirm Password"
                      onChange={e => setConfirmPassword(e.target.value)}
                      value={confirmPassword}
                      placeholder="Confirm Password"
                      type={cShow ? "text" : "password"}
                    />
                    <button
                      type="button"
                      className="passwordBtn"
                      onClick={toggleCButton}
                    >
                      {cShow ? <AiFillEye /> : <AiFillEyeInvisible />}
                    </button>
                  </div>
                  {confirmPasswordError && (
                    <p style={{ color: "red", textAlign: "left" }}>
                      {props.t(confirmPasswordError)}
                    </p>
                  )}
                  <br />
                  {/* <p
                  style={{
                    float: "right",
                  }}
                >
                  <Link to="/forgot_password" className="textfield">
                    Forgot Password?
                  </Link>
                </p> */}

                  <button
                    className="btnLgIn"
                    type="button"
                    onClick={resetPasswordHandler}
                  >
                    Submit
                  </button>

                  {/* <p
                  style={{
                    color: "grey",
                    marginTop: "10px",
                  }}
                >
                  {" Didn't have an Account?"}
                  <Link to="/register" className="textfield">
                    Signup
                  </Link>
                </p> */}
                </div>
              </Form>
            </div>
          </form>
        </div>
      </div>

      {/* <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                      </div> */}

      {/* <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div> */}

      {/* <div className="mt-5 text-center">
                <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
              </div> */}

      <ToastContainer />
    </React.Fragment>
  )
}

ResetPassword.propTypes = {
  // history: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(ResetPassword)
