import {
  FETCH_AMOUNT_TRANSFER_FAILURE,
  FETCH_AMOUNT_TRANSFER_REQUEST,
  FETCH_AMOUNT_TRANSFER_SUCCESS,
} from "./actionType"

export const fetchAmountTransferRequest = (
  amount,
  id,
  amountSuccessCallBack
) => ({
  type: FETCH_AMOUNT_TRANSFER_REQUEST,
  payload: {
    amount: amount,
    companyObjId: id,
    amountSuccessCallBack: amountSuccessCallBack,
  },
})

export const fetchAmountTransferSuccess = AmountTransfers => ({
  type: FETCH_AMOUNT_TRANSFER_SUCCESS,
  payload: AmountTransfers,
})

export const fetchAmountTransferFailure = error => ({
  type: FETCH_AMOUNT_TRANSFER_FAILURE,
  payload: error,
})
