import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"
import { FETCH_AMOUNT_TRANSFER_REQUEST } from "./actionType"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import {
  fetchAmountTransferFailure,
  fetchAmountTransferSuccess,
} from "./action"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchAmountTransfers(action) {
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/admin/pay/company`,

      {
        amount: action.payload.amount,
        companyObjId: action.payload.companyObjId,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchAmountTransferSuccess(response.data))

    action.payload.amountSuccessCallBack(response.data)
  } catch (error) {
    yield put(fetchAmountTransferFailure(error))
  }
}

function* fetchAmountTransferSaga() {
  yield takeEvery(FETCH_AMOUNT_TRANSFER_REQUEST, fetchAmountTransfers)
}

export default fetchAmountTransferSaga
