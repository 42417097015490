import {
  ADD_VALET_FAILURE,
  ADD_VALET_REQUEST,
  ADD_VALET_SUCCESS,
} from "./actionType"

export const addvaletRequest = (formData, successCallBack) => ({
  type: ADD_VALET_REQUEST,
  payload: {
    data: formData,
    successCallBack: successCallBack,
  },
})

export const addvaletSuccess = () => ({
  type: ADD_VALET_SUCCESS,
})

export const addvaletFailure = error => ({
  type: ADD_VALET_FAILURE,
  payload: error,
})
