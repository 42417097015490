import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"
import { FETCH_VIEW_USER_REQUEST } from "./actionType"
import { fetchViewUserFailure, fetchViewUserSuccess } from "./actions"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchViewUser(action) {
  // const id = localStorage.getItem("_id")
  // console.log("valetId==>", id)
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/user/details`,
      // "http://13.234.30.42:5000/user/details",
      { userObjId: action.payload },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchViewUserSuccess(response.data))
  } catch (error) {
    yield put(fetchViewUserFailure(error))
  }
}

function* fetchViewuserSaga() {
  yield takeEvery(FETCH_VIEW_USER_REQUEST, fetchViewUser)
}

export default fetchViewuserSaga
