import {
  FETCH_COMPANY_TRANSACTION_FAILURE,
  FETCH_COMPANY_TRANSACTION_REQUEST,
  FETCH_COMPANY_TRANSACTION_SUCCESS,
} from "./actionType"

export const fetchCompanyTransactionRequest = (n, search, id) => ({
  type: FETCH_COMPANY_TRANSACTION_REQUEST,
  payload: {
    page: n,
    searchedText: search,
    companyObjId: id,
  },
})

export const fetchCompanyTransactionSuccess = Companytransactions => ({
  type: FETCH_COMPANY_TRANSACTION_SUCCESS,
  payload: Companytransactions,
})

export const fetchCompanyTransactionFailure = error => ({
  type: FETCH_COMPANY_TRANSACTION_FAILURE,
  payload: error,
})
