import { React, useEffect } from "react"
import "../../../../App.css"
import PropTypes from "prop-types"
import "bootstrap/dist/css/bootstrap.min.css"
import { Label, Input, Form } from "reactstrap"
import {
  BrowserRouter as Router,
  useHistory,
  useParams,
} from "react-router-dom"
import { withTranslation } from "react-i18next"
import { Constant } from "pages/Resources/Constant"
import { useDispatch, useSelector } from "react-redux"
import { fetchViewPaymentRequest } from "store/actions"
const ViewPayment = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()
  console.log("paymentId==>", id)

  useEffect(() => {
    dispatch(fetchViewPaymentRequest(id))
  }, [])

  const ViewPaymentData = useSelector(
    state => state.fetchViewpaymentReducer.Viewpayments.data
  )
  console.log("ViewPaymentData=>", ViewPaymentData)
  const cancelHandler = () => {
    history.push("/payments")
  }
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  return (
    <div className={`main-content${classAlign}`}>
      {(document.title = "Payment | Valet")}
      <div className="page-content">
        <div className="registerValet" style={{ marginLeft: "15px" }}>
          <div id="formValet">
            <h5>
              <strong>{props.t(Constant.viewpayment)}</strong>
            </h5>
            <br />
            <Form>
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.transactionid)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="name"
                    value={ViewPaymentData?.transactionId}
                    disabled={true}
                    placeholder={props.t(Constant.transactionid)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.bookingid)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Booking ID"
                    value={ViewPaymentData?.bookingObjId?.bookingId}
                    disabled={true}
                    placeholder={props.t(Constant.bookingid)}
                  />
                </div>
              </div>
              <br />
              {/* <Label className={`label${classAlign}`}>
                {props.t("Booking ID")}
              </Label>
              <Input
                className="textfieldValet"
                type="text"
                name="Booking ID"
                // value={ViewBookingData?.bookingId}
                disabled={true}
                placeholder={props.t("Booking ID")}
              />
              <br />
              <Label className={`label${classAlign}`}>
                {props.t(Constant.name)}
              </Label>
              <Input
                className="textfieldValet"
                type="text"
                name="name"
                // value={ViewBookingData?.bookingId}
                disabled={true}
                placeholder={props.t(Constant.name)}
              />
              <br /> */}
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.username)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="name"
                    value={ViewPaymentData?.userObjId.name}
                    disabled={true}
                    placeholder={props.t(Constant.name)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.companyname)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Status"
                    value={ViewPaymentData?.companyObjId?.companyName}
                    disabled={true}
                    placeholder={props.t(Constant.companyname)}
                  />
                </div>
              </div>
              <br />
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.carname)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Status"
                    value={ViewPaymentData?.bookingObjId?.carObjId.carName}
                    disabled={true}
                    placeholder={props.t(Constant.carname)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.currency)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Status"
                    value={ViewPaymentData?.currency}
                    disabled={true}
                    placeholder={props.t(Constant.currency)}
                  />
                </div>
              </div>
              <br />
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.paymentmode)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Type"
                    value={ViewPaymentData?.type}
                    disabled={true}
                    placeholder={props.t(Constant.paymentmode)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.status)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Type"
                    value={ViewPaymentData?.status}
                    disabled={true}
                    placeholder={props.t(Constant.status)}
                  />
                </div>
              </div>

              <br />
              {/* <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.amountchargedQAR)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Type"
                    value={ViewPaymentData?.amount}
                    disabled={true}
                    placeholder={props.t(Constant.amountchargedQAR)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.tip)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Type"
                    value={ViewPaymentData?.bookingObjId?.tip}
                    disabled={true}
                    placeholder={props.t(Constant.tip)}
                  />
                </div>
              </div> */}

              <Label className={`label${classAlign}`}>
                {props.t(Constant.totalamountchargedQAR)}
              </Label>
              <Input
                className="textfieldValet"
                type="text"
                name="Type"
                value={ViewPaymentData?.amount}
                disabled={true}
                placeholder={props.t(Constant.totalamountchargedQAR)}
              />
              <br />

              <div className="inlineFieldsBtn">
                <button
                  className={`cancelbtn${classAlign}`}
                  type="button"
                  onClick={() => cancelHandler()}
                >
                  {props.t("Cancel")}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}
ViewPayment.propTypes = {
  t: PropTypes.any,
  state: PropTypes.object,
  location: PropTypes.func,
}

export default withTranslation()(ViewPayment)
