import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"
import { FETCH_PAYMENTVALET_REQUEST } from "./actionType"
import { fetchPaymentValetFailure, fetchPaymentValetsSuccess } from "./action"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchpaymentvalet(action) {
  const id = sessionStorage.getItem("_id")
  console.log("companyId==>", id)
  // console.log("SearcH==>", action.payload.searchedText)
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/admin/all/company/transaction`,
      // "http://13.234.30.42:5000/admin/all/company/transaction",
      {
        // page: 1,
        page: action.payload.page,
        limit: 10,
        search: action.payload.searchedText ? action.payload.searchedText : "",
        sort: action.payload.sort,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        amount: action.payload.amount,
        paymentMethod: action.payload.paymentMethod,
        companyObjId: id,
        month: action.payload.month,
        day: action.payload.day,
        status: action.payload.status,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchPaymentValetsSuccess(response.data))
  } catch (error) {
    yield put(fetchPaymentValetFailure(error))
  }
}

function* fetchpaymentvaletSaga() {
  yield takeEvery(FETCH_PAYMENTVALET_REQUEST, fetchpaymentvalet)
}

export default fetchpaymentvaletSaga
