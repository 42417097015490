import {
  FETCH_DASHBOARD_FAILURE,
  FETCH_DASHBOARD_REQUEST,
  FETCH_DASHBOARD_SUCCESS,
} from "./actionType"

export const fetchDashboardRequest = (monthOption, yearOption) => ({
  type: FETCH_DASHBOARD_REQUEST,
  payload: { month: monthOption, year: yearOption },
})

export const fetchDashboardSuccess = dashboardData => ({
  type: FETCH_DASHBOARD_SUCCESS,
  payload: dashboardData,
})

export const fetchDashboardFailure = error => ({
  type: FETCH_DASHBOARD_FAILURE,
  payload: error,
})
