import React, { useEffect, useState, useCallback } from "react"
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api"
import PropTypes from "prop-types"

import { Marker } from "@react-google-maps/api"
import Geocode from "react-geocode"
const containerStyle = {
  width: "335px",
  height: "300px",
}

const center = {
  lat: 22.7196,
  lng: 75.8577,
}

// const position = {
//     lat: 22.7196,
//     lng: 75.8577
// }

const GoogleMapAPI = ({ googleData }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBYuOTL5QWLq9-gs7_4hy7ijNwYmr_Dn88",
  })

  const [map, setMap] = useState(null)
  const [address, setAddress] = useState("")
  const [position, setPosition] = useState({
    lat: 22.7196,
    lng: 75.8577,
  })

  const onLoad = useCallback(async map => {
    const bounds = new window.google.maps.LatLngBounds(center)
    console.log({ bounds })
    await map.fitBounds(bounds)
    // console.log(map)
    setMap(map)
  }, [])

  const onLoad2 = marker => {
    console.log("marker: ", marker, map)
  }
  const temp = e => {
    setPosition({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    })
    // console.log({ a: e.latLng.lng(), B:  })
  }

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  Geocode.setApiKey("AIzaSyBYuOTL5QWLq9-gs7_4hy7ijNwYmr_Dn88")
  const getAddress = () => {
    Geocode.fromLatLng(position.lat, position.lng).then(
      response => {
        console.log("addressBefore==>", response)

        setAddress(response.results[0].formatted_address)
        console.log("addressAfter==>", address)
        return address
      },
      error => {
        console.error(error)
      }
    )
    // return address;
  }
  console.log("addressOutside==>", address)

  useEffect(() => {
    googleData(position, address)
  }, [position, address])

  getAddress()
  console.log("latlng==>", position)
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onLoad={onLoad}
      onClick={temp}
      // onUnmount={onUnmount}
    >
      {map && (
        <div>
          <Marker onLoad={onLoad2} position={position} />
        </div>
      )}
    </GoogleMap>
  ) : (
    <></>
  )
}
GoogleMapAPI.propTypes = {
  t: PropTypes.any,
  googleData: PropTypes.func,
}

export default React.memo(GoogleMapAPI)
