import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { FaEye } from "react-icons/fa"
import { IoIosRefresh } from "react-icons/io"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Pagination from "react-responsive-pagination"
import { fetchbookingRequest } from "store/actions"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { deleteBookingRequest } from "store/actions"
import Swal from "sweetalert2"
import Searchbar from "../Searchbar/Searchbar"
import { Constant } from "pages/Resources/Constant"
// import "react-dates/initialize"
// import { DateRangePicker } from "react-dates"
// import "react-dates/lib/css/_datepicker.css"
import { fetchCompaniesFilterRequest } from "store/actions"
import { Button } from "@material-ui/core"
import ReactTooltipLite from "react-tooltip-lite"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"

const Bookings = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [focusedInput, setFocusedInput] = useState("")
  const isOutsideRange = date => date.isAfter()

  const [searchData, setSearchData] = useState("")
  const [companyOption, setCompanyOption] = useState("")
  const [sortOption, setSortOption] = useState("")
  const [filterOption, setFilterOption] = useState("")
  const [monthOption, setMonthOption] = useState("")
  const [dayOption, setDayOption] = useState("")
  const [amountOption, setAmountOption] = useState("")
  const [bookingStatusOption, setBookingStatusOption] = useState("")
  const [parkingdurationOption, setParkingDurationOption] = useState("")
  const [rotation, setRotation] = useState(0)
  const [showTooltip, setShowTooltip] = useState(false)

  const [arrayFilter, setArrayFilter] = useState([])
  var filterArr = []

  // const originalStartDate = startDate ? new Date(startDate) : ""
  // const formattedStartDate = originalStartDate
  //   ? originalStartDate.toISOString()
  //   : ""
  // console.log("formattedStartDate>>", formattedStartDate)

  // const originalEndDate = endDate ? new Date(endDate._d) : ""
  // const formattedEndDate = originalEndDate ? originalEndDate.toISOString() : ""
  // console.log("formattedEndDate>>", formattedEndDate)
  const originalStartDate = startDate ? moment(new Date(startDate)) : ""

  const formattedStartDate = originalStartDate
    ? originalStartDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    : ""
  const originalEndDate = endDate ? moment(new Date(endDate)) : ""
  const formattedEndDate = originalEndDate
    ? originalEndDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    : ""

  const handleSearchData = searchtext => {
    setSearchData(searchtext)
    setCurrentPage(1)
  }
  const handleSortChange = event => {
    setSortOption(event.target.value)
  }
  const handleCompanyChange = event => {
    setCompanyOption(event.target.value)
  }

  const handleFilterChange = event => {
    setFilterOption(event.target.value)
    // setAmountOption("") // Reset amount option when changing filters
    // setBookingStatusOption("") // Reset payment method option when changing filters
    // setParkingDurationOption("")
    // setStartDate("")
    // setEndDate("")
    // setFocusedInput("")
    // setMonthOption("")
    // setDayOption("")
    setArrayFilter([...arrayFilter, event.target.value])
  }
  var newArrayFilter = Array.from(new Set(arrayFilter))
  const handleAmountChange = event => {
    setAmountOption(event.target.value)
  }
  const handleMonthChange = event => {
    setMonthOption(event.target.value)
  }
  const handleDayChange = event => {
    setDayOption(event.target.value)
  }

  const handlebookingStatusChange = event => {
    setBookingStatusOption(event.target.value)
  }
  const handleparkingdurationChange = event => {
    setParkingDurationOption(event.target.value)
  }
  const BookingData = useSelector(state => state.fetchbookingReducer.bookings)
  console.log("BookingDATA=>", BookingData)

  const DateFormate = createdAt => {
    const date = new Date(createdAt)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    return `${day}/${month}/${year}`
    console.log(`${day}-${month}-${year} ${hours}:${minutes}:${seconds}`)
  }
  const TimeFormate = createdAt => {
    const date = new Date(createdAt)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    const formattedTime = date.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
    })
    return `${formattedTime}`
    // return `${hours}:${minutes}:${seconds}`
    // console.log(`${day}-${month}-${year} ${hours}:${minutes}:${seconds}`)
  }
  const DayFormate = bookingStartTime => {
    const date = new Date(bookingStartTime)
    const options = { weekday: "long" }
    const dayOfWeek = new Intl.DateTimeFormat("en-US", options).format(date)
    return `${dayOfWeek}`
  }
  useEffect(() => {
    dispatch(fetchCompaniesFilterRequest())
  }, [])
  const CompanyListFilter = useSelector(
    state => state.fetchcompanyfilterReducer.companiesfilter
  )
  useEffect(() => {
    dispatch(
      fetchbookingRequest(
        currentPage,
        searchData,
        sortOption,
        formattedStartDate,
        formattedEndDate,
        amountOption,
        bookingStatusOption,
        companyOption,
        monthOption,
        dayOption,
        parkingdurationOption
      )
    )
  }, [
    currentPage,
    searchData,
    sortOption,
    formattedStartDate,
    formattedEndDate,
    amountOption,
    bookingStatusOption,
    companyOption,
    startDate,
    endDate,
    monthOption,
    dayOption,
    parkingdurationOption,
  ])

  function createData(
    Book,
    Name,
    Status,
    Type,
    Car_Name,
    Start_Date,
    End_Date,
    Action
  ) {
    return { Book, Name, Status, Type, Car_Name, Start_Date, End_Date, Action }
  }

  const handleDelete = id => {
    Swal.fire({
      text: "Are you sure you want to remove this booking?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      showConfirmButton: true,
      showCancelButton: true,
    }).then(willDelete => {
      if (willDelete.isConfirmed) {
        dispatch(deleteBookingRequest(id))
        dispatch(fetchbookingRequest())
      } else {
      }
    })
  }

  const addBookingHandler = () => {
    history.push("/add_bookings")
  }
  const editBookingHandler = () => {
    history.push("/edit_bookings")
  }
  const viewBookingHandler = id => {
    history.push(`/view_bookings/${id}`)
  }

  const handleStartDateChange = date => {
    setStartDate(date)
  }
  const handleEndDateChange = date => {
    setEndDate(date)
  }
  const HandleRefreshFilter = () => {
    setRotation(rotation + 360)
    setShowTooltip(true)
    setCompanyOption("")
    // setSearchData("")
    setAmountOption("")
    setBookingStatusOption("")
    setParkingDurationOption("")
    setStartDate("")
    setEndDate("")
    setFocusedInput("")
    setFilterOption("")
    setSortOption("")
    setMonthOption("")
    setDayOption("")
    setArrayFilter([])
    setCurrentPage(1)
    setTimeout(() => {
      setShowTooltip(false)
    }, 6000)
  }

  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }

  return (
    <div className={`main-content${classAlign}`}>
      {(document.title = "Booking |  Nater")}
      <div className="page-content">
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "40%",
            }}
          >
            <h3>
              <strong>{props.t(Constant.booking)}</strong>
            </h3>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              width: "60%",
            }}
          >
            <div>
              <Searchbar
                placeholder={props.t(Constant.search)}
                searchHandler={handleSearchData}
              />
            </div>
          </div>
        </div>
        <br />

        <div className="bookingfiltermaindiv">
          <div className="companyfilterseconddiv">
            <select
              value={companyOption}
              onChange={handleCompanyChange}
              className="filterstyle"
            >
              <option value="">{props.t(Constant.company)}</option>
              {CompanyListFilter?.data &&
                CompanyListFilter?.data.map(row => (
                  <option key={row.companyObjId} value={row.companyObjId}>
                    {row.companyName}
                  </option>
                ))}
            </select>

            <select
              value={sortOption}
              onChange={handleSortChange}
              className="filterstyle"
            >
              <option value="">{props.t(Constant.sortby)}</option>
              <option value="-1">{props.t(Constant.newest)}</option>
              <option value="1">{props.t(Constant.oldest)}</option>
            </select>
            <select
              className="filterstyle"
              value={filterOption}
              onChange={handleFilterChange}
            >
              <option value="">{props.t(Constant.filterby)}</option>
              <option value="date">{props.t(Constant.date)}</option>
              <option value="month">{props.t(Constant.month)}</option>
              <option value="day">{props.t(Constant.day)}</option>
              <option value="amount">{props.t(Constant.amount)}</option>
              <option value="bookingStatus">
                {props.t(Constant.bookingstatus)}
              </option>
              <option value="parkingduration">
                {props.t(Constant.parkingduration)}
              </option>
            </select>
            {newArrayFilter.map((option, i) => {
              // })}
              if (option === "date") {
                return (
                  <div key={i}>
                    <div className="startenddatediv">
                      <DatePicker
                        selected={startDate}
                        onChange={handleStartDateChange}
                        placeholderText={props.t(Constant.startdate)}
                        className="custom-datepicker"
                      />
                      <DatePicker
                        selected={endDate}
                        onChange={handleEndDateChange}
                        placeholderText={props.t(Constant.enddate)}
                        className="custom-datepicker"
                      />
                    </div>
                  </div>
                )
              }

              // if (option === "date") {
              //   return (
              //     <div>
              //       <DatePicker
              //         selected={endDate}
              //         onChange={handleEndDateChange}
              //         placeholderText={props.t(Constant.enddate)}
              //         className="custom-datepicker"
              //       />
              //     </div>
              //   )
              // }
            })}
          </div>
          <div className="companyfilterthirddiv">
            {newArrayFilter.map((option, i) => {
              if (option === "month") {
                return (
                  <select
                    value={monthOption}
                    onChange={handleMonthChange}
                    className="filterstyle"
                    key={i}
                  >
                    <option value="">{props.t(Constant.month)}</option>
                    <option value="1">{props.t(Constant.january)}</option>
                    <option value="2">{props.t(Constant.february)}</option>
                    <option value="3">{props.t(Constant.march)}</option>
                    <option value="4">{props.t(Constant.april)}</option>
                    <option value="5">{props.t(Constant.may)}</option>
                    <option value="6">{props.t(Constant.june)}</option>
                    <option value="7">{props.t(Constant.july)}</option>
                    <option value="8">{props.t(Constant.august)}</option>
                    <option value="9">{props.t(Constant.september)}</option>
                    <option value="10">{props.t(Constant.october)}</option>
                    <option value="11">{props.t(Constant.november)}</option>
                    <option value="12">{props.t(Constant.december)}</option>
                  </select>
                )
              }

              if (option === "day") {
                return (
                  <select
                    value={dayOption}
                    onChange={handleDayChange}
                    className="filterstyle"
                    key={i}
                  >
                    <option value="">{props.t(Constant.day)}</option>
                    <option value="0">{props.t(Constant.sunday)}</option>
                    <option value="1">{props.t(Constant.monday)}</option>
                    <option value="2">{props.t(Constant.tuesday)}</option>
                    <option value="3">{props.t(Constant.wednesday)}</option>
                    <option value="4">{props.t(Constant.thursday)}</option>
                    <option value="5">{props.t(Constant.friday)}</option>
                    <option value="6">{props.t(Constant.saturday)}</option>
                  </select>
                )
              }
              if (option === "amount") {
                return (
                  <select
                    value={amountOption}
                    onChange={handleAmountChange}
                    className="filterstyle"
                    key={i}
                  >
                    <option value="">{props.t(Constant.amountrange)}</option>
                    <option value="0-50">0 - 50</option>
                    <option value="50-100">50 - 100</option>
                    <option value="100-150">100 - 150</option>
                    <option value="150">150+</option>
                  </select>
                )
              }
              if (option === "bookingStatus") {
                return (
                  <select
                    value={bookingStatusOption}
                    onChange={handlebookingStatusChange}
                    className="filterstyle"
                    key={i}
                  >
                    <option value="">{props.t(Constant.bookingstatus)}</option>
                    <option value="New">{props.t(Constant.new)}</option>
                    <option value="Running">{props.t(Constant.running)}</option>
                    <option value="Completed">
                      {props.t(Constant.completed)}
                    </option>
                  </select>
                )
              }
              if (option === "parkingduration") {
                return (
                  <select
                    value={parkingdurationOption}
                    onChange={handleparkingdurationChange}
                    className="filterstyle"
                    key={i}
                  >
                    <option value="">
                      {props.t(Constant.parkingduration)}
                    </option>
                    <option value="0-1">{props.t(Constant.onehour)}</option>
                    <option value="0-2">{props.t(Constant.twohour)}</option>
                    <option value="0-3">{props.t(Constant.threehour)}</option>
                    <option value="0-4">{props.t(Constant.fourhour)}</option>
                    <option value="0-5">{props.t(Constant.fivehour)}</option>
                    <option value="5+">{props.t(Constant.fivehourplus)}</option>
                  </select>
                )
              }
            })}
            {/* <div className="refreshButton" onClick={HandleRefreshFilter}>
              <IoIosRefresh
                className="refreshicon"
                style={{ transform: `rotate(${rotation}deg)` }}
              />
            </div> */}
          </div>

          <div className="companyfilterthirddiv">
            <div className="refreshButton" onClick={HandleRefreshFilter}>
              <IoIosRefresh
                className="refreshicon"
                style={{ transform: `rotate(${rotation}deg)` }}
              />
            </div>
          </div>
        </div>

        <br />
        {BookingData.data?.docs?.length === 0 ? (
          <div className="datanotavailablemessage">
            <div>
              <h1>{props.t("No Booking Available")}</h1>
            </div>
          </div>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.bookingid)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.username)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.companyname)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.date)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.day)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.bookingtime)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.bookingstatus)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.durationminutes)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.amountcharged)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.action)}</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {BookingData.data?.docs &&
                  BookingData.data?.docs.map(row => (
                    <TableRow
                      key={row._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" align="center">
                        {row.bookingId}
                      </TableCell>
                      <TableCell align="center">
                        {row.carOwnerObjId?.name}
                      </TableCell>
                      <TableCell align="center">
                        {row.companyObjId?.companyName
                          ? row.companyObjId?.companyName
                          : "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {row.bookingStartTime
                          ? DateFormate(row.bookingStartTime)
                          : "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {row.bookingStartTime
                          ? DayFormate(row.bookingStartTime)
                          : "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {row.bookingStartTime
                          ? TimeFormate(row.bookingStartTime)
                          : "NA"}
                      </TableCell>
                      <TableCell align="center">{row.bookingStatus}</TableCell>
                      <TableCell align="center">
                        {row.parkingDuration ? row.parkingDuration : "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {row.totalAmount ? row.totalAmount : "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <div>
                            <span onClick={() => viewBookingHandler(row._id)}>
                              {" "}
                              <FaEye className="userListView" />
                            </span>{" "}
                          </div>
                        }
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {BookingData.data?.docs?.length === 0 ? (
          ""
        ) : (
          <div style={{ marginTop: 10 }}>
            <Pagination
              style={{ color: "#5B4FB1" }}
              current={currentPage}
              maxWidth={5}
              total={BookingData.data?.totalPages}
              onPageChange={n => {
                setCurrentPage(n)
                // dispatch(fetchbookingRequest(n))
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
Bookings.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Bookings)
