import {
  EDIT_COMPANY_FAILURE,
  EDIT_COMPANY_REQUEST,
  EDIT_COMPANY_SUCCESS,
} from "./actionType"

export const editCompanyRequest = formData => ({
  type: EDIT_COMPANY_REQUEST,
  payload: formData,
})

export const editCompanySuccess = () => ({
  type: EDIT_COMPANY_SUCCESS,
})

export const editCompanyFailure = error => ({
  type: EDIT_COMPANY_FAILURE,
  payload: error,
})
