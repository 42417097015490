import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import "./index.css"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"
import store from "./store"
// import store from "app/store"
import "bootstrap/dist/css/bootstrap.css"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { HashRouter } from "react-router-dom"

// pick a date util library
import MomentUtils from "@date-io/moment"
import DateFnsUtils from "@date-io/date-fns"
import LuxonUtils from "@date-io/luxon"
import Router from "./routes/Router"

const app = (
  <Provider store={store}>
    {/* <HashRouter> */}
    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
    <App />
    {/* </MuiPickersUtilsProvider> */}
    {/* </HashRouter> */}
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
