import { React, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Link, useHistory } from "react-router-dom"
import { FaEdit, FaEye } from "react-icons/fa"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchUserRequest } from "store/actions"
import { Constant } from "pages/Resources/Constant"
import Searchbar from "../Searchbar/Searchbar"
import Pagination from "react-responsive-pagination"
import { IoIosRefresh } from "react-icons/io"

const userList = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)

  const [searchData, setSearchData] = useState("")
  const [sortOption, setSortOption] = useState("")
  const [filterOption, setFilterOption] = useState("")
  const [bookingOption, setBookingOption] = useState("")
  const [carOption, setCarOption] = useState("")
  const [rotation, setRotation] = useState(0)
  const [arrayFilter, setArrayFilter] = useState([])

  const handleSearchData = searchtext => {
    console.log("searchbarValue==>", searchtext)
    setSearchData(searchtext)
    setCurrentPage(1)
  }
  const handleSortChange = event => {
    setSortOption(event.target.value)
  }

  const handleFilterChange = event => {
    setFilterOption(event.target.value)
    // setBookingOption("")
    // setCarOption("")
    setArrayFilter([...arrayFilter, event.target.value])
  }
  var newArrayFilter = Array.from(new Set(arrayFilter))

  const handleBookingChange = event => {
    setBookingOption(event.target.value)
  }
  const handleCarChange = event => {
    setCarOption(event.target.value)
  }

  const UserData = useSelector(state => state.fetchuserReducer.users)
  console.log("UserDATA=>", UserData)

  useEffect(() => {
    dispatch(
      fetchUserRequest(
        currentPage,
        searchData,
        sortOption,
        bookingOption,
        carOption
      )
    )
  }, [currentPage, searchData, sortOption, bookingOption, carOption])

  function createData(name, Email, Phone_Number, Country, Status, Action) {
    return { name, Email, Phone_Number, Status, Country, Action }
  }

  // const createdAt = "2023-04-24T09:08:27.087Z" // Your createdAt string
  const DateFormate = createdAt => {
    const date = new Date(createdAt)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    return `${day}/${month}/${year}`
    console.log(`${day}-${month}-${year} ${hours}:${minutes}:${seconds}`)
  }

  const rows = [
    createData(
      "Aayush",
      "aayush00@gmail.com",
      9658741265,
      "Active",
      "India",
      <div className="action"></div>
    ),
    createData(
      "Rohit",
      "aayush00@gmail.com",
      9658741265,
      "Active",
      "India",
      4.3
    ),
    createData(
      "Aditya",
      "aayush00@gmail.com",
      9658741265,
      "Active",
      "India",
      6.0
    ),
    createData(
      "Neha",
      "aayush00@gmail.com",
      9658741265,
      "Active",
      "India",
      4.3
    ),
    createData(
      "Shubham",
      "aayush00@gmail.com",
      9658741265,
      "Active",
      "India",
      3.9
    ),
  ]

  const viewUserHandler = id => {
    history.push(`/view_user/${id}`)
  }
  const HandleRefreshFilter = () => {
    setRotation(rotation + 360)
    setFilterOption("")
    setSortOption("")
    setBookingOption("")
    setCarOption("")
    setCurrentPage(1)
    setArrayFilter([])
  }

  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  return (
    <div className={`main-content${classAlign}`}>
      {(document.title = "User | Nater")}
      <div className="page-content">
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "40%",
            }}
          >
            <h3>
              <strong>{props.t(Constant.user)}</strong>
            </h3>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              width: "60%",
            }}
          >
            <div>
              <Searchbar
                placeholder={props.t(Constant.search)}
                searchHandler={handleSearchData}
              />
            </div>
          </div>
        </div>
        <br />
        <div className="bookingfiltermaindiv">
          {/* <div className="companyfiltermaindiv"> */}
          <div className="companyfilterseconddiv">
            {/* <label style={{ marginRight: "10px" }}>
              Sort By: */}
            <select
              value={sortOption}
              onChange={handleSortChange}
              className="filterstyle"
            >
              <option value="">{props.t(Constant.sortby)}</option>
              <option value="-1">{props.t(Constant.newest)}</option>
              <option value="1">{props.t(Constant.oldest)}</option>
            </select>
            {/* </label> */}
            {/* <label style={{ marginRight: "10px" }}>
              Filter By: */}
            <select
              value={filterOption}
              onChange={handleFilterChange}
              className="filterstyle"
            >
              <option value="">{props.t(Constant.filterby)}</option>
              <option value="booking">{props.t(Constant.booking)}</option>
              <option value="car">{props.t(Constant.car)}</option>
            </select>
            {/* </label> */}
          </div>
          <div className="companyfilterthirddiv">
            {newArrayFilter.map((option, i) => {
              console.log("option", option)
              // })}
              if (option === "booking") {
                return (
                  <select
                    value={bookingOption}
                    onChange={handleBookingChange}
                    className="filterstyle"
                    key={i}
                  >
                    <option value="">{props.t(Constant.bookingrange)}</option>
                    <option value="0-10">0-10</option>
                    <option value="11-20">11-20</option>
                    <option value="21-30">21-30</option>
                    <option value="30+">30+</option>
                  </select>
                )
              }
              if (option === "car") {
                return (
                  <select
                    value={carOption}
                    onChange={handleCarChange}
                    className="filterstyle"
                    key={i}
                  >
                    <option value="">{props.t(Constant.carrange)}</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5+">5+</option>
                  </select>
                )
              }
            })}
            {/* {filterOption === "booking" && (
            
              <select
                value={bookingOption}
                onChange={handleBookingChange}
                className="filterstyle"
              >
                <option value="">{props.t(Constant.bookingrange)}</option>
                <option value="0-10">0-10</option>
                <option value="11-20">11-20</option>
                <option value="21-30">21-30</option>
                <option value="30+">30+</option>
              </select>
              // </label>
            )}
            {filterOption === "car" && (
              // <label style={{ marginRight: "10px" }}>
              //   Amount Range:
              <select
                value={carOption}
                onChange={handleCarChange}
                className="filterstyle"
              >
                <option value="">{props.t(Constant.carrange)}</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5+">5+</option>
              </select>
              // </label>
            )} */}
            {/* <span className="refreshButton" onClick={HandleRefreshFilter}>
              <IoIosRefresh
                className="refreshicon"
                style={{ transform: `rotate(${rotation}deg)` }}
              />
            </span> */}
          </div>
          <div className="companyfilterthirddiv">
            <span className="refreshButton" onClick={HandleRefreshFilter}>
              <IoIosRefresh
                className="refreshicon"
                style={{ transform: `rotate(${rotation}deg)` }}
              />
            </span>
          </div>
        </div>
        <br />
        {UserData?.data?.length === 0 ? (
          <div className="datanotavailablemessage">
            <div>
              <h1>{props.t("No User Available")}</h1>
            </div>
          </div>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.username)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.phonenumber)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.totalbooking)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.totalcar)}</strong>
                  </TableCell>
                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.registrationdate)}</strong>
                  </TableCell>

                  <TableCell className="tableHeaderCell" align="center">
                    <strong>{props.t(Constant.action)}</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {UserData?.data &&
                  UserData?.data.map(row => (
                    <TableRow
                      key={row._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" align="center">
                        {row.name}
                      </TableCell>
                      <TableCell align="center">{row.phone}</TableCell>
                      <TableCell align="center">{row.totalBookings}</TableCell>
                      <TableCell align="center">{row.totalCars}</TableCell>
                      <TableCell align="center">
                        {DateFormate(row.createdAt)}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <div>
                            <span onClick={() => viewUserHandler(row._id)}>
                              {" "}
                              <FaEye className="userListView" />
                            </span>{" "}
                          </div>
                        }
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {UserData?.data?.length === 0 ? (
          ""
        ) : (
          <div style={{ marginTop: 10 }}>
            <Pagination
              current={currentPage}
              maxWidth={5}
              total={UserData.totalPages}
              onPageChange={n => {
                setCurrentPage(n)
                // dispatch(fetchUserRequest(n))
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
userList.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(userList)
