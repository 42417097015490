import React from "react"
import "../../../../App.css"
import PropTypes from "prop-types"
import "bootstrap/dist/css/bootstrap.min.css"
import { Input, Form } from "reactstrap"
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useState } from "react"

const AddBookings = props => {
  const history = useHistory()

  const cancelHandler = () => {
    history.push("/bookings")
  }

  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  return (
    <div className={`main-content${classAlign}`}>
      {(document.title = "Parent | AAC")}
      <div className="page-content">
        <div className="registerValet" style={{ marginLeft: "15px" }}>
          <div id="formValet">
            <h5>{props.t("Add Booking")}</h5>
            <br />
            <Form>
              <div className="passwordValet">
                <Input
                  className="textfieldValet"
                  type="text"
                  name=""
                  //   onChange={e => setName(e.target.value)}
                  //   value={name}
                  placeholder={props.t("Name")}
                />
              </div>
              {nameError && (
                <p style={{ color: "red", marginBottom: "0px" }}>
                  {props.t(nameError)}
                </p>
              )}

              <br />
              <div className="passwordValet">
                <Input
                  className="textfieldValet"
                  type="text"
                  name=""
                  //   onChange={e => setEmail(e.target.value)}
                  //   value={email}
                  placeholder={props.t("Status")}
                />
              </div>
              {emailError && (
                <p style={{ color: "red", marginBottom: "0px" }}>
                  {props.t(emailError)}
                </p>
              )}

              <br />
              <Input
                className="textfieldValet"
                type="text"
                name=""
                // onChange={e => setDesignation(e.target.value)}
                // value={designation}
                placeholder={props.t("Type")}
              />
              {designationError && (
                <p style={{ color: "red", marginBottom: "0px" }}>
                  {props.t(designationError)}
                </p>
              )}
              <br />
              <div className="passwordValet">
                <Input
                  className="textfieldValet"
                  type="text"
                  name=""
                  //   onChange={e => setName(e.target.value)}
                  //   value={name}
                  placeholder={props.t("Car Name")}
                />
              </div>
              {nameError && (
                <p style={{ color: "red", marginBottom: "0px" }}>
                  {props.t(nameError)}
                </p>
              )}

              <br />
              <div className="passwordValet">
                <Input
                  className="textfieldValet"
                  type="text"
                  name=""
                  //   onChange={e => setName(e.target.value)}
                  //   value={name}
                  placeholder={props.t("Start Date")}
                />
              </div>
              {nameError && (
                <p style={{ color: "red", marginBottom: "0px" }}>
                  {props.t(nameError)}
                </p>
              )}

              <br />

              <div className="passwordValet">
                <Input
                  className="textfieldValet"
                  type="text"
                  name=""
                  //   onChange={e => setName(e.target.value)}
                  //   value={name}
                  placeholder={props.t("End Date")}
                />
              </div>
              {nameError && (
                <p style={{ color: "red", marginBottom: "0px" }}>
                  {props.t(nameError)}
                </p>
              )}

              <br />

              <div className="inlineFieldsBtn">
                <button
                  className={`cancelbtn${classAlign}`}
                  type="button"
                  onClick={() => cancelHandler()}
                >
                  {props.t("Cancel")}
                </button>
                <button
                  className={`submitbtn${classAlign}`}
                  type="button"
                  //   onClick={() => submitHandler()}
                >
                  {props.t("Submit")}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}
AddBookings.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(AddBookings)
