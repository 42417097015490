import {
  ADD_COMPANY_REQUEST,
  ADD_COMPANY_FAILURE,
  ADD_COMPANY_SUCCESS,
} from "./actionTypes"

const initialState = {
  addcompanydatasaga: [],
  loading: false,
  error: null,
  companycode: "",
}

const addCompanyReducer = (state = initialState, action) => {
  console.log("Accccction=>", action)
  switch (action.type) {
    case ADD_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case ADD_COMPANY_SUCCESS:
      return {
        ...state,
        addcompanydatasaga: action.payload.data,
        loading: false,
        error: null,
        companycode: "200",
      }
    case ADD_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default addCompanyReducer
