import {
  FETCH_VIEW_PAYMENT_FAILURE,
  FETCH_VIEW_PAYMENT_REQUEST,
  FETCH_VIEW_PAYMENT_SUCCESS,
} from "./actionType"

export const fetchViewPaymentRequest = id => (
  console.log("viewbid==>", id),
  {
    type: FETCH_VIEW_PAYMENT_REQUEST,
    payload: id,
  }
)

export const fetchViewPaymentSuccess = Viewpayments => ({
  type: FETCH_VIEW_PAYMENT_SUCCESS,
  payload: Viewpayments,
})

export const fetchViewPaymentFailure = error => ({
  type: FETCH_VIEW_PAYMENT_FAILURE,
  payload: error,
})
