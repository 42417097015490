import {
  DELETE_VALET_FAILURE,
  DELETE_VALET_REQUEST,
  DELETE_VALET_SUCCESS,
} from "./actionType"

const initialState = {
  loading: false,
  error: null,
}

const deletevaletReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_VALET_REQUEST:
      return { ...state, loading: true, error: null }
    case DELETE_VALET_SUCCESS:
      return { ...state, loading: false, error: null }
    case DELETE_VALET_FAILURE:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export default deletevaletReducer
