import { React, useState, useMemo } from "react"
import "../../../../App.css"
import PropTypes from "prop-types"
import "bootstrap/dist/css/bootstrap.min.css"
import { Label } from "reactstrap"
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { addCompanyRequest } from "store/actions"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import SearchInput from "../GooglePlaces/SearchInput"
import { Constant } from "pages/Resources/Constant"
import { useEffect } from "react"
import { RotatingLines } from "react-loader-spinner"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/bootstrap.css"

const AddCustomer = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [company_pic, setCompanyLogo] = useState("")
  const [company_profile, setCompanyProfile] = useState("")
  const [loader, setLoader] = useState(false)
  const [phone, setPhone] = useState("")
  const [phoneError, setPhoneError] = useState("")

  const { loading, error, addcompanydatasaga, companycode } = useSelector(
    state => state.addCompanyReducer
  )

  // var StatusCode = companycode
  // useMemo(() => {
  //   console.log(companycode, typeof companycode, "ccoma")
  //   if (StatusCode === "200") {
  //     setTimeout(() => {
  //       history.push("/company")
  //     }, 2000)
  //     StatusCode = ""
  //   }
  // }, [StatusCode])
  console.log("addcompanydatasaga=>", addcompanydatasaga)
  console.log("eoorr=<", error)
  console.log("companycode", companycode)
  console.log("phonee", phone)
  useEffect(() => {
    if (addcompanydatasaga) {
      console.log("addcompanydatasaga1:", addcompanydatasaga)
      // Process the addCsaga response as needed
    }
  }, [addcompanydatasaga])

  const [place, setPlace] = useState(null)
  const [latlng, setLatLng] = useState({ lat: "", lng: "" })
  const [searchInputError, setSearchInputError] = useState("")
  const [profileError, setProfileError] = useState("")
  const [avatarError, setAvatarError] = useState("")
  const handlePlaceChanged = place => {
    console.log("PLACE===<<", place)
    setPlace(place.formatted_address)
    setLatLng({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    })
    setSearchInputError("")
  }
  console.log("place==>", place, "@@@@@", latlng)

  const initialValues = {
    companyName: "",
    email: "",
    // phone: "",
    password: "",
    companyId: "",
    bookingFees: "",
  }

  const validationSchema = Yup.object({
    companyName: Yup.string().required(props.t("Name is Required")),
    email: Yup.string()
      .matches(
        /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i,
        props.t("Invalid email")
      )
      .required(props.t("Email is required")),
    // phone: Yup.string().required(props.t("Phone Number is Required")),

    companyId: Yup.string()
      .max(15, props.t("Must be 15 characters or less"))
      .required(props.t("Company ID is Required")),
    password: Yup.string()
      .required(props.t("Password is Required"))
      .min(8, props.t("Password must have atleast 8 Characters"))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        props.t(
          "Password must contain at least one letter, one number, and one special character"
        )
      ),
    bookingFees: Yup.string().required(props.t("Booking Fees is Required")),
    // address: Yup.string().required(props.t("Address is Required")),
    // profile: Yup.mixed().required(props.t("Profile is Required")),
    // avatar: Yup.mixed().required(props.t("Avatar is Required")),
    // address: Yup.string().required(props.t("Address is Required"))
  })
  // useEffect(() => {
  //   console.log("entered>>>")
  //   if (
  //     // &&
  //     // loading == false &&
  //     sagastatus === true
  //     // companycode === 200
  //   ) {
  //     console.log("before toast>>>")
  //     toast.success(props.t("Company registered successfully"))
  //     setSagastatus(false)
  //     setTimeout(() => {
  //       history.push("/company")
  //     }, 2000)
  //   }
  // }, [addcompanydatasaga.status])

  const addCompanyPic = e => {
    setCompanyLogo(e.target.files[0])
    setAvatarError("")
  }
  console.log("Company pic set file-->", company_pic)

  const addCompanyProfile = e => {
    setCompanyProfile(e.target.files[0])
    setProfileError("")
  }
  const addPhoneNumber = value => {
    setPhone(value)
    setPhoneError("")
  }
  const handleSubmit = values => {
    console.log("values-->", values)
    if (!phone) {
      setPhoneError(props.t("Phone Number is Required"))
      return
    } else {
      setPhoneError("")
    }
    if (!place) {
      setSearchInputError(props.t("Address is Required"))
      return
    } else {
      setSearchInputError("")
    }

    if (!company_profile) {
      setProfileError(props.t("Profile is Required"))
      return
    } else {
      setProfileError("")
    }

    if (!company_pic) {
      setAvatarError(props.t("Document is Required"))
      return
    } else {
      setAvatarError("")
    }
    setLoader(true)
    const formData = new FormData()
    const use_obj = { ...values, document: values.company_pic }
    console.log(use_obj)

    formData.append("companyName", values.companyName.trimStart())
    formData.append("email", values.email)
    formData.append("phone", phone)
    formData.append("address", place)
    formData.append("companyId", values.companyId.trimStart())
    formData.append("password", values.password)
    formData.append("bookingFees", values.bookingFees)
    formData.append("profile", company_profile)
    formData.append("avatar", company_pic)
    formData.append("latitude", latlng.lat)
    formData.append("longitude", latlng.lng)
    dispatch(
      addCompanyRequest(formData, () => {
        console.log("Hogaygayagya")
        history.push("/company")
      })
    )
    setLoader(false)
    // setTimeout(() => {
    //   history.push("/company")
    // }, 2000)
    // else toast.error(addCompanydata.error)

    // console.log("formdata-->", formData, "$$$$", company_pic)
  }

  const cancelHandler = () => {
    history.push("/company")
  }

  useEffect(() => {
    if (addcompanydatasaga) {
      console.log("addcompanydatasaga2:", addcompanydatasaga)
      // Process the addCsaga response as needed
    }
  }, [addcompanydatasaga])
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  return (
    <div className={`main-content${classAlign}`}>
      {(document.title = "Company | Nater")}
      <div className="page-content">
        <ToastContainer />
        <div
          style={{
            position: "fixed",
            left: "50%",
            top: "50%",
            zIndex: "999",
          }}
        >
          <RotatingLines
            strokeColor="#0a6ac8"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={loader}
          />
        </div>
        <div className="registerValet" style={{ marginLeft: "15px" }}>
          <div id="formValet">
            <h5>
              <strong>{props.t(Constant.addcompany)}</strong>
            </h5>
            <br />
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ touched }) => (
                <Form>
                  <div className="passwordValet">
                    <Label className={`label${classAlign}`}>
                      {props.t(Constant.name)}
                    </Label>
                    <Field
                      className="form-control"
                      style={{ backgroundColor: "#f1f1f9" }}
                      type="text"
                      name="companyName"
                      placeholder={props.t(Constant.name)}
                    />
                    <p className="textdanger1">
                      <ErrorMessage name={props.t("companyName")} />
                    </p>
                  </div>

                  <br />
                  <div className="passwordValet">
                    <Label className={`label${classAlign}`}>
                      {props.t(Constant.email)}
                    </Label>
                    <Field
                      className="form-control"
                      style={{ backgroundColor: "#f1f1f9" }}
                      type="text"
                      name="email"
                      placeholder={props.t(Constant.email)}
                    />

                    <p className="textdanger1">
                      <ErrorMessage name={props.t("email")} />
                    </p>
                  </div>

                  <br />
                  {/* <div className="passwordValet">
                    <Label className={`label${classAlign}`}>
                      {props.t(Constant.phonenumber)}
                    </Label>
                    <Field
                      className="form-control"
                      style={{ backgroundColor: "#f1f1f9" }}
                      type="text"
                      name="phone"
                      placeholder={props.t(Constant.phonenumber)}
                    />
                    <p className="textdanger1">
                      <ErrorMessage name={props.t("phone")} />
                    </p>
                  </div> */}
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.phonenumber)}
                  </Label>
                  <br />
                  <div className="passwordValet">
                    <PhoneInput
                      country={"eg"}
                      enableSearch={true}
                      value={phone}
                      onChange={addPhoneNumber}
                    />
                    <p className="textdanger1">{props.t(phoneError)}</p>
                  </div>
                  <br />
                  <div className="passwordValet">
                    <Label className={`label${classAlign}`}>
                      {props.t(Constant.password)}
                    </Label>
                    <Field
                      className="form-control"
                      style={{ backgroundColor: "#f1f1f9" }}
                      type="text"
                      name="password"
                      placeholder={props.t(Constant.password)}
                    />
                    <p className="textdanger1">
                      {/* <ErrorMessage name={props.t("password")} /> */}
                      <ErrorMessage name="password" />
                    </p>
                  </div>

                  <br />
                  <div className="passwordValet">
                    <Label className={`label${classAlign}`}>
                      {props.t(Constant.companyid)}
                    </Label>
                    <Field
                      className="form-control"
                      style={{ backgroundColor: "#f1f1f9" }}
                      type="text"
                      name="companyId"
                      placeholder={props.t(Constant.companyid)}
                    />
                    <p className="textdanger1">
                      <ErrorMessage name={props.t("companyId")} />
                    </p>
                  </div>
                  <br />
                  <div className="passwordValet">
                    <Label className={`label${classAlign}`}>
                      {props.t(Constant.bookingfees)}
                    </Label>
                    <Field
                      className="form-control"
                      style={{ backgroundColor: "#f1f1f9" }}
                      type="text"
                      name="bookingFees"
                      placeholder={props.t(Constant.bookingfees)}
                    />
                    <p className="textdanger1">
                      <ErrorMessage name={props.t("bookingFees")} />
                    </p>
                  </div>

                  <br />
                  <div className="passwordValet">
                    <Label className={`label${classAlign}`}>
                      {props.t(Constant.address)}
                    </Label>

                    <SearchInput
                      onPlaceChanged={handlePlaceChanged}
                      value={place}
                      placeholder={props.t("Address")}
                      name="address"
                    />
                    <p className="textdanger1">{props.t(searchInputError)}</p>
                  </div>
                  <br />

                  <div className="passwordValet">
                    <Label className={`label${classAlign}`}>
                      {props.t(Constant.companyprofile)}
                    </Label>
                  </div>
                  <br />
                  <div className="passwordValet">
                    <input
                      className="form-control"
                      type="file"
                      name="profile"
                      onChange={addCompanyProfile}
                    />
                    <p className="textdanger1">{props.t(profileError)}</p>
                  </div>
                  <br />
                  <div className="passwordValet">
                    <Label className={`label${classAlign}`}>
                      {props.t(Constant.companydocument)}
                    </Label>
                  </div>
                  <br />
                  <div className="passwordValet">
                    <input
                      className="form-control"
                      type="file"
                      name="avatar"
                      onChange={addCompanyPic}
                    />
                    <p className="textdanger1">{props.t(avatarError)}</p>
                  </div>

                  <br />

                  <div className="inlineFieldsBtn">
                    <button
                      className={`cancelbtn${classAlign}`}
                      type="button"
                      onClick={() => cancelHandler()}
                    >
                      {props.t(Constant.cancel)}
                    </button>
                    <button className={`submitbtn${classAlign}`} type="submit">
                      {props.t(Constant.submit)}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}
AddCustomer.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(AddCustomer)
