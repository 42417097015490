import {
  ADD_COMPANYPROFILE_FAILURE,
  ADD_COMPANYPROFILE_REQUEST,
  ADD_COMPANYPROFILE_SUCCESS,
} from "./actionType"

export const addCompanyprofileRequest = profileData => ({
  type: ADD_COMPANYPROFILE_REQUEST,
  payload: profileData,
})

export const addCompanyprofileSuccess = () => ({
  type: ADD_COMPANYPROFILE_SUCCESS,
})

export const addCompanyprofileFailure = error => ({
  type: ADD_COMPANYPROFILE_FAILURE,
  payload: error,
})
