import { React, useState, useEffect } from "react"
import moment from "moment"
// import schoolDataObject from "../Controllers/SchoolController"
import Select from "react-select"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
// import "./Chart.css"
import { Button } from "reactstrap"
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  Tooltip,
  ResponsiveContainer,
} from "recharts"
import { Constant } from "pages/Resources/Constant"
import { useDispatch } from "react-redux"
import { fetchDashboardvaletRequest } from "store/actions"
import { useSelector } from "react-redux"
import { ToastContainer, toast } from "react-toastify"
import { BiCheck } from "react-icons/bi"
// defaults.global.tooltips.enabled = true
// defaults.global.legend.position = "bottom"

function BarchartValetCompany(props) {
  const [dataType, setDataType] = useState("booking")
  const [timePeriod, setTimePeriod] = useState("week")
  const [barGraphData, setBarGraphData] = useState([])
  const [monthOption, setMonthOption] = useState("")
  const [yearOption, setYearOption] = useState("")

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchDashboardvaletRequest(monthOption, yearOption))
  }, [monthOption, yearOption])

  const DashboardValetBarchatData = useSelector(
    state => state.fetchdashboardvaletReducer?.dashboardValetData.data
  )
  console.log("DashboardValetBarchatData=>", DashboardValetBarchatData)
  const handleDataTypeChange = event => {
    setDataType(event.target.value)
    setTimePeriod("week")
    setMonthOption("")
    setYearOption("")
  }
  var presentMonth = new Date().getMonth()
  console.log("presentMonth", presentMonth)
  const handleTimePeriodChange = event => {
    setTimePeriod(event.target.value)
    // setMonthOption(presentMonth)
    setMonthOption(0)
    setYearOption(2023)
  }
  const handleMonthChange = event => {
    setMonthOption(parseInt(event.target.value))
  }
  const handleYearChange = event => {
    setYearOption(parseInt(event.target.value))
  }
  // useEffect(() => {
  //   // Retrieve selected values from local storage on component mount
  //   const storedDataType = localStorage.getItem("selectedDataType")
  //   const storedTimePeriod = localStorage.getItem("selectedTimePeriod")

  //   if (storedDataType && storedTimePeriod) {
  //     setDataType(storedDataType)
  //     setTimePeriod(storedTimePeriod)
  //   }
  // }, [])
  // useEffect(() => {
  //   // Save selected values to local storage whenever they change
  //   localStorage.setItem("selectedDataType", dataType)
  //   localStorage.setItem("selectedTimePeriod", timePeriod)
  // }, [dataType, timePeriod])
  const HandleApplyButton = () => {
    fetchData(dataType, timePeriod, monthOption, yearOption)
  }
  // useEffect(() => {
  //   // Fetch the data based on the selected options (dataType and timePeriod)
  //   fetchData(dataType, timePeriod)
  // }, [dataType, timePeriod])

  useEffect(() => {
    if (!barGraphData?.length) {
      // Set initial graphData based on booking week data
      setBarGraphData(
        DashboardValetBarchatData?.bookingGraphData?.weekBookingArr || []
      ),
        setTimePeriod("week"),
        setDataType("booking")
    }
  }, [barGraphData])
  const CurrentYear = new Date().getFullYear()
  const targetYear = 2023
  const yearsArray = []

  for (let year = CurrentYear; year >= targetYear; year--) {
    yearsArray.push(year)
  }
  const reversedArray = yearsArray.reverse()
  console.log("reversedArray", reversedArray)

  let BookingYearGraphData =
    DashboardValetBarchatData?.bookingGraphData.yearlyGraphData

  const newBookingYearArr =
    BookingYearGraphData &&
    BookingYearGraphData.map((item, index) => {
      const monthOfYear = [
        "Jan.",
        "Feb.",
        "Mar.",
        "Apr.",
        "May",
        "Jun.",
        "Jul.",
        "Aug.",
        "Sep.",
        "Oct.",
        "Nov.",
        "Dec.",
      ]
      return { ...item, month: monthOfYear[index] }
    })
  let paymentYearGraphData =
    DashboardValetBarchatData?.paymentGraphData.yearlyPaymentData

  const newPaymentYearArr =
    paymentYearGraphData &&
    paymentYearGraphData.map((item, index) => {
      const monthOfYear = [
        "Jan.",
        "Feb.",
        "Mar.",
        "Apr.",
        "May",
        "Jun.",
        "Jul.",
        "Aug.",
        "Sep.",
        "Oct.",
        "Nov.",
        "Dec.",
      ]
      return { ...item, month: monthOfYear[index] }
    })

  let parkingdurationData = DashboardValetBarchatData?.parkingDurationGraph

  const newparkingdurationDataArr =
    parkingdurationData &&
    parkingdurationData.map((item, index) => {
      const hoursdata = [
        "1 Hour",
        "2 Hours",
        "3 Hours",
        "4 Hours",
        "5 Hours",
        "5+ Hours",
      ]
      return { ...item, hour: hoursdata[index] }
    })

  const fetchData = (dataType, timePeriod) => {
    // Perform API call or data processing based on the selected options
    let data = []
    if (dataType === "booking") {
      if (timePeriod === "year") {
        // data = DashboardValetBarchatData?.bookingGraphData.yearlyGraphData
        data = newBookingYearArr
        if (!data || data.length === 0) {
          toast.error(props.t("Booking details not available for this year."))
          // return
        }
      } else if (timePeriod === "month") {
        data = DashboardValetBarchatData?.bookingGraphData.monthBookingArr
        if (!data || data.length === 0) {
          toast.error(props.t("Booking details not available for this month."))
          // return
        }
      } else if (timePeriod === "week") {
        data = DashboardValetBarchatData?.bookingGraphData.weekBookingArr
      }
    } else if (dataType === "payment") {
      if (timePeriod === "year") {
        // data = DashboardValetBarchatData?.paymentGraphData.yearlyPaymentData
        data = newPaymentYearArr
        if (!data || data.length === 0) {
          toast.error(props.t("Payment details not available for this year."))
          // return
        }
      } else if (timePeriod === "month") {
        data = DashboardValetBarchatData?.paymentGraphData.monthPayArr
        if (!data || data.length === 0) {
          toast.error(props.t("Payment details not available for this month."))
          // return
        }
      } else if (timePeriod === "week") {
        data = DashboardValetBarchatData?.paymentGraphData.weekPayArr
      }
    }
    if (dataType === "parkingduration") {
      data = newparkingdurationDataArr
    }
    setBarGraphData(data)
  }
  console.log("bargraphdata=>", barGraphData)

  // recharts data(new bar graph)
  const data = [
    { name: "Page A", uv: 4000, pv: 2400, amt: 2400 },
    { name: "Page B", uv: 3000, pv: 1398, amt: 2210 },
    { name: "Page C", uv: 2000, pv: 9800, amt: 2290 },
    { name: "Page D", uv: 2780, pv: 3908, amt: 2000 },
    // more data...
  ]
  return (
    <div>
      <ToastContainer />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "15px",
          alignItems: "center",

          //  justifyContent: "space-between"
        }}
      >
        <select id="dataType" value={dataType} onChange={handleDataTypeChange}>
          <option value="booking">{props.t(Constant.booking)}</option>
          <option value="payment">{props.t(Constant.payment)}</option>
          <option value="parkingduration">
            {props.t(Constant.parkingduration)}
          </option>
        </select>

        {dataType === "parkingduration" ? (
          ""
        ) : (
          <select
            id="timePeriod"
            value={timePeriod}
            onChange={handleTimePeriodChange}
          >
            <option value="year">{props.t(Constant.year)}</option>
            <option value="month">{props.t(Constant.month)}</option>
            <option value="week">{props.t(Constant.week)}</option>
          </select>
        )}
        {timePeriod === "month" && (
          // <label style={{ marginRight: "10px" }}>
          //   Amount Range:
          <select
            id="monthPeriod"
            value={monthOption}
            onChange={handleMonthChange}
            // className="filterstyle"
          >
            {/* <option value={new Date().getMonth()}>
              {props.t(Constant.currentmonth)}
            </option> */}
            <option value="0">{props.t(Constant.january)}</option>
            <option value="1">{props.t(Constant.february)}</option>
            <option value="2">{props.t(Constant.march)}</option>
            <option value="3">{props.t(Constant.april)}</option>
            <option value="4">{props.t(Constant.may)}</option>
            <option value="5">{props.t(Constant.june)}</option>
            <option value="6">{props.t(Constant.july)}</option>
            <option value="7">{props.t(Constant.august)}</option>
            <option value="8">{props.t(Constant.september)}</option>
            <option value="9">{props.t(Constant.october)}</option>
            <option value="10">{props.t(Constant.november)}</option>
            <option value="11">{props.t(Constant.december)}</option>
          </select>
          // </label>
        )}
        {timePeriod === "year" && (
          // <label style={{ marginRight: "10px" }}>
          //   Amount Range:
          <select
            id="monthPeriod"
            value={yearOption}
            onChange={handleYearChange}
            // className="filterstyle"
          >
            {/* <option value="">{props.t(Constant.currentyear)}</option> */}
            {reversedArray &&
              reversedArray.map(row => (
                <option key={row} value={row}>
                  {row}
                </option>
              ))}
          </select>
          // </label>
        )}
        <div
          onClick={HandleApplyButton}
          className="backButton"
          style={{ cursor: "pointer" }}
        >
          {/* <h6 className="goButton">{props.t(Constant.go)}</h6> */}
          <BiCheck className="backicon" />
        </div>
      </div>

      <br />
      <br />
      {/* {dataType === "parkingduration" ? (
        <div
          // className="recharts-default-legend"
          style={{
            border: "2px solid white",
            borderRadius: "10px",
            zIndex: "-moz-initial",
            backgroundColor: "white",
            // -webkit-box-shadow: "0px 0px 10px rgba(0, 0, 0, 0.25)"
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
            height: "300px",
          }}
        >
          <ResponsiveContainer width="95%" height={300}>
            <LineChart
              
              data={barGraphData}
              
            >
             
              <XAxis dataKey="hour" />
              <YAxis dataKey="totalCars" />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="totalCars"
               
                stroke="#002BC0"
                activeDot={{ r: 8 }}
                strokeWidth={3}
              />
              
            </LineChart>
          </ResponsiveContainer>
         
        </div>
      ) : (
        <div
          style={{
            border: "2px solid white",
            borderRadius: "10px",
            zIndex: "-moz-initial",
            backgroundColor: "white",
            // -webkit-box-shadow: "0px 0px 10px rgba(0, 0, 0, 0.25)"
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
            height: "300px",
          }}
        >
          <ResponsiveContainer width="95%" height={300}>
            <LineChart data={barGraphData}>
              <XAxis dataKey={timePeriod === "year" ? "month" : "date"} />
              <YAxis dataKey="total" />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="total"
               
                stroke="#002BC0"
                activeDot={{ r: 8 }}
                strokeWidth={3}
                
              />
             
            </LineChart>
          </ResponsiveContainer>
        </div>
      )} */}
      {dataType === "parkingduration" ? (
        <div
          style={{
            border: "2px solid white",
            borderRadius: "10px",
            zIndex: "-moz-initial",
            backgroundColor: "white",
            // -webkit-box-shadow: "0px 0px 10px rgba(0, 0, 0, 0.25)"
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
            height: "300px",
          }}
        >
          <ResponsiveContainer width="95%" height={300}>
            <BarChart
              // width={1000}
              // height={300}
              // data={data}
              data={barGraphData}
              // margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey="hour" />
              <YAxis dataKey="totalCars" />
              <Tooltip />
              <Bar dataKey="totalCars" fill="#0a6ac8" barSize={40} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div
          style={{
            border: "2px solid white",
            borderRadius: "10px",
            zIndex: "-moz-initial",
            backgroundColor: "white",
            // -webkit-box-shadow: "0px 0px 10px rgba(0, 0, 0, 0.25)"
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
            height: "300px",
          }}
        >
          <ResponsiveContainer width="95%" height={300}>
            <BarChart
              // width={1000}
              // height={300}
              // data={data}
              data={barGraphData}
              // margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey={timePeriod === "year" ? "month" : "date"} />
              <YAxis dataKey="total" />

              <Tooltip />
              <Bar dataKey="total" fill="#0a6ac8" barSize={40} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  )
}
BarchartValetCompany.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(BarchartValetCompany)
