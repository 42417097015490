import { React, useEffect } from "react"
import "../../../../App.css"
import PropTypes from "prop-types"
import "bootstrap/dist/css/bootstrap.min.css"
import { Label, Input, Form } from "reactstrap"
import {
  BrowserRouter as Router,
  useHistory,
  useParams,
} from "react-router-dom"
import { withTranslation } from "react-i18next"
import { Constant } from "pages/Resources/Constant"
import { useDispatch, useSelector } from "react-redux"
import { fetchViewPaymentRequest } from "store/actions"
const ViewPaymentValet = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()
  console.log("paymentId==>", id)

  useEffect(() => {
    dispatch(fetchViewPaymentRequest(id))
  }, [])

  const ViewPaymentValetData = useSelector(
    state => state.fetchViewpaymentReducer.Viewpayments.data
  )
  console.log("ViewPaymentvaletData=>", ViewPaymentValetData)
  const cancelHandler = () => {
    history.push("/paymentvalet")
  }

  const StartTimeFormate = starttime => {
    const date = new Date(starttime)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    const formattedTime = date.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
    })
    return `${formattedTime}`
    // return `${hours}:${minutes}:${seconds}`
    // console.log(`${day}-${month}-${year} ${hours}:${minutes}:${seconds}`)
  }
  const EndTimeFormate = endtime => {
    const date = new Date(endtime)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    const formattedTime = date.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
    })
    return `${formattedTime}`
    // return `${hours}:${minutes}:${seconds}`
    // console.log(`${day}-${month}-${year} ${hours}:${minutes}:${seconds}`)
  }
  const StartDateFormate = startdate => {
    const date = new Date(startdate)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()

    return `${day}/${month}/${year}`
    // console.log(`${day}-${month}-${year} ${hours}:${minutes}:${seconds}`)
  }
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  return (
    <div className={`main-content${classAlign}`}>
      {(document.title = "Payment | Valet")}
      <div className="page-content">
        <div className="registerValet" style={{ marginLeft: "15px" }}>
          <div id="formValet">
            <h5>
              <strong>{props.t(Constant.viewpayment)}</strong>
            </h5>
            <br />
            <Form>
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.transactionid)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="name"
                    value={ViewPaymentValetData?.transactionId}
                    disabled={true}
                    placeholder={props.t(Constant.transactionid)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.bookingid)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Booking ID"
                    value={ViewPaymentValetData?.bookingObjId?.bookingId}
                    disabled={true}
                    placeholder={props.t(Constant.bookingid)}
                  />
                </div>
              </div>
              <br />
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.username)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="name"
                    value={ViewPaymentValetData?.userObjId.name}
                    disabled={true}
                    placeholder={props.t(Constant.username)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.userphonenumber)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Status"
                    value={ViewPaymentValetData?.userObjId?.phone}
                    disabled={true}
                    placeholder={props.t(Constant.userphonenumber)}
                  />
                </div>
              </div>
              <br />
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.companyname)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Status"
                    value={ViewPaymentValetData?.companyObjId?.companyName}
                    disabled={true}
                    placeholder={props.t(Constant.companyname)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.comapnyemail)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Status"
                    value={ViewPaymentValetData?.companyObjId?.email}
                    disabled={true}
                    placeholder={props.t(Constant.comapnyemail)}
                  />
                </div>
              </div>
              <br />
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.bookingdate)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="name"
                    value={StartDateFormate(
                      ViewPaymentValetData?.bookingObjId?.bookingStartTime
                    )}
                    disabled={true}
                    placeholder={props.t(Constant.bookingdate)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.bookingtime)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Type"
                    value={StartTimeFormate(
                      ViewPaymentValetData?.bookingObjId?.bookingStartTime
                    )}
                    disabled={true}
                    placeholder={props.t(Constant.bookingtime)}
                  />
                  {/* <Label className={`label${classAlign}`}>
                    {props.t(Constant.bookingendtime)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Type"
                    value={EndTimeFormate(
                      ViewPaymentValetData?.bookingObjId?.bookingEndTime
                    )}
                    disabled={true}
                    placeholder={props.t(Constant.bookingenddate)}
                  /> */}
                </div>
              </div>
              <br />
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.pickupby)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Type"
                    value={
                      ViewPaymentValetData?.bookingObjId?.pickUpValetDriverObjId
                        ?.valetDriverName
                    }
                    disabled={true}
                    placeholder={props.t(Constant.pickupby)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.retrieveby)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Type"
                    value={
                      ViewPaymentValetData?.bookingObjId
                        ?.dropOffValetDriverObjId?.valetDriverName
                    }
                    disabled={true}
                    placeholder={props.t(Constant.retrieveby)}
                  />
                </div>
              </div>
              <br />
              {/* <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.bookingdate)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="name"
                    value={StartDateFormate(
                      ViewPaymentValetData?.bookingObjId?.bookingStartTime
                    )}
                    disabled={true}
                    placeholder={props.t(Constant.bookingdate)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.amount)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Booking ID"
                    value={ViewPaymentValetData?.amount}
                    disabled={true}
                    placeholder={props.t(Constant.amount)}
                  />
                </div>
              </div>
              <br /> */}
              <div className="viewRowDiv">
                <div className="viewDivGap">
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.status)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="name"
                    value={ViewPaymentValetData?.status}
                    disabled={true}
                    placeholder={props.t(Constant.status)}
                  />
                </div>
                <div>
                  <Label className={`label${classAlign}`}>
                    {props.t(Constant.paymentmode)}
                  </Label>
                  <Input
                    className="ViewtextfieldValet"
                    type="text"
                    name="Booking ID"
                    value={ViewPaymentValetData?.type}
                    disabled={true}
                    placeholder={props.t(Constant.paymentmode)}
                  />
                </div>
              </div>
              <br />
              <Label className={`label${classAlign}`}>
                {props.t(Constant.totalamountchargedQAR)}
              </Label>
              <Input
                className="textfieldValet"
                type="text"
                name="Booking ID"
                value={ViewPaymentValetData?.amount}
                disabled={true}
                placeholder={props.t(Constant.totalamountchargedQAR)}
              />
              <br />
              <Label className={`label${classAlign}`}>
                {props.t(Constant.companyaddress)}
              </Label>
              <Input
                className="textfieldValet"
                type="text"
                name="Booking ID"
                value={ViewPaymentValetData?.companyObjId?.address}
                disabled={true}
                placeholder={props.t(Constant.companyaddress)}
              />
              <br />

              <div className="inlineFieldsBtn">
                <button
                  className={`cancelbtn${classAlign}`}
                  type="button"
                  onClick={() => cancelHandler()}
                >
                  {props.t("Cancel")}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}
ViewPaymentValet.propTypes = {
  t: PropTypes.any,
  state: PropTypes.object,
  location: PropTypes.func,
}

export default withTranslation()(ViewPaymentValet)
