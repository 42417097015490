import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"
import {
  FETCH_DASHBOARD_REQUEST,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE,
} from "./actionType"
import { fetchDashboardSuccess, fetchDashboardFailure } from "./actions"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchDashboardData(action) {
  // console.log("SearcH==>", action.payload.searchedText)
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/admin/dashboard`,
      {
        month: action.payload.month,
        year: action.payload.year,
      },

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchDashboardSuccess(response.data))
  } catch (error) {
    yield put(fetchDashboardFailure(error))
  }
}

function* fetchdashboardSaga() {
  yield takeEvery(FETCH_DASHBOARD_REQUEST, fetchDashboardData)
}

export default fetchdashboardSaga
