import {
  FETCH_VIEW_BOOKINGVALET_FAILURE,
  FETCH_VIEW_BOOKINGVALET_REQUEST,
  FETCH_VIEW_BOOKINGVALET_SUCCESS,
} from "./actionType"

export const fetchViewBookingvaletRequest = id => (
  console.log("viewbid==>", id),
  {
    type: FETCH_VIEW_BOOKINGVALET_REQUEST,
    payload: id,
  }
)

export const fetchViewBookingvaletSuccess = Viewbookingvalet => ({
  type: FETCH_VIEW_BOOKINGVALET_SUCCESS,
  payload: Viewbookingvalet,
})

export const fetchViewBookingvaletFailure = error => ({
  type: FETCH_VIEW_BOOKINGVALET_FAILURE,
  payload: error,
})
