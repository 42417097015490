import {
  DELETE_COMPANY_FAILURE,
  DELETE_COMPANY_REQUEST,
  DELETE_COMPANY_SUCCESS,
} from "./actionType"

export const deleteCompanyRequest = id => (
  console.log("viewid==>>>>>", id),
  {
    type: DELETE_COMPANY_REQUEST,
    payload: id,
  }
)

export const deleteCompanySuccess = () => ({
  type: DELETE_COMPANY_SUCCESS,
})

export const deleteCompanyFailure = error => ({
  type: DELETE_COMPANY_FAILURE,
  payload: error,
})
