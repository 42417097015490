import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"
import { FETCH_VIEW_BOOKINGVALET_REQUEST } from "./actionType"
import {
  fetchViewBookingvaletFailure,
  fetchViewBookingvaletSuccess,
} from "./actions"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchViewBookingvalet(action) {
  // const id = localStorage.getItem("_id")
  // console.log("valetId==>", id)
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/booking/details`,
      // "http://13.234.30.42:5000/booking/details",
      { bookingObjId: action.payload },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchViewBookingvaletSuccess(response.data))
  } catch (error) {
    yield put(fetchViewBookingvaletFailure(error))
  }
}

function* fetchViewbookingvaletSaga() {
  yield takeEvery(FETCH_VIEW_BOOKINGVALET_REQUEST, fetchViewBookingvalet)
}

export default fetchViewbookingvaletSaga
